import { observable, action } from 'mobx';
import authStore from '../stores/authStore';
import _ from 'lodash';
import moment from 'moment';
import apiRequest from 'utilities/api';

class surveyStore {
	@observable values = {
		loading: true,
		surveys: [],
		total: 0,
		completed: 0,
		averages: {
			callTaker: 0,
			technician: 0,
			value: 0,
			recommend: 0
		},
		nps1: 0,
		//nps1 is the overall score of all time
		nps2: 0,
		nps3: 0,
		nps4: 0,
		nps5: 0,
		nps6: 0,
		surveyPage: [],
		techList: {},
		callTakers: []
	}

	@action async getSurveys() {
		this.values.loading = true;
		const { token } = authStore.values;
		const data = await apiRequest({ endpoint: 'v3/surveys', token });
		this.values.surveys = data.data;
		this.getStats();
	}

	getStats() {
		this.values.total = this.values.surveys.length;
		this.values.completed = _.filter(this.values.surveys, { 'IsCmplt': 1 }).length;
		this.values.averages.callTaker = _.meanBy(_.filter(this.values.surveys, { 'IsCmplt': 1 }), 'q1').toFixed(2);
		this.values.averages.technician = _.meanBy(_.filter(this.values.surveys, { 'IsCmplt': 1 }), 'q2').toFixed(2);
		this.values.averages.value = _.meanBy(_.filter(this.values.surveys, { 'IsCmplt': 1 }), 'q3').toFixed(2);
		this.values.averages.recommend = _.meanBy(_.filter(this.values.surveys, { 'IsCmplt': 1 }), 'q4').toFixed(2);
		var currentDate = moment().format('M');
		this.getNps();
		this.getNps(currentDate, 2);
		this.getNps(currentDate - 1, 3);
		this.getNps(currentDate - 2, 4);
		this.getNps(currentDate - 3, 5);
		this.getNps(currentDate - 4, 6);
	}

	getNps(date, value) {
		// From here, we can do this: (P-D)100 (rounded down.)
		// As a real example, let's suppose you have the following values P(1529), D(201), T(1730)
		// Our formula would be: ((1529/1730) - (201 / 1730))100, for a NPS of: 76
		if (!date) {
			let promoters = 0;
			let detractors = 0;
			this.values.surveys.map(survey => {
				if (survey.IsCmplt === 1) {
					survey.q1 >= 9 && promoters++;
					survey.q2 >= 9 && promoters++;
					survey.q3 >= 9 && promoters++;
					survey.q4 >= 9 && promoters++;
					survey.q1 <= 6 && detractors++;
					survey.q2 <= 6 && detractors++;
					survey.q3 <= 6 && detractors++;
					survey.q4 <= 6 && detractors++;
				}
			});
			let total = promoters + detractors;
			var overallNPS = Math.floor(((promoters / total) - (detractors / total)) * 100);
			if (isNaN(overallNPS)) {
				NPS = 0;
			}
			this.values.nps1 = overallNPS;
		} else {
			let promoters = 0;
			let detractors = 0;
			this.values.surveys.map(survey => {
				if (survey.IsCmplt === 1 && parseInt(moment(survey.CmpltDate, 'YYYY-MM-DD hh:mm:ss').format('M')) === parseInt(date)) {
					survey.q1 >= 9 && promoters++;
					survey.q2 >= 9 && promoters++;
					survey.q3 >= 9 && promoters++;
					survey.q4 >= 9 && promoters++;
					survey.q1 <= 6 && detractors++;
					survey.q2 <= 6 && detractors++;
					survey.q3 <= 6 && detractors++;
					survey.q4 <= 6 && detractors++;
				}
			});
			let total = promoters + detractors;
			var NPS = Math.floor(((promoters / total) - (detractors / total)) * 100);
			// console.log(`Promoters: ${promoters} Detractors: ${detractors} Total: ${total}`);
			if (isNaN(NPS)) {
				NPS = 0;
			}
			if (value === 2) { this.values.nps2 = NPS; }
			if (value === 3) { this.values.nps3 = NPS; }
			if (value === 4) { this.values.nps4 = NPS; }
			if (value === 5) { this.values.nps5 = NPS; }
			if (value === 6) { this.values.nps6 = NPS; }
		}
	}

	@action getTechNps(name, isCallTaker) {
		// var total = _.filter(this.values.surveys, { 'TechCode': tech, 'IsCmplt': 1 }).length;
		let promoters = 0;
		let detractors = 0;

		this.values.surveys.map(survey => {
			let searchType = survey.TechCode;
			if (isCallTaker) {
				searchType = survey.CallTaker;
			}
			if (survey.IsCmplt === 1 && searchType === name) {
				survey.q1 >= 9 && promoters++;
				survey.q2 >= 9 && promoters++;
				survey.q3 >= 9 && promoters++;
				survey.q4 >= 9 && promoters++;
				survey.q1 <= 6 && detractors++;
				survey.q2 <= 6 && detractors++;
				survey.q3 <= 6 && detractors++;
				survey.q4 <= 6 && detractors++;
			}
		});
		let total = promoters + detractors;

		var nps = Math.floor(((promoters / total) - (detractors / total)) * 100);
		if (isNaN(nps)) {
			nps = 'No data';
		}
		return nps;
	}

	@action async getTechs() {
		this.values.loading = true;
		const { token } = authStore.values;
		const data = await apiRequest({ endpoint: 'v3/getSyncInfo', token });

		// alphabetically sort
		const sorter = (left, right) => {
			if (left.TechCode < right.TechCode) return -1;
			if (left.TechCode > right.TechCode) return 1;
			return 0;
		};
		data.data.sort(sorter);
		this.values.techList = data.data;
		this.values.loading = false;
	}

	@action async getCallTakers() {
		this.values.loading = true;
		const { token } = authStore.values;
		const data = await apiRequest({ endpoint: 'v3/surveys', token });

		data.data.map(survey => {
			if (!_.includes(this.values.callTakers, survey.CallTaker)) {
				this.values.callTakers.push(survey.CallTaker);
			}
		});
		this.values.loading = false;
	}

	@action async getPage(page, tech) {
		this.values.loading = true;
		const { token } = authStore.values;
		const parameters = { page, limit: 50, completed: 1, escaped_order: 'asc' };
		if (tech) {
			parameters.technician_code = tech;
		}
		const data = await apiRequest({ endpoint: 'v3/surveys', parameters, token });

		this.values.surveys = data.data;
		this.values.loading = false;
		this.values.surveyPage = data.data;
	}

	@action async search(value) {
		this.values.loading = true;
		const { token } = authStore.values;
		const parameters = { invoice_number: value };
		const data = await apiRequest({ endpoint: 'v3/surveys', parameters, token });

		this.values.surveys = data.data;
		this.values.loading = false;
		this.values.surveyPage = data.data;
	}

	@action async setThreshold(value) {
		this.values.loading = true;
		const { token } = authStore.values;
		const parameters = { invoice_number: value };
		const data = await apiRequest({ endpoint: 'v3/surveys', parameters, token });

		this.values.surveys = data.data;
		this.values.loading = false;
		this.values.surveyPage = data.data;
	}
}

export default new surveyStore();