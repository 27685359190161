import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import SideBar from './SideBar';
import { inject, observer } from 'mobx-react';
import Loading from './loaders/Loading';

@inject('authStore')
@observer class RouteTemplate extends Component {
	render() {
		const currentPageLoading = this.props.authStore.values.currentPageLoading;
		const { first_name } = this.props.authStore.nonObservableValues?.user;

		return (
			<div className="grid-container position-relative">
				<div className="grid-body">
					<div className="top-nav">
						<span className="mr-4 font-weight-bold">Logged in as { first_name }</span>
						<Button variant="light" onClick={() => this.props.authStore.handleLogout(this.props.authStore.values.token)}>Logout</Button>
					</div>
					<Container className="py-4" style={{ position: 'relative' }}>
						<h4 className="text-muted">{this.props.title}</h4>
						{ currentPageLoading && <Loading /> }
						{this.props.children}
					</Container>
				</div>
				<SideBar navItem={this.props.navItem} />
			</div>
		);
	}
}

export default RouteTemplate;