import React, { Component } from 'react';
import { InputGroup, FormControl, Button, Alert, Card } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import InlineLoading from '../../components/loaders/InlineLoading';
import PVR from '../../components/sd-mobile/PVR';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class SdMobilePVRSearch extends Component {
	state = {
		loading: false,
	}
	onKeyPress = (event) => {
		if (event.which === 13) {
			this.getPVRFromInvoice();
		}
	}
	onKeyUp = (event) => {
		this.setState({ PVRInvoice: event.target.value });
	}
	getPVRFromInvoice = async () => {
		this.setState({ loading: true });
		let { PVRInvoice } = this.state;
		let { token } = this.props.authStore.values;
		let data = await apiRequest({ endpoint: 'v3/myrossware/listPvrs', parameters: { invoice_number: PVRInvoice }, token });
		this.setState({ loading: false, returnPVRData: data.data });
	}
	renderPVRs = () => {
		var incrementer = 0;
		const { returnPVRData } = this.state;
		if (returnPVRData && returnPVRData !== null && returnPVRData.length !== 0) {
			return ((returnPVRData || []).map((currentPVR) => {
				incrementer++;
				return (
					<PVR currentPVR={currentPVR} incrementer={incrementer} key={incrementer} />
				);
			}));
		} else if (returnPVRData && returnPVRData !== null && returnPVRData.length === 0) {
			return <Alert variant="warning">No results found for that search, please try another.</Alert>;
		}
	}
	render() {
		if (!this.state.loading) {
			return (
				<>
					<Card>
						<Card.Title className="p-4 border-bottom">PVR Search</Card.Title>
						<Card.Body>
							<InputGroup className="mb-3 mt-3">
								<FormControl
									placeholder="Invoice Number"
									aria-label="Invoice Number"
									aria-describedby="basic-addon2"
									onKeyUp={this.onKeyUp}
									onKeyPress={this.onKeyPress}
								/>
								<InputGroup.Append>
									<Button variant="primary" size="sm" onClick={this.getPVRFromInvoice}>Search</Button>
								</InputGroup.Append>
							</InputGroup>
						</Card.Body>
					</Card>
					{this.renderPVRs()}
				</>
			);
		} else {
			return (<div className="whiteContainer"><InlineLoading /></div>);
		}
	}
}

export default SdMobilePVRSearch;
