import React, { Component } from 'react';
import { InputGroup, FormControl, Button, Alert, Card } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import QuickPic from './QuickPic';
import InlineLoading from '../loaders/InlineLoading';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class QuickPicsSearch extends Component {
	state = {
		loading: false,
	}

	onKeyPress = (event) => {
		if (event.which === 13) {
			this.getPhotosFromInvoice();
		}
	}

	onKeyUp = (event) => {
		this.setState({ invoice: event.target.value });
	}
	getPhotosFromInvoice = async () => {
		this.setState({ loading: true });
		let { invoice } = this.state;
		let { token } = this.props.authStore.values;
		let data = await apiRequest({ endpoint: 'v3/myrossware/quickPicSearchByInvoice', parameters: { invoice_number: invoice }, token });
		this.setState({ loading: false, returnPhotoData: data.data });
	}

	renderPhotos = () => {
		const { returnPhotoData } = this.state;
		if (returnPhotoData && returnPhotoData !== null && returnPhotoData.length !== 0) {
			return ((returnPhotoData || []).map((currentPhoto) => {
				return (
					<QuickPic key={currentPhoto} props={currentPhoto} business_id={this.props.authStore.values.business_id} />
				);
			}));
		} else if (returnPhotoData && returnPhotoData !== null && returnPhotoData.length === 0) {
			return <Alert variant="warning">No results found for that search, please try another.</Alert>;
		}
	}

	render() {
		if (this.state.loading) { return (<Card className="mb-4"><Card.Body><InlineLoading /></Card.Body></Card>); } else {
			return (
				<div className="border-bottom pb-5 mb-5">
					<h3>Search for pictures</h3>
					<InputGroup className="mb-3 mt-3">
						<FormControl
							placeholder="Invoice Number"
							aria-label="Invoice Number"
							aria-describedby="basic-addon2"
							onKeyUp={this.onKeyUp}
							onKeyPress={this.onKeyPress}
						/>
						<InputGroup.Append>
							<Button variant="primary" onClick={this.getPhotosFromInvoice}>Search</Button>
						</InputGroup.Append>
					</InputGroup>
					{this.renderPhotos()}
				</div>
			);
		}
	}
}

export default QuickPicsSearch;
