import React, { Component } from 'react';
import { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';

export class Loading extends Component {
	render() {
		return (
			<Fragment>
				<div className="myRwLoadingDiv d-flex justify-content-center align-items-center">
					{/* <div className="myRwLoadingDiv w-100 vh-100 d-flex justify-content-center align-items-center"> */}
					<Spinner animation="border" role="status" variant="primary">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</div>
			</Fragment>
		);
	}
}

export default Loading;

