import React, { Component } from 'react';
import { Table, Card } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { convertBytes } from '../../helpers/quickpics';
import { formatToCurrency } from '../../helpers/formatting';
import InlineLoading from '../../components/loaders/InlineLoading';
import { checkIfObjectValuesAreNull } from '../../helpers/objects';
import apiRequest from 'utilities/api';


@inject('authStore')
@observer class QuickPicsUsage extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		this.getMonthlyUsage();
	}
	getMonthlyUsage = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/quickPicsMonthlyTechData', token });
		this.setState({ monthlyData: data.data, loading: false });
	}
	renderMonthlyUsage() {
		const { monthlyData } = this.state;
		return ((monthlyData || []).map((tech) => {
			let i = 0;
			if (!checkIfObjectValuesAreNull(tech)) {
				let averagePhotoSize = convertBytes((tech.quantity_photos_taken > 0) ? (tech.file_size / tech.quantity_photos_taken) : 0);
				let totalPhotoSize = convertBytes(tech.file_size);
				let totalTechCost = formatToCurrency(tech.quantity_photos_taken * 0.04);
				return (
					<tr key={'tr' + tech.tech_code}>
						<td key={'td1' + tech.tech_code}>{tech.tech_code}</td>
						<td key={'td2' + tech.tech_code}>{tech.quantity_photos_taken}</td>
						<td key={'td3' + tech.tech_code}>{averagePhotoSize}</td>
						<td key={'td4' + tech.tech_code}>{totalPhotoSize}</td>
						<td key={'td5' + tech.tech_code}>{totalTechCost}</td>
					</tr>
				);
			} else {
				i++;
				return <tr key={i}></tr>;
			} //  GETS RID OF WARNING ABOUT NOT RETURNING ANYTHING
		}));
	}
	render() {
		if (this.state.loading) { return (<Card><Card.Body><InlineLoading /></Card.Body></Card>); } else {
			return (
				<Card>
					<Card.Title className="p-4 border-bottom">Technician Usage</Card.Title>
					<Card.Body>
						<Table striped size="sm" className="mt-3 myRwSmallTable">
							<thead>
								<tr>
									<th>Tech Code</th>
									<th>Picture Count</th>
									<th>Average Size</th>
									<th>Total Size</th>
									<th>Cost</th>
								</tr>
							</thead>
							<tbody>
								{this.renderMonthlyUsage()}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			);
		}
	}
}

export default QuickPicsUsage;
