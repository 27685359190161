import { getTimeRemainingInHoursLuxon } from './dateTime';
const { DateTime: dt } = require('luxon');

export function checkIfObjectValuesAreNull(obj) {
	for (var key in obj) {
		if (obj[key] !== null && obj[key] !== '') {
			return false;
		} else {
			return true;
		}
	}
}

export function getKeyByValue(object, value) {
	return Object.keys(object).find(key => object[key] === value);
}

export function parseIpWhitelist(data) {
	const parsed = data.map((item) => {
		const whenAdded = item.when_added;
		const isoString = `${whenAdded.substring(0,10)}T${whenAdded.substring(11, 20)}`;
		const whenAddedLuxon = dt.fromISO(isoString, { zone: 'UTC' });
		item.hours_remaining = getTimeRemainingInHoursLuxon(whenAddedLuxon, item.duration) || null;
		return item;
	});

	parsed.sort(function (a, b) {
		return a.hours_remaining - b.hours_remaining;
	});
	return parsed;
}