import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Badge, Table } from 'react-bootstrap';
import { formatToNumber } from 'helpers/formatting';
import InlineLoading from 'components/loaders/InlineLoading';
import _ from 'lodash';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class Analytics extends Component {
	state = {
		loading: true,
	}
	async componentDidMount() {
		const { token } = this.props.authStore.values;

		const analyticData = (await apiRequest({ endpoint: 'v3/myrossware/cyberOfficeAnalytics', token } ));
		this.setState({ analyticData, loading: false });
	}
	renderLog() {
		const { analyticData } = this.state;
		const mostRecent25Appointments = analyticData?.appointments.slice(0,25);

		return mostRecent25Appointments.map((appointment, dex) => {
			const paymentSituation = [1, 4].includes(appointment?.PaymentSituation) ? 'CoD' : 'Warranty';
			return (
				<tr key={`appointment-${dex}`}>
					<td>{ appointment?.CstmrNm }</td>
					<td>{ appointment?.DateCreated.substring(0,10) }</td>
					<td>{ appointment?.DateRequested }</td>
					<td>{ paymentSituation }</td>
					<td>{ parseInt(appointment?.RcvdBySD) === 1 ? <Badge variant="success">Yes</Badge> : <Badge variant="danger">No</Badge> }</td>
				</tr>
			);
		});

	}
	render() {
		const { analyticData, loading } = this.state;
		if (loading) {
			return <InlineLoading />;
		}

		const { appointments: appointmentsLast90, jobStatus: jobStatusLast90, techStatus: techStatusLast90 } = analyticData;

		const techStatusViewsLast90 = (function(techStatusLast90) {
			var views = 0;
			techStatusLast90.forEach(techStatusRow => {
				views += parseInt(techStatusRow.Viewed);
			});
			return views;
		}(techStatusLast90));

		const jobStatusViewsLast90 = (function(jobStatusLast90) {
			var views = 0;
			jobStatusLast90.forEach(jobStatusRow => {
				views += parseInt(jobStatusRow.Viewed);
			});
			return views;
		}(jobStatusLast90));

		const techStatusTotalsLast90 = (function(techStatusLast90) {
			return _.size(techStatusLast90);
		}(techStatusLast90));

		const jobStatusTotalsLast90 = (function(jobStatusLast90) {
			return _.size(jobStatusLast90);
		}(jobStatusLast90));

		return (
			<div>
				<div className="page-header">
					<h1 className="page-title" style={{ marginBottom: 0 }}>Analytics</h1>
				</div>

				<div className="settings-section grid-3-col">
					<div className="analyticsBadge">
						<h2>{ formatToNumber(appointmentsLast90.length) }</h2>
						<span>appointments scheduled last 90 days</span>
					</div>
					<div className="analyticsBadge">
						<h2>{ formatToNumber(techStatusTotalsLast90) }</h2>
						<span>tech tracking invitations last 90 days</span>
						<h2>{ formatToNumber(techStatusViewsLast90) }</h2>
						<span>tech tracking views last 90 days</span>
					</div>
					<div className="analyticsBadge">
						<h2>{ formatToNumber(jobStatusTotalsLast90) }</h2>
						<span>job status invitations last 90 days</span>
						<h2>{ formatToNumber(jobStatusViewsLast90) }</h2>
						<span>job status views last 90 days</span>
					</div>
				</div>

				<div className="settings-section">
					<b>Recent appointments</b>
					<div>
						<Table striped size="sm" className="mt-3 myRwSmallTable">
							<thead className='sticky-top bg-white border-top'>
								<tr>
									<th>Customer</th>
									<th>WhenScheduled</th>
									<th>WhenRequested</th>
									<th>Type</th>
									<th>Received By SD</th>
								</tr>
							</thead>
							<tbody>
								{this.renderLog()}
							</tbody>
						</Table>

					</div>
				</div>



			</div>
		);
	}
}

export default Analytics;