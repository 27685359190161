import React, { Component } from 'react';

export class ServerStatusBadge extends Component {
	constructor(props) {
		super(props);
		this.state = {
			refreshCountdown: 5
		};
	}

	componentDidMount() {
		this.interval = setInterval(() => {
			this.setState(this.refreshCountdownTicker());
		}, 1000);
	}

	refreshCountdownTicker() {
		if (this.state.refreshCountdown === 0) {
			this.setState({ refreshCountdown: 5 });
		} else {
			this.setState({ refreshCountdown: this.state.refreshCountdown - 1 });
		}
	}

	render() {
		const { status } = this.props;
		let time = this.state.refreshCountdown;
		let refreshStatus = `${time !== 0 ? 'in' : ''} ${time !== 0 ? time : ''} ${time !== 0 ? 'seconds' : 'now'}`;
		switch (status) {
		case 'running':
			return (
				<div>
					<div className="badge badge-success"><b>Running</b> -- status refreshing {refreshStatus}</div>
				</div>
			);
		case 'stopped':
			return (
				<div className="rss-server-status RSSServerStatusStopped"><span>Stopped</span></div>
			);
		case 'stopping':
			return (
				<div className="rss-server-status RSSServerStatusStopping"><span>Stopping</span></div>
			);
		case 'pending':
			return (
				<div className="rss-server-status RSSServerStatusPending"><span>Pending</span></div>
			);
		case 'unknown':
			return (
				<div className="rss-server-status RSSServerStatusUnknown"><span>Unknown</span></div>
			);
		default:
			return (
				<div className="rss-server-status RSSServerStatusUnknown"><span>Unknown</span></div>
			);
		}
	}
}

export default ServerStatusBadge;
