import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { convertPlatformReadable, convertVersionReadable, cleanTechList } from '../../helpers/sdMobile';
import InlineLoading from '../loaders/InlineLoading';
import apiRequest from 'utilities/api';
import moment from 'moment';

@inject('authStore')
@observer class TechnicianList extends Component {
	state = {
		loading: true,
	};

	componentDidMount() {
		this.getRecentTechs();
	}

	getRecentTechs = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/getSyncInfo', token });
		this.setState({ techs: cleanTechList(data.data), loading: false });
	}

	renderTechs() {
		const { techs } = this.state;
		return ((techs || []).map((tech) => {
			let platform = tech.Platform;
			console.log(tech.Platform);
			let badgeType = 'badge-secondary';
			if (platform === 'flxi') {
				badgeType = 'badge-purple';
			} else if (platform === 'flxa') {
				badgeType = 'badge-success';
			}
			return (
				<div className="grid-tech-list" key={tech.TechCode}>
					<div className="d-flex justify-content-center align-items-center">
						<div className="tech-profile" key={tech.TechCode + 'TechCode'}>{tech.TechCode}</div>
					</div>
					<div>
						<h6 key={tech.TechName + 'TechName'}>{tech.TechName}</h6>
						<div className={`badge ${badgeType} mr-2`} key={tech.TechCode + 'Platform'}>{convertPlatformReadable(tech.Platform)}</div>
						<div className="badge badge-secondary" key={tech.TechCode + 'Version'}>Version {convertVersionReadable(tech.Version)}</div>
					</div>
					<div key={tech.TechCode + 'LastConnection'}><span className="text-muted">Last connected:</span> {moment(tech.timestamp, 'YYYY-MM-DD HH:mm:ss').format('MMM, D YYYY, LT') || 'Unknown'}</div>
				</div>
			);
		}));
	}
	//2022-12-22 06:21:55
	render() {
		if (this.state.loading) { return <InlineLoading />; } else {
			const { techs } = this.state;
			if (techs.length < 1) { return <div>No technicians found. You can add technicians from within ServiceDesk.</div>; }
			return (
				<div>
					<div className="card">
						<div className="grid-tech-wrapper">
							{this.renderTechs()}
						</div>
					</div>
				</div>
			);
		}
	}
}

export default TechnicianList;
