import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import InlineLoading from '../loaders/InlineLoading';
import { Spinner, Table } from 'react-bootstrap';
import decimalsAndCommas from 'helpers/currency';
import { capitalizeFirstLetter } from 'helpers/strings';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class PendingList extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		this.getPayments();
	}
	getPayments = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/listPaymentRequests', token });
		const pending = [];
		data.forEach(payment => {
			if (payment.payment_complete === 0 && !payment?.cancelled) {
				pending.push(payment);
			}
		});
		this.setState({ payments: pending, loading: false });
	}

	renderPayments() {
		const cancelPayment = async (dex) => {
			var { payments } = this.state;
			payments[dex].loading_cancellation = true;
			this.setState({ payments });
			const payment = payments[dex];

			const { user } = this.props.authStore.nonObservableValues;
			const { token } = this.props.authStore.values;
			const response = await apiRequest({ endpoint: 'v3/myrossware/cancelPaymentRequest', token, parameters: { payment_id: payment?.id, business_id: user?.business_id } });
			if (response?.data?.affectedRows === 1) {
				payments[dex].just_cancelled = 1;
			}
			payments[dex].loading_cancellation = false;
			this.setState({ payments });
		};
		function renderCancelButton(dex) {
			const payment = payments?.[dex];
			if (payment.loading_cancellation) {
				return (
					<button disabled className="btn-secondary btn-small btn">
						<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
						/>
						&nbsp;Cancelling
					</button>
				);
			}
			else if (payment?.just_cancelled === 1) {
				return <button disabled className="btn-warning btn-small btn">Cancelled</button>;
			} else {
				return <button onClick={() => cancelPayment(dex)} className="btn-danger btn-small btn">Cancel</button>;
			}
		}
		const { payments } = this.state;
		console.log(payments);
		return ((payments || []).map((payment, dex) => {
			return (
				<tr key={payment.id}>
					<td key={payment.id + 'customer'}>{`${capitalizeFirstLetter(payment.customer_last_name)}, ${capitalizeFirstLetter(payment.customer_first_name)}`}</td>
					<td style={{ textAlign: 'right' }} key={payment.id + 'amount'}>{`$${decimalsAndCommas(payment.amount_to_charge)}`}</td>
					<td style={{ textAlign: 'right' }} key={payment.id + 'invoice'}>{payment.invoice_number}</td>
					<td key={payment.id + 'dateRequested'}>{payment.when_requested}</td>
					<td style={{ textAlign: 'right' }} key={payment.id + 'cancelButton'}>{ renderCancelButton(dex) }</td>
				</tr>
			);
		}));
	}
	render() {
		if (this.state.loading) { return <InlineLoading />; } else {
			const { payments } = this.state;
			if (payments.length < 1) { return <div>No payments pending. </div>; }
			return (
				<div className="card">
					<div className="card-body">
						<Table size="sm" className="mt-3 payment-request-table">
							<thead>
								<tr>
									<th>Customer</th>
									<th style={{ textAlign: 'right' }}>Amount</th>
									<th style={{ textAlign: 'right' }}>Invoice</th>
									<th>Requested</th>
									<th style={{ textAlign: 'right' }}></th>
								</tr>
							</thead>
							<tbody>
								{this.renderPayments()}
							</tbody>
						</Table>
					</div>
				</div>
			);
		}
	}
}

export default PendingList;
