import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import apiRequest from 'utilities/api';
import moment from 'moment';
import _ from 'lodash';
import Loading from 'components/loaders/Loading';

@inject('authStore')
@observer class PlatformFeeReports extends Component {
	state = {
		loading: true,
		start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
		end_date: moment().format('YYYY-MM-DD'),
		data: {},
	}
	componentDidMount() {
		this.getData(this.state.start_date, this.state.end_date);
	}

	getData = async (start, end) => {
		this.setState({ loading: true });
		let { token } = this.props.authStore.values;
		let { business } = this.props.authStore.nonObservableValues;
		let data = await apiRequest({ endpoint: 'v3/rosswarepay/production/reportPlatformFees', parameters: { business_id: business.business_id, business_password: business.business_password, start_date: start, end_date: end }, token });
		this.setState({ data: data, loading: false });
	}

	getDataTotal() {
		let { data } = this.state;
		let total = 0;
		_.forEach(data.data, (row) => {
			total += row.amount;
		});
		return total;
	}

	renderDataRows() {
		console.log(this.state.data);
		return this.state.data.data.map((row, key) => {
			return (
				<tr key={key}>
					<td>{ row.invoice_number }</td>
					<td className="text-right">${ row.amount.toString() }</td>
				</tr>
			);
		});
	}

	handleDateChange = (event) => {
		this.setState({ [event.target.name]: event.target.value }, () => {
			this.getData(this.state.start_date, this.state.end_date);
		});
	}


	render() {
		let { loading, start_date, end_date } = this.state;
		return (
			<div>
				<div className="card">
					<div className="card-body">
						<div className="mb-3">
							<h4 className="">Platform fee report</h4>
						</div>
						<div className="grid-2-col mb-3">
							<div className="grid-4-col">
								<div className="d-flex align-items-center">Dates:</div>
								<input type="date" className="form-control" name="start_date" onChange={(e) => this.handleDateChange(e)} defaultValue={start_date} />
								<div className="d-flex justify-content-center align-items-center">to</div>
								<input type="date" className="form-control" name="end_date" onChange={(e) => this.handleDateChange(e)} defaultValue={end_date} />
							</div>
							<button className="btn btn-light d-print-none" onClick={() => window.print()}>Print</button>
						</div>
						{loading && <div className="py-4"><Loading /></div>}
						{!loading && <table className="table table-hover">
							<thead>
								<tr>
									<th>Invoice number</th>
									<th className="text-right">Amount</th>
								</tr>
							</thead>
							<tbody className="table-group-divider">
								{this.renderDataRows()}
							</tbody>
							<tbody>
								<tr>
									<td className="font-weight-bolder">Total</td>
									<td className="text-right font-weight-bolder">${ this.getDataTotal().toString() }</td>
								</tr>
							</tbody>
						</table>}
					</div>
				</div>
			</div>
		);
	}
}

export default PlatformFeeReports;
