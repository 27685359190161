import InlineLoading from 'components/loaders/InlineLoading';
import CompleteList from 'components/payments/CompleteList';
import React, { Component } from 'react';

export class Complete extends Component {
	state = {
		loading: false,
	}
	render() {
		const { loading } = this.state;
		if (loading) { return <div><InlineLoading /></div>; } else {
			return (
				<div className="mb-4">
					<h1 className="page-title">Complete Payments</h1>
					<div>
						<CompleteList />
					</div>
				</div>
			);
		}
	}
}

export default Complete;
