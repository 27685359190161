import React, { Component, Fragment } from 'react';
import { Pagination } from 'react-bootstrap';

// TAKES IN currentPage and numPages AS PROPS, RUNS getRoboLog() ON CLICK

export class PaginationComponent extends Component {
	state = {
		currentPage: parseInt(this.props.currentPage),
		numPages: parseInt(this.props.numPages),
	}
	processClick = (page, forwardOrBack) => {
		if ((page === (this.state.numPages + 1)) && forwardOrBack === 'forward') { return false; }
		if (this.state.currentPage === 1 && forwardOrBack === 'back') { return false; }
		this.setState({ currentPage: page });
		this.props.getRoboLog(page - 1);
	}
	renderGuts = () => {
		var { currentPage, numPages } = this.state;
		var pageList = [];
		for (var i = 1; i <= numPages; i++) {
			pageList.push(i);
		}
		currentPage = parseInt(currentPage);
		numPages = parseInt(numPages);
		if (numPages < 10) { // NO ELIPSES OR EXTRA COMPLICATION
			return pageList.map((page) => {
				if (page === currentPage) {
					return (<Pagination.Item onClick={() => this.processClick(page)} active key={page}>{page}</Pagination.Item>);
				} else {
					return (<Pagination.Item onClick={() => this.processClick(page)} key={page}>{page}</Pagination.Item>);
				}
			});
		}
		if (numPages === 10) {
			if (currentPage <= 5) {
				let returnArray = [];
				let first7 = pageList.slice(0, 7);
				for (let i = 0; i < first7.length; i++) {
					if (first7[i] === currentPage) {
						returnArray.push(<Pagination.Item onClick={() => this.processClick(first7[i])} active key={first7[i]}>{first7[i]}</Pagination.Item>);
					} else {
						returnArray.push(<Pagination.Item onClick={() => this.processClick(first7[i])} key={first7[i]}>{first7[i]}</Pagination.Item>);
					}
				}
				returnArray.push(<Pagination.Ellipsis disabled key="ellipsis1" />);
				returnArray.push(<Pagination.Item onClick={() => this.processClick(numPages)} key={numPages}>{numPages}</Pagination.Item>);
				return (returnArray);
			} else {
				let returnArray = [];
				returnArray.push(<Pagination.Item onClick={() => this.processClick(1)} key="1">1</Pagination.Item>);
				returnArray.push(<Pagination.Ellipsis disabled key="ellipsis2" />);
				let last7 = pageList.slice(numPages - 7, numPages);
				for (let i = 0; i < last7.length; i++) {
					if (last7[i] === currentPage) {
						returnArray.push(<Pagination.Item onClick={() => this.processClick(last7[i])} active key={last7[i]}>{last7[i]}</Pagination.Item>);
					} else {
						returnArray.push(<Pagination.Item onClick={() => this.processClick(last7[i])} key={last7[i]}>{last7[i]}</Pagination.Item>);
					}
				}
				return (returnArray);
			}
		}
		if (numPages > 10) {
			let returnArray = [];
			let bottom5 = pageList.slice(0, 5);
			let top5 = pageList.slice(numPages - 5, numPages);
			let middle = pageList.slice(5, numPages - 5);

			if (bottom5.includes(currentPage)) {
				for (let i = 0; i < bottom5.length; i++) {
					if (currentPage === bottom5[i]) {
						returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(bottom5[i])} active key={bottom5[i]}>{bottom5[i]}</Pagination.Item>);
					} else {
						returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(bottom5[i])} key={bottom5[i]}>{bottom5[i]}</Pagination.Item>);
					}
				}
				returnArray.push(<Fragment key="6"><Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(6)}>6</Pagination.Item><Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(7)} key="7">7</Pagination.Item></Fragment>);
				returnArray.push(<Pagination.Ellipsis disabled key="ellipsis3" />);
				returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(numPages)} key={numPages}>{numPages}</Pagination.Item>);
				return returnArray;
			}
			if (top5.includes(currentPage)) {
				returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(1)} key="1">1</Pagination.Item>);
				returnArray.push(<Pagination.Ellipsis disabled key="elipses4" />);
				let minus6 = numPages - 5;
				let minus7 = numPages - 6;
				returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(minus7)} key={minus7}>{minus7}</Pagination.Item>);
				returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(minus6)} key={minus6}>{minus6}</Pagination.Item>);
				for (let i = 0; i < top5.length; i++) {
					if (currentPage === top5[i]) {
						returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(top5[i])} active key={top5[i]}>{top5[i]}</Pagination.Item>);
					} else {
						returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(top5[i])} key={top5[i]}>{top5[i]}</Pagination.Item>);
					}
				}
				return returnArray;
			}
			if (middle.includes(currentPage)) {
				let currentGroup = pageList.slice(currentPage - 3, currentPage + 2);
				returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(1)} key="1">1</Pagination.Item>);
				returnArray.push(<Pagination.Ellipsis disabled key="ellipsis5" />);

				for (let i = 0; i < currentGroup.length; i++) {
					if (currentPage === currentGroup[i]) {
						returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(currentGroup[i])} active key={currentGroup[i]}>{currentGroup[i]}</Pagination.Item>);
					} else {
						returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(currentGroup[i])} key={currentGroup[i]}>{currentGroup[i]}</Pagination.Item>);
					}
				}
				returnArray.push(<Pagination.Ellipsis disabled key="ellipsis6" />);
				returnArray.push(<Pagination.Item className="flex-fill d-none d-sm-block" onClick={() => this.processClick(numPages)} key={numPages}>{numPages}</Pagination.Item>);
				return returnArray;
			}
		}

	}
	render() {
		return (
			<Pagination className="myRWPagination d-flex">
				<Pagination.Prev key="prevBtn" onClick={() => this.processClick(this.state.currentPage - 1, 'back')} />
				{this.renderGuts()}
				<Pagination.Next key="nextBtn" onClick={() => this.processClick(this.state.currentPage + 1, 'forward')} />
			</Pagination>
		);
	}
}

export default PaginationComponent;
