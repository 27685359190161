import React, { Component } from 'react';
import { Card, Navbar, Nav, Accordion, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { BsPhone, BsCalendar, BsGraphUp, BsSquare, BsImage, BsCloud, BsLightning, BsPerson } from 'react-icons/bs';
import { FaMoneyBillWaveAlt } from 'react-icons/fa';
import { inject, observer } from 'mobx-react';
import Logo from '../assets/rwLogo.png';

@inject('authStore')
@observer class SideBar extends Component {
	render() {
		const twilioNumber = this.props.authStore.nonObservableValues.business?.web_configuration?.twilio_number;
		const roboRegistration = this.props.authStore.nonObservableValues.roboRegistration;
		const [testMerchantId, productionMerchantId] = [this.props.authStore?.nonObservableValues?.business?.test_rosswarepay_merchant_id, this.props.authStore?.nonObservableValues?.business?.production_rosswarepay_merchant_id];

		function renderRosswarePayMenu() {
			const hasMerchantId = testMerchantId || productionMerchantId;
			if (hasMerchantId) {
				return (
					<div>
						<Accordion.Toggle as={Button} className="nav-link" variant="link" eventKey="0"><FaMoneyBillWaveAlt />RosswarePay</Accordion.Toggle>
						<Accordion.Collapse eventKey="0">
							<div className="sub-nav-link-container">
								<Nav.Link as={NavLink} to="/rosswarepay/reporting" exact activeClassName="sidebar-active" className="sub-nav-link">Reporting</Nav.Link>
								<Nav.Link as={NavLink} to="/rosswarepay/settings" exact activeClassName="sidebar-active" className="sub-nav-link">Settings</Nav.Link>
							</div>
						</Accordion.Collapse>
					</div>
				);
			}
			return <a href="https://rossware.com/rosswarepay" target="_blank" rel="noreferrer" className="nav-link d-flex align-items-center"><FaMoneyBillWaveAlt />RosswarePay</a>;
		}

		function renderRobocallingMenu() {
			if (twilioNumber && roboRegistration) {
				return (
					<div>
						<Accordion.Toggle as={Button} className="nav-link" variant="link" eventKey="6"><BsLightning />Robo-Calling/SMS</Accordion.Toggle>
						<Accordion.Collapse eventKey="6">
							<div className="sub-nav-link-container">
								<Nav.Link as={NavLink} to="/robocalling" exact activeClassName="sidebar-active" className="sub-nav-link">Overview</Nav.Link>
								<Nav.Link as={NavLink} to="/robocalling/pending" exact activeClassName="sidebar-active" className="sub-nav-link">Pending actions</Nav.Link>
								<Nav.Link as={NavLink} to="/robocalling/log" exact activeClassName="sidebar-active" className="sub-nav-link">Call log</Nav.Link>
								<Nav.Link as={NavLink} to="/robocalling/lookup" exact activeClassName="sidebar-active" className="sub-nav-link">Call lookup</Nav.Link>
								<Nav.Link as={NavLink} to="/robocalling/smslog" exact activeClassName="sidebar-active" className="sub-nav-link">SMS log</Nav.Link>
								<Nav.Link as={NavLink} to="/robocalling/scripts" exact activeClassName="sidebar-active" className="sub-nav-link">Scripts</Nav.Link>
							</div>
						</Accordion.Collapse>
					</div>
				);
			} else {
				return <Nav.Link as={NavLink} to="/robocalling" exact activeClassName="sidebar-active" eventKey="6"><BsLightning />Robo-calling/SMS</Nav.Link>;
			}
		}

		return (
			<div className="sidebar">
				<Card.Body>
					<Navbar className="" variant="light" expand="md" collapseOnSelect>
						<Navbar.Toggle className="" />
						<Navbar.Collapse>
							<Nav className="w-100 d-flex flex-column">
								<img src={Logo} width="36px" className="mb-4" />
								<Nav.Link as={NavLink} to="/" exact activeClassName="sidebar-active" eventKey="20"><BsSquare />Dashboard</Nav.Link>


								<Accordion defaultActiveKey={this.props.navItem}>

									{renderRosswarePayMenu()}
									<div>
										<Accordion.Toggle as={Button} className="nav-link" variant="link" eventKey="1"><BsGraphUp />Survey System</Accordion.Toggle>
										<Accordion.Collapse eventKey="1">
											<div className="sub-nav-link-container">
												<Nav.Link as={NavLink} to="/surveys" exact activeClassName="sidebar-active" className="sub-nav-link">How it works</Nav.Link>
												<Nav.Link as={NavLink} to="/surveys/stats" exact activeClassName="sidebar-active" className="sub-nav-link">Business statistics</Nav.Link>
												<Nav.Link as={NavLink} to="/surveys/tech-stats" exact activeClassName="sidebar-active" className="sub-nav-link">Tech statistics</Nav.Link>
												<Nav.Link as={NavLink} to="/surveys/calltaker-stats" exact activeClassName="sidebar-active" className="sub-nav-link">Call Taker statistics</Nav.Link>
												<Nav.Link as={NavLink} to="/surveys/lookup" activeClassName="sidebar-active" className="sub-nav-link">Itemization/Lookup</Nav.Link>
												<Nav.Link as={NavLink} to="/surveys/settings" exact activeClassName="sidebar-active" className="sub-nav-link">Settings</Nav.Link>
											</div>
										</Accordion.Collapse>
									</div>

									<div>
										<Accordion.Toggle as={Button} className="nav-link" variant="link" eventKey="2"><FaMoneyBillWaveAlt />Online payments</Accordion.Toggle>
										<Accordion.Collapse eventKey="2">
											<div className="sub-nav-link-container">
												<Nav.Link as={NavLink} to="/payments/pending" exact activeClassName="sidebar-active" className="sub-nav-link">Pending</Nav.Link>
												<Nav.Link as={NavLink} to="/payments/complete" exact activeClassName="sidebar-active" className="sub-nav-link">Complete</Nav.Link>
											</div>
										</Accordion.Collapse>
									</div>

									<div>
										<Accordion.Toggle as={Button} className="nav-link" variant="link" eventKey="3"><BsPhone />SD-Mobile</Accordion.Toggle>
										<Accordion.Collapse eventKey="3">
											<div className="sub-nav-link-container">
												<Nav.Link as={NavLink} to="/sdmobile" exact activeClassName="sidebar-active" className="sub-nav-link">Current technicians</Nav.Link>
												<Nav.Link as={NavLink} to="/sdmobile/pvrsearch" exact activeClassName="sidebar-active" className="sub-nav-link">PVR search</Nav.Link>
												<Nav.Link as={NavLink} to="/sdmobile/techimages" exact activeClassName="text-primary" className="sub-nav-link">Tech images</Nav.Link>
											</div>
										</Accordion.Collapse>
									</div>

									<div>
										<Accordion.Toggle as={Button} className="nav-link" variant="link" eventKey="4"><BsCalendar />SD-CyberOffice</Accordion.Toggle>
										<Accordion.Collapse eventKey="4">
											<div className="sub-nav-link-container">
												{/* <Nav.Link as={NavLink} to="/sdcyberoffice" exact activeClassName="sidebar-active" className="sub-nav-link">Overview</Nav.Link> */}
												<Nav.Link as={NavLink} to="/sdcyberoffice/settings" exact activeClassName="sidebar-active" className="sub-nav-link">Settings</Nav.Link>
												<Nav.Link as={NavLink} to="/sdcyberoffice/analytics" exact activeClassName="sidebar-active" className="sub-nav-link">Analytics</Nav.Link>
											</div>
										</Accordion.Collapse>
									</div>

									<div>
										<Accordion.Toggle as={Button} className="nav-link" variant="link" eventKey="5"><BsImage />SD-QuickPics</Accordion.Toggle>
										<Accordion.Collapse eventKey="5">
											<div className="sub-nav-link-container">
												<Nav.Link as={NavLink} to="/quickpics" exact activeClassName="sidebar-active" className="sub-nav-link">Overview</Nav.Link>
												<Nav.Link as={NavLink} to="/quickpics/usage" exact activeClassName="sidebar-active" className="sub-nav-link">Technician usage</Nav.Link>
											</div>
										</Accordion.Collapse>
									</div>

									{ renderRobocallingMenu() }

									<Nav.Link as={NavLink} to="/serversolutions" exact activeClassName="sidebar-active" className="nav-link" variant="link" eventKey="7"><BsCloud />Server Solutions</Nav.Link>

									<Nav.Link as={NavLink} to="/users" exact activeClassName="sidebar-active" eventKey="8" className="nav-link" variant="link" ><BsPerson />User Management</Nav.Link>

								</Accordion>
								<hr className="mx-n4 flex-grow-1"/>
								<Nav.Link as={NavLink} to="/updatepayment" exact activeClassName="sidebar-active" eventKey="10" className="nav-link d-flex align-items-center">Update payment info</Nav.Link>
								<a href="https://help.rossware.com/" target="_blank" rel="noreferrer" className="nav-link d-flex align-items-center">Help Center</a>
								<a href="https://resources.rossware.com/resources/video-tutorials" target="_blank" rel="noreferrer" className="nav-link d-flex align-items-center">Video Tutorials</a>
								<a href="https://key.rossware.com/" target="_blank" rel="noreferrer" className="nav-link d-flex align-items-center">RSS Key</a>
								<a href="https://rossware.com/connect" target="_blank" rel="noreferrer" className="nav-link d-flex align-items-center">Connect to Support</a>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</Card.Body>
			</div>
		);
	}
}

export default SideBar;
