
import React, { Component } from 'react';
import Dashboard from '../pages/Dashboard';
import Turnkey from '../pages/turnkey/TurnKey';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import SdMobileTechnicians from '../pages/sd-mobile/SdMobileTechnicians';
import SdMobilePVRSearch from '../pages/sd-mobile/SdMobilePVRSearch';
import SDCOOverview from '../pages/sd-cyberoffice/SDCOOverview';
import SDCOSettings from '../pages/sd-cyberoffice/Settings';
import RoboCallingOverview from '../pages/robocalling/RoboCallingOverview';
import RoboCallingLog from '../pages/robocalling/RoboCallingLog';
import RoboCallingLookup from '../pages/robocalling/RoboCallingLookup';
import RoboCallingScripts from '../pages/robocalling/RoboCallingScripts';
import QuickPicsOverview from '../pages/quickpics/QuickPicsOverview';
import QuickPicsUsage from '../pages/quickpics/QuickPicsUsage';
import ServerControl from '../pages/servermanagement/ServerControl';
import BluebookOverview from '../pages/bluebook/BluebookOverview';
import BluebookTypes from '../pages/bluebook/BluebookTypes';
import BluebookMakes from '../pages/bluebook/BluebookMakes';
import NotFound from 'pages/NotFound';
import SurveyMain from 'pages/survey-system/Main';
import Stats from 'pages/survey-system/Stats';
import Lookup from 'pages/survey-system/Lookup';
import Settings from 'pages/survey-system/Settings';
import { inject, observer } from 'mobx-react';
import UpdatePayment from 'pages/UpdatePayment';
import TechImages from 'pages/sd-mobile/TechImages';
import TechStats from 'pages/survey-system/TechStats';
import CallTakerStats from 'pages/survey-system/CallTakerStats';
import PatchNotes from 'pages/PatchNotes';
import RoboSMSLog from 'pages/robocalling/RoboSMSLog';
import PaymentSettings from 'pages/payments/Settings';
import Pending from 'pages/payments/Pending';
import Complete from 'pages/payments/Complete';
import RoboCallingPending from 'pages/robocalling/RoboCallingPendingCalls';
import Analytics from 'pages/sd-cyberoffice/Analytics';
import MerchantTrack from 'pages/rosswarepay/Reporting';
import Loading from 'components/loaders/Loading';
import Login from './Login';
import RouteTemplate from 'components/RouteTemplate';
import Signup from './Signup';
import ResetPassword from './ResetPassword';
import ForwardedReset from './ForwardedReset';
import UserManagement from './UserManagement';
import RosswarePaySettings from './rosswarepay/Settings';
import RobocallingDisposition from './RobocallingDisposition';

@inject('authStore')
@observer class Root extends Component {
	componentDidMount() { // this serves to re authenticate on full page refresh
		if (window.sessionStorage.getItem('token')) {
			this.props.authStore.reAuthenticate(window.sessionStorage.getItem('token'), true);
		}
	}
	render() {
		const { user } = this.props.authStore.nonObservableValues;
		const { loading } = this.props.authStore.values;
		if (loading) {
			return <Loading />;
		}
		if (!this.props.authStore.values.token) {
			return (
				<Router>
					<Switch>
						<Route exact path={'/signup'}>
							<Signup />
						</Route>
						<Route exact path={'/reset'}>
							<ResetPassword />
						</Route>
						<Route exact path={'/forwarded-reset'}>
							<ForwardedReset />
						</Route>
						<Route exact path={'/robocalling-disposition'}>
							<RobocallingDisposition />
						</Route>
						<Route path="*">
							<Login />
						</Route>
					</Switch>
				</Router>
			);
		}
		return (
			<Router>
				<Switch>
					<Route path={'/'} exact component={() =>
						<RouteTemplate title={`Hi, ${user?.first_name ? user?.first_name : 'friend'}!`}>
							<Dashboard />
						</RouteTemplate>
					} />
					<Route path={'/rosswarepay'} component={() =>
						<RouteTemplate title="RosswarePay" navItem="0">
							<Route exact path={'/rosswarepay/reporting'} component={MerchantTrack} />
							<Route exact path={'/rosswarepay/settings'} component={RosswarePaySettings} />
						</RouteTemplate>
					} />
					<Route path={'/surveys'} component={() =>
						<RouteTemplate title="Survey System" navItem="1">
							<Route exact path={'/surveys'} component={SurveyMain} />
							<Route exact path={'/surveys/stats'} component={Stats} />
							<Route exact path={'/surveys/tech-stats'} component={TechStats} />
							<Route exact path={'/surveys/calltaker-stats'} component={CallTakerStats} />
							<Route exact path={'/surveys/lookup/:tech?'} component={Lookup} />
							<Route exact path={'/surveys/personnel'} component={SdMobilePVRSearch} />
							<Route exact path={'/surveys/settings'} component={Settings} />
						</RouteTemplate>
					} />
					<Route path={'/payments'} component={() =>
						<RouteTemplate title="Payments" navItem="2">
							<Route exact path={'/payments/complete'} component={Complete} />
							<Route exact path={'/payments/settings'} component={PaymentSettings} />
							<Route exact path={'/payments/pending'} component={Pending} />
						</RouteTemplate>
					} />
					<Route path={'/sdmobile'} component={() =>
						<RouteTemplate title="SD-Mobile" navItem="3">
							<Route exact path={'/sdmobile'} component={SdMobileTechnicians} />
							<Route exact path={'/sdmobile/pvrsearch'} component={SdMobilePVRSearch} />
							<Route exact path={'/sdmobile/techimages'} component={TechImages} />
						</RouteTemplate>
					} />
					<Route path={'/sdcyberoffice'} component={() =>
						<RouteTemplate title="SD-CyberOffice" navItem="4">
							<Route exact path={'/sdcyberoffice'} component={SDCOOverview} />
							<Route exact path={'/sdcyberoffice/settings'} component={SDCOSettings} />
							<Route exact path={'/sdcyberoffice/analytics'} component={Analytics} />
						</RouteTemplate>
					} />
					<Route path={'/quickpics'} component={() =>
						<RouteTemplate title="SD-QuickPics" navItem="5">
							<Route exact path={'/quickpics'} component={QuickPicsOverview} />
							<Route exact path={'/quickpics/usage'} component={QuickPicsUsage} />
						</RouteTemplate>
					} />
					<Route path={'/robocalling'} component={() =>
						<RouteTemplate title="Robo-Calling/SMS" navItem="6">
							<Route exact path={'/robocalling'} component={RoboCallingOverview} />
							<Route exact path={'/robocalling/pending'} component={RoboCallingPending} />
							<Route exact path={'/robocalling/log'} component={RoboCallingLog} />
							<Route exact path={'/robocalling/smslog'} component={RoboSMSLog} />
							<Route exact path={'/robocalling/lookup'} component={RoboCallingLookup} />
							<Route exact path={'/robocalling/scripts'} component={RoboCallingScripts} />
						</RouteTemplate>
					} />
					<Route path={'/bluebook'} component={() =>
						<RouteTemplate title="Bluebook" navItem="7">
							<Route exact path={'/bluebook'} component={BluebookOverview} />
							<Route exact path={'/bluebook/types'} component={BluebookTypes} />
							<Route exact path={'/bluebook/makes'} component={BluebookMakes} />
						</RouteTemplate>
					} />
					<Route path={'/serversolutions'} component={() =>
						<RouteTemplate title="Server Solutions" navItem="8">
							<Route exact path={'/serversolutions'} component={ServerControl} />
							{/* <Route exact path={'/serversolutions/rsskey'} component={RSSKey} /> */}
						</RouteTemplate>
					} />
					<Route path={'/users'} component={() =>
						<RouteTemplate title="User Management" navItem="9">
							<Route exact path={'/users'} component={UserManagement} />
						</RouteTemplate>
					} />
					<Route path="/website-editor" component={Turnkey} />
					<Route path={'/updatepayment'} component={() =>
						<RouteTemplate title="Update Payment" navItem="11">
							<Route exact path={'/updatepayment'} component={UpdatePayment} />
						</RouteTemplate>
					} />
					<Route path={'/patch-notes'} component={() =>
						<RouteTemplate title="Patch notes" navItem="12">
							<Route exact path={'/patch-notes'} component={PatchNotes} />
						</RouteTemplate>
					} />
					<Redirect from="/login" to="/" />
					<Route path={'/*'} component={() =>
						<RouteTemplate title="Update Payment" navItem="13">
							<Route path="/*" component={NotFound} />
						</RouteTemplate>
					} />
				</Switch>
			</Router>
		);
	}
}

export default Root;
