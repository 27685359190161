import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import Loading from '../../components/loaders/Loading';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import Iframe from 'react-iframe';
import { Link } from 'react-router-dom';
import getSiteData from '../../helpers/getSiteData';
import InputMask from 'react-input-mask';
// import CTA from 'components/cta';
// import WebImage from '../../assets/web.svg';
import * as Scroll from 'react-scroll';
import ReactTooltip from 'react-tooltip';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaQuestionCircle } from 'react-icons/fa';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class Turnkey extends Component {
	state = {
		fieldData: {},
		scrollPosition: 0,
		showWarning: false,
		unsavedChanges: false,
		siteUrlBase: 'https://rosswareturnkey.com',
		siteSlug: `?${this.props.authStore.values?.business?.business_id}`,
		menuActive: '0'
	}

	componentDidMount() {
		console.log(this.props.authStore.values);
		this.setState({
			loading: true,
			loadingPreview: true
		});
		this.loadData(true);
	}

	setScrollPosition() {
		var scroll = Scroll.animateScroll;
		scroll.scrollTo(this.state.scrollPosition, {
			duration: 200,
			smooth: 'easeInOutQuart'
		});
	}

	loadData(fullRefresh) {
		let { business_id: businessID } = this.props.authStore.values?.business;
		this.setState({
			loading: fullRefresh,
			loadingPreview: true
		}, () => {
			getSiteData(businessID).then((data) => {
				this.setState({
					loading: false,
					loadingPreview: false,
					fieldData: _.get(data, 'data[0]')
				});
			});
		});
	}

	onChange = (fieldName, input) => {
		var inputValue = input.target.value;
		const checked = input.target.checked;
		if (checked && (fieldName === 'hero_overlay' || fieldName === 'show_brands' || fieldName === 'show_schedule' || fieldName === 'show_tracking' || fieldName === 'show_status')) {
			inputValue = 1;
		} else if (!checked && (fieldName === 'hero_overlay' || fieldName === 'show_brands' || fieldName === 'show_schedule' || fieldName === 'show_tracking' || fieldName === 'show_status')) {
			inputValue = 0;
		}

		const { fieldData } = this.state;
		fieldData[fieldName] = inputValue;
		this.setState({
			fieldData: fieldData,
			scrollPosition: window.pageYOffset
		}, () => {
			if (fieldName === 'hero_overlay' || fieldName === 'show_brands' || fieldName === 'show_schedule' || fieldName === 'show_tracking' || fieldName === 'show_status') {
				this.handleSubmit();
			}

		});
	}

	handleSubmit = async () => {
		this.setState({
			loadingPreview: true,
			scrollPosition: window.pageYOffset
		});
		let { token } = this.props.authStore.values;
		let fieldData = this.state.fieldData;
		//disgusting i know
		let results = await apiRequest({
			endpoint: 'v3/myrossware/updateTurnKeyData', parameters: {
				client_name: _.get(fieldData, 'client_name', ''),
				google_tag: _.get(fieldData, 'google_tag', ''),
				client_logo: _.get(fieldData, 'client_logo', ''),
				client_icon: _.get(fieldData, 'client_icon', ''),
				home_image: _.get(fieldData, 'home_image', ''),
				show_brands: _.get(fieldData, 'show_brands', ''),
				show_schedule: _.get(fieldData, 'show_schedule', ''),
				show_tracking: _.get(fieldData, 'show_tracking', ''),
				show_status: _.get(fieldData, 'show_status', ''),
				about_image: _.get(fieldData, 'about_image', ''),
				about_title_1: _.get(fieldData, 'about_title_1', ''),
				about_text_1: _.get(fieldData, 'about_text_1', ''),
				about_title_2: _.get(fieldData, 'about_title_2', ''),
				about_text_2: _.get(fieldData, 'about_text_2', ''),
				brands_title_1: _.get(fieldData, 'brands_title_1', ''),
				brands_text_1: _.get(fieldData, 'brands_text_1', ''),
				brands_list_1: _.get(fieldData, 'brands_list_1', ''),
				brands_title_2: _.get(fieldData, 'brands_title_2', ''),
				brands_text_2: _.get(fieldData, 'brands_text_2', ''),
				brands_list_2: _.get(fieldData, 'brands_list_2', ''),
				contact_phone_1: _.get(fieldData, 'contact_phone_1', ''),
				contact_phone_2: _.get(fieldData, 'contact_phone_2', ''),
				contact_phone_3: _.get(fieldData, 'contact_phone_3', ''),
				contact_email_1: _.get(fieldData, 'contact_email_1', ''),
				contact_email_2: _.get(fieldData, 'contact_email_2', ''),
				contact_address_1: _.get(fieldData, 'contact_address_1', ''),
				contact_address_2: _.get(fieldData, 'contact_address_2', ''),
				contact_address_3: _.get(fieldData, 'contact_address_3', ''),
				contact_hours_1: _.get(fieldData, 'contact_hours_1', ''),
				contact_hours_2: _.get(fieldData, 'contact_hours_2', ''),
				client_color: _.get(fieldData, 'client_color', ''),
				service_area: _.get(fieldData, 'service_area', '')
			},
			token,
		});
		if (results) {
			this.setState({
				loading: false,
			}, () => {
				this.setScrollPosition();
			});
			this.loadData();
		}
	}

	renderSite() {
		let fullUrl = this.state.siteUrlBase + this.state.siteSlug;
		return (
			<div className="h-100 sticky-top website-wrapper">
				<div className="h-100 position-relative">
					<Iframe
						url={fullUrl}
						width="100%"
						height="100%"
						id="myId"
						className="website-preview"
						display="initial"
						position="relative" />
				</div>
			</div>
		);
	}

	renderEditBar() {
		let fieldData = this.state.fieldData;
		if (_.get(fieldData, 'show_schedule') === 1) {
			var showSchedule = true;
		} else {
			showSchedule = false;
		}
		if (_.get(fieldData, 'show_tracking') === 1) {
			var showTracking = true;
		} else {
			showTracking = false;
		}
		if (_.get(fieldData, 'show_brands') === 1) {
			var showBrands = true;
		} else {
			showBrands = false;
		}
		if (_.get(fieldData, 'show_status') === 1) {
			var showStatus = true;
		} else {
			showStatus = false;
		}
		return (
			<div className="tk-edit-bar-wrapper">
				<ReactTooltip place="right" />
				<div className="d-flex justify-content-between mb-3">
					<Button as={Link} to="/website" variant="outline-secondary" className="mr-3">Return</Button>
					<Button onClick={() => this.handleSubmit()} to="/website" variant="outline-secondary">Save / Publish</Button>
				</div>
				<Accordion allowZeroExpanded preExpanded={[this.state.menuActive]}>
					<AccordionItem className="accordion-item" uuid="0">
						<AccordionItemHeading onClick={() => this.setState({ menuActive: '0' })}>
							<AccordionItemButton className="accordion-heading">Website Information</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className="accordion-body">
							<Form.Group>
								<Form.Label>Company Name <FaQuestionCircle data-tip="This is will be shown in the browser tab, as well as in your meta data." /></Form.Label>
								<Form.Control type="text" placeholder="Company Name" defaultValue={_.get(fieldData, 'client_name', '')} onChange={(event) => this.onChange('client_name', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Google Analytics <FaQuestionCircle data-tip="If you have Google analytics, place your key here." /></Form.Label>
								<Form.Control type="text" placeholder="UA-XXXXX-Y" defaultValue={_.get(fieldData, 'google_tag', '')} onChange={(event) => this.onChange('google_analytics', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Company Color <FaQuestionCircle data-tip="Use the hex code for your company&apos;s main color. Example: #DC251C" /></Form.Label>
								<div className="d-flex align-items-center">
									<span className="company-color" style={{ backgroundColor: _.get(fieldData, 'client_color', '') }} />
									<Form.Control as={InputMask} type="text" mask={'#******'} placeholder="Company Color" defaultValue={_.get(fieldData, 'client_color', '')} onChange={(event) => this.onChange('client_color', event)} />
								</div>
							</Form.Group>
							<Form.Check type="checkbox" defaultChecked={showSchedule} label="Display the online scheduler page" onChange={(event) => this.onChange('show_schedule', event)} />
							<Form.Check type="checkbox" defaultChecked={showTracking} label="Display the tech tracking page" onChange={(event) => this.onChange('show_tracking', event)} />
							<Form.Check type="checkbox" defaultChecked={showStatus} label="Display the job status page" onChange={(event) => this.onChange('show_status', event)} />
							<Form.Check type="checkbox" defaultChecked={showBrands} label="Display your brands/services" onChange={(event) => this.onChange('show_brands', event)} />
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem className="accordion-item" uuid="1">
						<AccordionItemHeading onClick={() => this.setState({ menuActive: '1' })}>
							<AccordionItemButton className="accordion-heading">Pictures</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className="accordion-body">
							<Form.Group>
								<Form.Label>Full Logo</Form.Label>
								<Form.Control type="text" placeholder="URL to logo" defaultValue={_.get(fieldData, 'client_logo', '')} onChange={(event) => this.onChange('client_logo', event)} />
								{_.get(fieldData, 'client_logo') && <img src={_.get(fieldData, 'client_logo', '')} className="border bg-light rounded p-2 mt-3" width="100%" />}
							</Form.Group>
							<Form.Group>
								<Form.Label>Logo Icon <FaQuestionCircle data-tip="This is a simplified version of your logo, if you have it." /></Form.Label>
								<Form.Control type="text" placeholder="URL to logo icon" defaultValue={_.get(fieldData, 'client_icon', '')} onChange={(event) => this.onChange('client_icon', event)} />
								{_.get(fieldData, 'client_icon') && <img src={_.get(fieldData, 'client_icon', '')} className="border bg-light rounded p-2 mt-3" width="100%" />}
							</Form.Group>
							<Form.Group>
								<Form.Label>Main Home Banner <FaQuestionCircle data-tip="This is the banner that will go across the top of your homepage." /></Form.Label>
								<Form.Control type="text" placeholder="Main Home Banner" defaultValue={_.get(fieldData, 'home_image', '')} onChange={(event) => this.onChange('home_image', event)} />
								{_.get(fieldData, 'home_image') && <img src={_.get(fieldData, 'home_image', '')} className="border bg-light rounded p-2 mt-3" width="100%" />}
							</Form.Group>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem className="accordion-item" uuid="2">
						<AccordionItemHeading onClick={() => this.setState({ menuActive: '2' })}>
							<AccordionItemButton className="accordion-heading">Home Text</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className="accordion-body">
							<Form.Group>
								<Form.Label>About Title 1</Form.Label>
								<Form.Control type="text" placeholder="Can be as simple as 'About'" defaultValue={_.get(fieldData, 'about_title_1', '')} onChange={(event) => this.onChange('about_title_1', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>About Body 1</Form.Label>
								<Form.Control type="text" as="textarea" rows="5" placeholder="Home Subtext" defaultValue={_.get(fieldData, 'about_text_1', '')} onChange={(event) => this.onChange('about_text_1', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>About Title 2</Form.Label>
								<Form.Control type="text" placeholder="Home Main Title" defaultValue={_.get(fieldData, 'about_title_2', '')} onChange={(event) => this.onChange('about_title_2', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>About Body 2</Form.Label>
								<Form.Control type="text" as="textarea" rows="5" placeholder="Home Subtext" defaultValue={_.get(fieldData, 'about_text_2', '')} onChange={(event) => this.onChange('about_text_2', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Service Territory Description</Form.Label>
								<Form.Control type="text" as="textarea" placeholder="Home Text 2" defaultValue={_.get(fieldData, 'service_area', '')} onChange={(event) => this.onChange('service_area', event)} />
							</Form.Group>
						</AccordionItemPanel>
					</AccordionItem>
					{_.get(fieldData, 'show_brands') === 1 && <AccordionItem className="accordion-item" uuid="3">
						<AccordionItemHeading onClick={() => this.setState({ menuActive: '3' })}>
							<AccordionItemButton className="accordion-heading">Brands and Machines you service</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className="accordion-body">
							<Form.Group>
								<Form.Label>Brands/Machines Title 1</Form.Label>
								<Form.Control type="text" placeholder="Machines Title 1" defaultValue={_.get(fieldData, 'brands_title_1', '')} onChange={(event) => this.onChange('brands_title_1', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Brands/Machines Description 1</Form.Label>
								<Form.Control type="text" as="textarea" placeholder="Brief description of what machines or services your provide." defaultValue={_.get(fieldData, 'brands_text_1', '')} onChange={(event) => this.onChange('brands_text_1', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Brands/Machines List 1</Form.Label>
								<Form.Control type="text" placeholder="Dishwashers, Freezers, Microwaves" defaultValue={_.get(fieldData, 'brands_list_1', '')} onChange={(event) => this.onChange('brands_list_1', event)} />
								<Form.Text className="text-muted">
									Enter each machine type separated by a comma
								</Form.Text>
							</Form.Group>
							<Form.Group>
								<Form.Label>Brands/Machines Title 2</Form.Label>
								<Form.Control type="text" placeholder="Brands Title 1" defaultValue={_.get(fieldData, 'brands_title_2', '')} onChange={(event) => this.onChange('brands_title_2', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Brands/Machines Description 2</Form.Label>
								<Form.Control type="text" as="textarea" placeholder="Brief description of the brands you service." defaultValue={_.get(fieldData, 'brands_text_2', '')} onChange={(event) => this.onChange('brands_text_2', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Brands/Machines List 2</Form.Label>
								<Form.Control type="text" placeholder="Maytag, GE, Whirlpool" defaultValue={_.get(fieldData, 'brands_list_2', '')} onChange={(event) => this.onChange('brands_list_2', event)} />
								<Form.Text className="text-muted">
									Enter each brand name separated by a comma
								</Form.Text>
							</Form.Group>
						</AccordionItemPanel>
					</AccordionItem>}
					<AccordionItem className="accordion-item" uuid="5">
						<AccordionItemHeading onClick={() => this.setState({ menuActive: '5' })}>
							<AccordionItemButton className="accordion-heading">Your company contact information</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className="accordion-body">
							<Form.Group>
								<Form.Label>Phone 1</Form.Label>
								<Form.Control type="text" placeholder="Phone 1" defaultValue={_.get(fieldData, 'contact_phone_1', '')} onChange={(event) => this.onChange('contact_phone_1', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Phone 2</Form.Label>
								<Form.Control type="text" placeholder="Phone 2" defaultValue={_.get(fieldData, 'contact_phone_2', '')} onChange={(event) => this.onChange('contact_phone_2', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Email 1</Form.Label>
								<Form.Control type="text" placeholder="Email 1" defaultValue={_.get(fieldData, 'contact_email_1', '')} onChange={(event) => this.onChange('contact_email_1', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Email 2</Form.Label>
								<Form.Control type="text" placeholder="Email 2" defaultValue={_.get(fieldData, 'contact_email_2', '')} onChange={(event) => this.onChange('contact_email_2', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Address Line 1</Form.Label>
								<Form.Control type="text" placeholder="Address Line 1" defaultValue={_.get(fieldData, 'contact_address_1', '')} onChange={(event) => this.onChange('contact_address_1', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Address Line 2</Form.Label>
								<Form.Control type="text" placeholder="Address Line 2" defaultValue={_.get(fieldData, 'contact_address_2', '')} onChange={(event) => this.onChange('contact_address_2', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Address Line 3</Form.Label>
								<Form.Control type="text" placeholder="Address Line 3" defaultValue={_.get(fieldData, 'contact_address_3', '')} onChange={(event) => this.onChange('contact_address_3', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Hours Line 1</Form.Label>
								<Form.Control type="text" placeholder="Hours Line 1" defaultValue={_.get(fieldData, 'contact_hours_1', '')} onChange={(event) => this.onChange('contact_hours_1', event)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Hours Line 2</Form.Label>
								<Form.Control type="text" placeholder="Hours Line 2" defaultValue={_.get(fieldData, 'contact_hours_2', '')} onChange={(event) => this.onChange('contact_hours_2', event)} />
							</Form.Group>
						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>
			</div>
		);
	}

	render() {
		if (this.state.loading) {
			return <Loading />;
		}
		return (
			<div className="tk-wrapper">
				<div className="tk-grid">
					{this.renderEditBar()}
					<div>
						{!this.state.loadingPreview && this.renderSite()}
					</div>
				</div>

			</div>
		);
	}
}

export default Turnkey;

