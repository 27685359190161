import React, { Component } from 'react';
import { Form, Button, Spinner, Card } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import InlineLoading from '../../components/loaders/InlineLoading';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class BluebookMakes extends Component {
	state = {
		// should remain unchanged
		BBMakes: {},
		SDMakes: {},
		// subject to change based on user interaction
		makeMap: {},
		selectedBBMake: 'default',
		selectedFromUnmapped: [],
		selectedFromMapped: [],
		loading: true,
		loadingSave: false,
	}
	componentDidMount() {
		this.getMakes();
	}
	getMakes = async () => { // fills state with data
		this.setState({ loading: true });
		const { token } = this.props.authStore.values;
		const BBMakes = await apiRequest({ endpoint: 'v3/myrossware/getBBMakes', token });
		const SDMakes = await apiRequest({ endpoint: 'v3/myrossware/getSDMakes', token });
		const BBMakeMap = await apiRequest({ endpoint: 'v3/myrossware/getBBMakeMap', token });

		let BBMakeMapObject = {};
		BBMakeMap.data.forEach(make => {
			if (_.has(BBMakeMapObject, make.bbItmStrng)) {
				BBMakeMapObject[make.bbItmStrng].push(make.RWItmStrng);
			} else {
				BBMakeMapObject[make.bbItmStrng] = [];
				BBMakeMapObject[make.bbItmStrng].push(make.RWItmStrng);
			}
		});
		this.setState({ BBMakes: BBMakes.data, SDMakes: SDMakes.data, makeMap: BBMakeMapObject, loading: false });
	}

	// render options below

	renderBBMakesOptions() { // renders makes from SCS BB data
		const { BBMakes } = this.state;
		if (BBMakes) {
			return BBMakes.map((make) => {
				return <option key={make.BrandId}>{make.BrandName}</option>;
			});
		}
	}
	renderAllSDMakeOptions() { // renders list of SD makes with relevant ones selected
		const { SDMakes } = this.state;
		return SDMakes.map((make) => {
			return (
				<option key={make.ItmStrng}>{make.ItmStrng}</option>
			);
		});
	}
	renderMappedSDMakeOptions() {
		const { selectedBBMake, makeMap } = this.state;
		const mappedMakes = makeMap[selectedBBMake] || [];
		return mappedMakes.map((make) => {
			return (
				<option key={make}>{make}</option>
			);
		});
	}

	// on change/click functions below

	onBBMakeSelectChange = (event) => {
		this.setState({ selectedBBMake: event.target.value });
	}
	onMappedSelectChange = (event) => {
		var options = event.target.options;
		var value = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}
		this.setState({ selectedFromMapped: value });
	}
	onUnmappedSelectChange = (event) => {
		var options = event.target.options;
		var value = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}
		this.setState({ selectedFromUnmapped: value });
	}
	onMapButton = () => {
		const { selectedBBMake, selectedFromUnmapped, makeMap } = this.state;
		selectedFromUnmapped.forEach(element => {
			if (makeMap[selectedBBMake]) {
				if (!makeMap[selectedBBMake].includes(element)) {
					makeMap[selectedBBMake].push(element);
				}
			} else {
				makeMap[selectedBBMake] = [];
				makeMap[selectedBBMake].push(element);
			}
		});
		this.setState({ makeMap: makeMap, selectedFromUnmapped: [] });
	}
	onUnmapButton = () => {
		const { selectedBBMake, selectedFromMapped, makeMap } = this.state;
		let indexesToBeUnmapped = [];
		selectedFromMapped.forEach(element => {
			let dex = makeMap[selectedBBMake].indexOf(element);
			indexesToBeUnmapped.push(dex);
		});
		const newMakeMap = makeMap[selectedBBMake].filter((make) => {
			const indexOfItemInMakeMap = makeMap[selectedBBMake].indexOf(make);
			return !indexesToBeUnmapped.includes(indexOfItemInMakeMap);
		});
		makeMap[selectedBBMake] = newMakeMap;
		this.setState({ makeMap: makeMap, selectedFromMapped: [] });
	}
	onSaveButton = async () => {
		this.setState({ loadingSave: true });
		const { token } = this.props.authStore.values;
		const makeMap = JSON.stringify(this.state.makeMap);
		await apiRequest({ endpoint: 'v3/myrossware/setBBMakeMap', parameters: { bb_make_map_object: makeMap }, token });
		this.setState({ loadingSave: false });
	}
	render() {
		if (this.state.loading) {
			return (
				<Card>
					<Card.Body>
						<InlineLoading />
					</Card.Body>
				</Card>
			);
		} else {
			return (
				<Card>
					<Card.Title className="p-4 border-bottom">Map Makes</Card.Title>
					<Card.Body>
						<small>Select Bluebook Make</small>
						<Form.Control defaultValue={this.state.selectedBBMake} as="select" onChange={this.onBBMakeSelectChange}>
							<option disabled value="default">Select a Bluebook Make</option>
							{this.renderBBMakesOptions()}
						</Form.Control>
						<br></br>
						<small>Mapped ServiceDesk Makes</small>
						<Form.Control as="select" multiple className="SDMakeSelect" onChange={this.onMappedSelectChange}>
							{this.renderMappedSDMakeOptions()}
						</Form.Control>
						<br />
						<Button disabled={!this.state.selectedFromUnmapped.length > 0} variant="secondary" size="sm" onClick={this.onMapButton}>Map <MdKeyboardArrowUp /></Button>&nbsp;
						<Button disabled={!this.state.selectedFromMapped.length > 0} variant="secondary" size="sm" onClick={this.onUnmapButton}>Unmap <MdKeyboardArrowDown /></Button><br />
						<small>Your ServiceDesk Makes</small>
						<Form.Control as="select" multiple className="SDMakeSelectedSelect" onChange={this.onUnmappedSelectChange}>
							{this.renderAllSDMakeOptions()}
						</Form.Control>
						<br></br>
						{!this.state.loadingSave && <Button variant="primary" className="mt-2" onClick={this.onSaveButton}>Save Mapping</Button>}
						{this.state.loadingSave && <Button variant="primary" className="mt-2" onClick={this.onSaveButton}><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;Saving</Button>}
					</Card.Body>

				</Card>
			);
		}
	}
}

export default BluebookMakes;

