import InlineLoading from 'components/loaders/InlineLoading';
import React, { Component } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import apiRequest from 'utilities/api';
import { inject, observer } from 'mobx-react';

@inject('authStore')
@observer class RosswarePaySettings extends Component {
	state = {
		loading: true,
		enabledTipAmounts: [],
		renderTipsSettings: false,
		renderPlatformFeeSettings: false,

		configuration: {},
		configurationIsValid: false,
	}
	async componentDidMount() {
		const { token } = this.props.authStore.values;
		const existingSettings = await apiRequest ({ endpoint: 'v3/rosswarepay/getRosswarePayConfiguration', token });
		const configuration = existingSettings?.data;
		const enabledTipAmounts = [configuration?.default_tip_1, configuration?.default_tip_2, configuration?.default_tip_3].filter(tip => tip !== null).map(tip => parseInt(tip));
		this.setState({ configuration, enabledTipAmounts, loading: false });
	}
	render() {
		const { configuration, enabledTipAmounts } = this.state;

		const saveConfiguration = async () => {
			this.setState({ loading: true });
			const { token } = this.props.authStore.values;
			enabledTipAmounts.sort((a,b) => {
				return a - b;
			}).forEach((tip, key) => {
				configuration[`default_tip_${key + 1}`] = `${tip}%`;
			});
			await apiRequest ({ endpoint: 'v3/rosswarepay/setRosswarePayConfiguration', token, parameters: configuration });
			this.setState({ loading: false });
		};

		const toggleTipCheckbox = (amount) => {
			if (enabledTipAmounts.includes(amount)) {
				enabledTipAmounts.splice(enabledTipAmounts.indexOf(amount), 1);
			} else {
				enabledTipAmounts.push(amount);
			}
			this.setState({ enabledTipAmounts });
		};

		const changeConfigurationBoolInStateOnChange = (e) => {
			const { configuration } = this.state;
			if (!([e.target.name] in configuration)) {
				console.log('no configuration found for ' + e.target.name);
				return false;
			}
			const currentlyChecked = configuration[e.target.name] === 'true' || configuration[e.target.name] === true || configuration[e.target.name] === 1;
			configuration[e.target.name] = !currentlyChecked;
			this.setState({ configuration });
		};

		const isTipPercentageChecked = (amount) => {
			const { default_tip_1, default_tip_2, default_tip_3 } = configuration;
			const tipAmountsCurrentlyEnabled = [default_tip_1, default_tip_2, default_tip_3].map(tip => parseInt(tip));
			return tipAmountsCurrentlyEnabled.includes(amount);
		};

		const renderTipBoxes = () => {
			return [5, 10, 15, 20, 25].map((tipPercentage, key) => {
				return (
					<Form.Check
						key={key}
						inline
						label={`${tipPercentage}%`}
						name="group1"
						type="checkbox"
						id={`inline-checkbox-${key}`}
						onChange={ () => {
							toggleTipCheckbox(tipPercentage);
							validateConfiguration();
						}}
						disabled={ enabledTipAmounts.length >= 3 && !enabledTipAmounts.includes(tipPercentage) }
						defaultChecked={ isTipPercentageChecked(tipPercentage) }
					/>
				);
			});
		};

		const validateConfiguration = () => {
			function isConfigValid () {
				if (configuration?.enable_tips && (enabledTipAmounts.length !== 3 || !configuration?.tip_text)) {
					console.log('tips not valid');
					return false;
				}
				if (configuration?.enable_platform_fee && (!configuration?.platform_fee_amount)) {
					console.log('platform fee not valid', configuration.platform_fee_amount);
					return false;
				}
				return true;
			}
			if (isConfigValid()) {
				this.setState({ configurationIsValid: true });
			} else {
				this.setState({ configurationIsValid: false });
			}
		};

		if (this.state.loading) { return <div><InlineLoading /></div>; } else {
			return (
				<div>
					<div className="page-header">
						<h1>Settings</h1>
					</div>

					<Alert variant="warning">Payments in ServiceDesk (reports, funds journal, sales journal, etc.) <b>do not currently</b> reflect platform fees or tips. Be very sure you consider the repercussions of this before enabling these features. <br /><br />Separate reporting for tips and platform fees can be found within this dashboard.</Alert>
					<div className="card">
						<div className="card-body">
							<Form.Check
								style={{ float: 'right' }}
								type="switch"
								name="enable_tips"
								label=''
								id="enable_tips"
								onClick={(e) => {
									changeConfigurationBoolInStateOnChange(e);
									validateConfiguration();
								}}
								defaultChecked={configuration?.enable_tips}
							/>
							<h4>Tips / Gratuities</h4>
							<small>Configure to allow the payer to leave a tip when paying for service</small>

							<div className={!configuration?.enable_tips ? 'd-none' : ''}>
								<br /><br />
								Which tip options would you like to present to your customers?
								Choose exactly 3.
								<div key="inline-checkbox" className="mb-3">
									{ renderTipBoxes() }

									<br /><br />
									Enter a short message to prompt the payer to enter a tip.
									<Form.Control
										type="text"
										defaultValue={ configuration?.tip_text }
										onChange={(e) => {
											configuration.tip_text = e.target.value;
											validateConfiguration();
										}}
									/>
								</div>

							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<Form.Check
								style={{ float: 'right' }}
								type="switch"
								name="enable_platform_fee"
								label=''
								id="enable_platform_fee"
								onClick={(e) => {
									changeConfigurationBoolInStateOnChange(e);
									validateConfiguration();
								}}
								defaultChecked={configuration?.enable_platform_fee}
							/>
							<h4>Platform fee</h4>
							<small>Configure to assess a platform fee when collecting for service</small>
							<div className={!configuration?.enable_platform_fee ? 'd-none' : ''}>
								<br /><br />
								Enter platform fee as a percentage (e.g. 2.5%). Maximum allowed is 4%.
								<Form.Control
									type="number"
									defaultValue={ parseFloat(configuration?.platform_fee_amount) }
									onChange={(e) => {
										console.log(e.target.value);
										if (e.target.value.length === 0 || e.target.value === '0' || e.target.value === '' || e.target.value === undefined || e.target.value > 4) {
											configuration.platform_fee_amount = 0;
										} else {
											configuration.platform_fee_amount = `${parseFloat(e.target.value)}%`;
										}
										validateConfiguration();
									}}
									max={4}
									min={0.25}
									step={0.25}
									onBeforeInput={(e) => {
										const regex = new RegExp('^[0-9.]');
										if (e.data !== null && !regex.test(e.data) || e.target.value.length >= 4)
											e.preventDefault();
									}}
								/>
							</div>
						</div>
					</div>
					<Button disabled={ !this.state.configurationIsValid } onClick={ () => saveConfiguration() }>Save</Button>
				</div>
			);
		}
	}
}

export default RosswarePaySettings;