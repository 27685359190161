import { observable, action } from 'mobx';
import apiRequest from 'utilities/api';
import authStore from '../stores/authStore';

class techStore {
	@observable values = {
		techList: []
	}

	@action getTechList = async () => {
		const { token } = authStore.values;
		const result = await apiRequest({ endpoint: 'v3/getTechnicians', token });

		result?.data.sort(sorter);
		const sorter = (left, right) => {
			if (left.TechCode < right.TechCode) return -1;
			if (left.TechCode > right.TechCode) return 1;
			return 0;
		};
		return result.data;
	}

	@action getTechImages = async () => {
		const { token } = authStore.values;
		const result = await apiRequest({ endpoint: 'v3/myrossware/getTechImages', token });

		return result.data;
	}

	@action async fetchTechInfo() {
		const { businessID, password } = authStore.values;
		const url = `${process.env.REACT_APP_API_BASE_URL}/v3/getSyncInfo?business_id=${businessID}&business_password=${password}`;
		const response = await fetch(url);
		const data = await response.json();
		this.values.techList = data.data;
	}
}

export default new techStore();