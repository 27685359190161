import { trim } from 'lodash';

export function validatePaymentProcessorSettings(selectedProcessor, processorInfo) {
	switch (selectedProcessor) {
	case 'tsys':
		if (!validateText(processorInfo.tsys.name)) {
			return false;
		}
		if (!validateTsysSiteId(processorInfo.tsys.site_id)) {
			return false;
		}
		if (!validateTsysKey(processorInfo.tsys.key)) {
			return false;
		}
		break;
	}
	return true;

}

export function validateIpWhitelistInputs(inputs) {
	if (!validateIpV4(inputs.ip)) { return false; }
	if (!validateText(inputs.friendly)) { return false; }
	if (inputs.duration === null) { return false; }
	return true;
}

export function validateIpV4(ipv4) {
	const parts = ipv4.split('.');
	if (parts.length !== 4) { return false; }
	if (parts.includes('') || parts.includes(undefined)) { return false; }
	return true;
}

export function validateTsysKey(key) {
	const alphaNumericOnly = key.replace(/[^0-9a-z]/gi, '');
	if (alphaNumericOnly.length !== 25) { return false; }
	return true;
}

export function validateTsysSiteId(siteId) {
	const alphaNumericOnly = siteId.replace(/[^0-9a-z]/gi, '');
	if (alphaNumericOnly.length !== 8) { return false; }
	return true;
}

export function validateEmail(incomingValue) {
	return String(incomingValue)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
}

export function validateText(value) {
	if (!value || value === null || value === undefined || value === '') {
		return false;
	}
	if (typeof(value) === 'object') { return true; }
	if (value && trim(value).length > 0 && value !== 'default' && value !== '') { return true; }
	return false;
}

export function validatePhone(value) {
	if (!value || value === null || value === undefined || value === '') {
		return false;
	}
	var strippedPhone = value.replace(/\D/g,'');
	if (strippedPhone.length === 10 || strippedPhone.length === 11) {
		return true;
	}
	return false;
}

export function validateBusinessId(value) {
	const pattern = /^(\d\d\d\d)$/;
	return pattern.test(value);
}

function checkPasswordMeetsComplexity(password) {
	const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
	return pattern.test(password);
}

export function validateEverything(fields) {
	const validations = {
		email: validateEmail,
		phone: validatePhone,
		text: validateText,
		business_id: validateBusinessId,
	};

	const arrayOfFields = Object.keys(fields || {}).map(item => {
		const objectAtKey = fields[item];
		objectAtKey.name = item;
		return objectAtKey;
	});

	for (let dex = 0; dex < arrayOfFields.length; dex++) {
		const validation = validations[arrayOfFields[dex].type];
		arrayOfFields[dex].valid = validation(arrayOfFields[dex].value);
	}

	const unfilledRequiredFields = arrayOfFields.filter(item => item.required === true && (item.value === '' || item.value === undefined || item.value === null || !item?.value || item.value === '____') );
	const invalidRequiredFields = arrayOfFields.filter(item => item.required === true && !item.valid);

	let message = null;
	let allValid = false;

	if (unfilledRequiredFields.length > 0) {
		if (unfilledRequiredFields.length > 3) {
			message = 'All fields are required';
		} else {
			message = `Missing required fields: ${unfilledRequiredFields.map(field => field.proper_name).join(', ')}`;
		}
	} else if (invalidRequiredFields.length > 0) {
		message = `Please check invalid fields: ${invalidRequiredFields.map(field => field.proper_name).join(', ')}`;
	} else if (!fields?.password) {
		allValid = true;
		return { arrayOfFields, message, allValid };
	} else if (fields?.password.value !== fields?.confirm_password.value) {
		message = 'Ensure passwords are matching.';
	} else if (fields?.password && checkPasswordMeetsComplexity(fields?.password.value)) {
		allValid = true;
	}

	return { arrayOfFields, message, allValid };
}