import React, { Component } from 'react';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Cards from 'react-credit-cards';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class UpdatePayment extends Component {
	state = {
		cvc: '',
		expiry: '',
		focus: '',
		name: '',
		number: '',
		loading: false,
		success: false
	};

	handleInputFocus = (e) => {
		this.setState({ focus: e.target.name });
	}

	handleInputChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleSubmit = async () => {
		this.setState({
			loading: true
		});
		let data = {
			name: this.state.name,
			cc: this.state.number,
			exp: this.state.expiry,
			cvc: this.state.cvc
		};
		const response = await apiRequest({ endpoint: 'v3/myrossware/sendSecure', method: 'POST', body: { data: btoa(JSON.stringify(data)) }, token: this.props.authStore.values?.token });
		if (response) {
			this.setState({
				loading: false,
				success: true,
				cvc: '',
				expiry: '',
				focus: '',
				name: '',
				number: '',
			});
			return (<Redirect to={'/'}/>);
		}
	}

	render() {
		if (this.state.success) {
			return (
				<Alert variant="success">Success!</Alert>
			);
		}
		return (

			<div className="card">
				<div className="card-body">
					<div className="mb-4 container-sm">
						<Form.Control
							className="mb-3"
							type="tel"
							name="number"
							placeholder="Card Number"
							onChange={this.handleInputChange}
							onFocus={this.handleInputFocus}
							as={InputMask}
						/>
						<Form.Control
							className="mb-3"
							type="text"
							name="name"
							placeholder="Name on Card"
							onChange={this.handleInputChange}
							onFocus={this.handleInputFocus}
							as={InputMask}
						/>
						<div className="grid-2-col">
							<Form.Control
								type="text"
								name="expiry"
								placeholder="Expiration"
								onChange={this.handleInputChange}
								onFocus={this.handleInputFocus}
								as={InputMask}
							/>
							<Form.Control
								type="tel"
								name="cvc"
								placeholder="CVC"
								onChange={this.handleInputChange}
								onFocus={this.handleInputFocus}
								as={InputMask}
							/>
						</div>
					</div>
					<div className="">
						<Cards
							cvc={this.state.cvc}
							expiry={this.state.expiry}
							focused={this.state.focus}
							name={this.state.name}
							number={this.state.number}
						/>
					</div>
				</div>
				<div className="card-footer">
					<Button className="btn btn-dark float-right" onClick={() => this.handleSubmit()}>
						{this.state.loading && <Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
							className="mr-3"
						/>}
						Submit update
					</Button>
				</div>
			</div>
		);
	}
}

export default UpdatePayment;