import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

export class NotSubscribedMessage extends Component {
	render() {
		const { head, p, link } = this.props;
		return (
			<Card>
				<Card.Body>
					<h4>{head}</h4>
					<p>{p}</p>
					<a href={link || ''}>{link || ''}</a>
				</Card.Body>
			</Card>
		);
	}
}

export default NotSubscribedMessage;

