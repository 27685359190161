export function convertPlatformReadable(platform) {
	if (!platform) {
		return 'Unknown';
	}
	const possiblePlatforms = {
		'flxa': 'Android', 
		'flxi': 'iOS'
	};
	if ({}.hasOwnProperty.call(possiblePlatforms, platform)) {
		return possiblePlatforms[platform];
	} else {
		return platform;
	}
}

export function convertVersionReadable(version) {
	if (!version) {
		return 'Unknown';
	}
	return version.replace(/[a-z]/gi, '');
}

export function cleanTechList(techniciansObject) {
	for (let i = 0; i < techniciansObject.length; i++) {
		const currentObject = techniciansObject[i];
		const techCode = currentObject.TechCode;
		const platform = currentObject.Platform;
		if (techCode === '' || platform === '') {
			techniciansObject.splice(i, 1);
		}
	}
	techniciansObject.sort(function(a,b) {
		if (a.TechCode < b.TechCode) {
			return -1; 
		}
		if (a.TechCode > b.TechCode) {
			return 1; 
		}
		if (a.TechCode === b.TechCode) {
			return 0; 
		}
		return true;
	});
	return techniciansObject;
}

export function parsePartsArrayToString(partsArray) {
	if (!partsArray.length) {
		return 'None';
	}
	const resultString = partsArray.map((curr) => {
		return curr.part_number + ' (x' + (curr.quantity ? curr.quantity : 1) + ')';
	}).join(', ');
	return resultString;
}

export function parseJobStatusFromCode(status) {
	const statuses = {
		follow_up_required: 'Follow-up Required',
		complete: 'Complete',
		no_show: 'No-show'
	};
	if (statuses[status]) {
		return statuses[status];
	} else {
		return 'Unknown';
	}
}

export function returnUnknownIfFalse(item, returnValIfFalse) {
	if (!item) {
		return returnValIfFalse;
	} else {
		return item;
	}
}


