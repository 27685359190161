import React, { Component, Fragment } from 'react';
import { Badge, Table } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { formatPhoneReadable } from 'helpers/formatting';

@inject('authStore')
@observer class Dashboard extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		// 	this.props.authStore.reAuthenticate();
	}
	renderBadge = (value) => {
		switch (value) {
		case 'full_permissions_with_support':
			return (
				<Badge variant="success">Active</Badge>
			);
		default:
			return (
				<Badge variant="secondary">Inactive</Badge>
			);
		}
	}

	showPassword() {
		this.setState({ showPassword: true });
	}
	render() {
		const values = this.props.authStore.nonObservableValues;
		return (
			<div>
				<h1 className="page-title">Your dashboard </h1>
				<div className="grid-2-col">
					<div className="card">
						<div className="card-body">
							<h3>Company info</h3>
							<div className="grid-2-col alternate-children">
								<div>
									<div className="text-muted small font-weight-bold">Company</div>
									<div>{values?.business?.name ? values?.business?.name : 'Not defined'}</div>
								</div>
								<div>
									<div className="text-muted small font-weight-bold">UserID</div>
									<div>{values?.business?.business_id ? values?.business?.business_id : 'Not defined'}</div>
								</div>
								<div>
									<div className="text-muted small font-weight-bold">UserName</div>
									<div>{values?.business?.business_id ? values?.business?.subscriptions?.user_name : 'Not defined'}</div>
								</div>
								<div>
									{ values?.business?.subscriptions?.business_password &&
									<Fragment>
										<div className="text-muted small font-weight-bold">Download password</div>
										<div>
											{values?.business?.subscriptions?.business_password}
										</div>
									</Fragment>
									}
								</div>
								<div>
									{ values?.business?.subscriptions?.web_password &&
									<Fragment>
										<div className="text-muted small font-weight-bold">Business password</div>
										<div>
											{values?.business?.subscriptions?.web_password}
										</div>
									</Fragment>
									}
								</div>
								<div>
									<div className="text-muted small font-weight-bold">Phone number</div>
									<div>{values?.business?.phone ? formatPhoneReadable(values?.business?.phone) : 'Not defined'}</div>
								</div>
								<div>
									<div className="text-muted small font-weight-bold">Address</div>
									<div>
										{values?.business?.address_1 ? values?.business?.address_1 : 'Not defined'}<br />
										{values?.business?.address_2 ? values?.business?.address_2 : 'Not defined'}
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<Link className="btn btn-dark float-right" to="/updatepayment">Update payment information</Link>
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<h3>User info</h3>
							<div className="grid-2-col alternate-children">
								<div>Name</div>
								<div>{ `${values.user.first_name} ${values.user.last_name}` }</div>
								<div>Email Address</div>
								<div>{values?.user?.email_address ? values?.user?.email_address : 'Not defined'}</div>
							</div>
						</div>
						<div className="card-footer">
							<Link className="btn btn-dark float-right" to="/users">User settings</Link>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-body">
						<h3>Current Subscriptions</h3>
						<Table borderless size="sm">
							<tbody>
								<tr>
									<td>SD-Support</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.service_desk)}</td>
								</tr>
								<tr>
									<td>SD-Mobile</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.service_desk_mobile)}</td>
								</tr>
								<tr>
									<td>CyberOffice</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.service_desk_cyber_link)}</td>
								</tr>
								<tr>
									<td>ServiceBench DispatchLink</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.service_bench_dispatch_link)}</td>
								</tr>
								<tr>
									<td>ServicePower DispatchLink</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.service_power_dispatch_link)}</td>
								</tr>
								<tr>
									<td>LG DispatchLink</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.lg_dispatch_link)}</td>
								</tr>
								<tr>
									<td>Samsung DispatchLink</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.samsung_dispatch_link)}</td>
								</tr>
								<tr>
									<td>Dispatch.Me DispatchLink</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.dispatch_me_dispatch_link)}</td>
								</tr>
								<tr>
									<td>ServiceLive DispatchLink</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.service_live_dispatch_link)}</td>
								</tr>
								<tr>
									<td>AHS Updater</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.american_home_shield_status_updater)}</td>
								</tr>
								<tr>
									<td>SD-RevenueBuilder</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.service_desk_revenue_builder)}</td>
								</tr>
								<tr>
									<td>SD-Dealer</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.service_desk_dealer)}</td>
								</tr>
								<tr>
									<td>SmartParts</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.smart_parts)}</td>
								</tr>
								<tr>
									<td>EmailedDispatchReceiver</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.emailed_dispatch_receiver)}</td>
								</tr>
								<tr>
									<td>BB-Sync+</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.my_parts_help)}</td>
								</tr>
								<tr>
									<td>Turnkey Website</td>
									<td>{this.renderBadge(values?.business?.subscriptions?.turnkey)}</td>
								</tr>
								{/* <tr>
									<td>Rossware Server Solutions</td>
									<td>{this.renderBadge(values.subscriptions.virtual_terminal)}</td>
								</tr> */}

							</tbody>
						</Table>
						<div>Need to make a change to your subscriptions? Call 800-353-4101 or email <a href="mailto:billing@rossware.net">billing@rossware.net</a></div>
					</div>

				</div>



			</div>
		);
	}
}

export default Dashboard;