import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import InlineLoading from '../loaders/InlineLoading';
import { Table } from 'react-bootstrap';
import decimalsAndCommas from 'helpers/currency';
import { capitalizeFirstLetter } from 'helpers/strings';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class CompleteList extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		this.getPayments();
	}
	getPayments = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/listPaymentRequests', token });
		const complete = [];
		data.forEach(payment => {
			if (payment.payment_complete === 1) {
				complete.push(payment);
			}
		});
		complete.sort((first, second) => {
			if (first.when_paid > second.when_paid) {
				return -1;
			} else if (first.when_paid < second.when_paid) {
				return 1;
			} else {
				return 0;
			}
		});
		this.setState({ payments: complete, loading: false });
	}
	renderPayments() {
		const { payments } = this.state;
		return ((payments || []).map((payment) => {
			return (
				<tr key={payment.id}>
					<td key={payment.id + 'customer'}>{`${capitalizeFirstLetter(payment.customer_last_name)}, ${capitalizeFirstLetter(payment.customer_first_name)}`}</td>
					<td key={payment.id + 'amount'}>{`$${decimalsAndCommas(payment.amount_to_charge)}`}</td>
					<td key={payment.id + 'invoice'}>{payment.invoice_number}</td>
					<td key={payment.id + 'dateCompleted'}>{payment.when_paid}</td>
				</tr>
			);
		}));
	}
	render() {
		if (this.state.loading) { return <InlineLoading />; } else {
			const { payments } = this.state;
			if (payments.length < 1) { return <div>No payments complete. </div>; }
			return (
				<Table striped size="sm" className="mt-3 myRwSmallTable">
					<thead>
						<tr>
							<th>Customer</th>
							<th>Amount</th>
							<th>Invoice</th>
							<th>Completed</th>
						</tr>
					</thead>
					<tbody>
						{this.renderPayments()}
					</tbody>
				</Table>
			);
		}
	}
}

export default CompleteList;
