import React, { Component, Fragment } from 'react';
import PaginationComponent from '../../components/Pagination';
import { inject, observer } from 'mobx-react';
import { Table, Card, Modal, Alert } from 'react-bootstrap';
import CallLogLoading from '../../components/loaders/CallLogLoading';
import { parseCallType } from '../../helpers/robocalling';
import verifyObjectLength from '../../helpers/verifyObject';
import { formatPhoneReadable } from '../../helpers/formatting';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class RoboSMSLog extends Component {
	state = {
		loading: true,
		selectedSMS: null,
	}
	componentDidMount() {
		this.initializeRoboLog();
	}
	initializeRoboLog = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/getsmslogpage', parameters: { per_page: 25, page: 0, sort_by: 'WhenSent', sort_direction: 'DESC' }, token });
		this.setState({ roboLog: data.data, numPages: data.pages, loading: false });
		this.setState({ pagination: this.renderPagination(this.state.numPages) });
	}
	getRoboLog = async (pageNum) => {
		this.setState({ loading: true });
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/getsmslogpage', parameters: { per_page: 25, page: pageNum, sort_by: 'WhenSent', sort_direction: 'DESC' }, token });
		this.setState({ roboLog: data.data, loading: false });
	}
	renderPagination(numPages) {
		return (<PaginationComponent key="paginationComponentKey" numPages={numPages} currentPage="1" getRoboLog={(pageNum) => this.getRoboLog(pageNum)} />);
	}
	renderLog() {
		const { roboLog } = this.state;
		return ((roboLog || []).map((roboLogItem) => {
			return (
				<tr style={{ cursor: 'pointer' }} onClick={ () => { this.setState({ selectedSMS: roboLogItem }); } } key={roboLogItem.call_id}>
					<td key={roboLogItem.invoice_number + 'TechCode'}>{roboLogItem.invoice_number === 0 ? 'N/A' : roboLogItem.invoice_number}</td>
					<td key={roboLogItem.to_from + 'TechName'}>{formatPhoneReadable(roboLogItem.to_from)}</td>
					<td key={roboLogItem.when_sent + 'Platform'}>{roboLogItem.when_sent}</td>
				</tr>
			);
		}));
	}
	renderSMSDetailsModal() {
		const { selectedSMS } = this.state;

		if (!selectedSMS) {
			return null;
		} else {
			return (
				<Modal
					show={ true }
					onHide={() => this.setState({ selectedSMS: null })}
				>
					<Modal.Header closeButton>
						<Modal.Title>SMS Details</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p><strong>Invoice Number:</strong> {selectedSMS.invoice_number === 0 ? 'N/A' : selectedSMS.invoice_number}</p>
						<p><strong>Customer phone:</strong> {formatPhoneReadable(selectedSMS.to_from)}</p>
						<p><strong>Time:</strong> {selectedSMS.when_sent}</p>
						<p><strong>Type:</strong> {parseCallType(selectedSMS.type)}</p>
						<p><strong>Message:</strong> {selectedSMS.body}</p>
					</Modal.Body>
				</Modal>
			);
		}

	}
	render() {

		return (
			<Fragment>

				{ this.renderSMSDetailsModal() }

				<Card>
					<Card.Title className="p-4 border-bottom">SMS Log</Card.Title>
					<Card.Body>
						<Alert variant='info'>
							Click on an item for more details
						</Alert>
						{(!verifyObjectLength(this.state.roboLog) && !this.state.loading) && 'No SMS logged.'}
						{this.state.loading && <CallLogLoading />}
						{!this.state.loading &&
							<Table size="sm" className="mt-3 myRwSmallTable" hover>
								<thead>
									<tr>
										<th>Invoice</th>
										<th>Customer phone</th>
										<th>Time</th>
									</tr>
								</thead>
								<tbody>
									{this.renderLog()}
								</tbody>
							</Table>
						}
						{this.state.pagination}
					</Card.Body>

				</Card>
			</Fragment>
		);
	}
}

export default RoboSMSLog;

