import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { parsePartsArrayToString, parseJobStatusFromCode } from '../../helpers/sdMobile';
import { rawApiRequest } from 'utilities/api';

@inject('authStore')
@observer class PVR extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			pvrSuccess: false,
			ticketSuccess: false,
		};
	}

	reflag = async (item_id, ticket) => {
		this.setState({
			loading: true
		});

		const response = await rawApiRequest({ endpoint: 'v3/flagPvrForDownload', parameters: { item_id, is_ticket: ticket } });

		if (response.ok) {
			if (ticket) {
				this.setState({
					loading: false,
					ticketSuccess: true,
				});
			} else {
				this.setState({
					loading: false,
					pvrSuccess: true,
				});
			}
		}
	}

	render() {
		const { currentPVR, incrementer } = this.props;
		return (
			<Card className="mb-4">
				<Card.Body>
					<h6><u>Post Visit Report #{incrementer}</u></h6>

					<Row>
						<Col><small className="boldMyRW">Invoice #:</small> {currentPVR.invoice_number}</Col>
						<Col><small className="boldMyRW">Job Title:</small> {currentPVR.job_title}</Col>
						<Col><small className="boldMyRW">Technician:</small> {currentPVR.tech_code}</Col>
					</Row>
					<Row>
						<Col><small className="boldMyRW">Date:</small> {currentPVR.when_visited}</Col>
						<Col><small className="boldMyRW">Work Description:</small> {currentPVR.work_description}</Col>
						<Col><small className="boldMyRW">Status:</small> {parseJobStatusFromCode(currentPVR.status)}</Col>
					</Row>
					<span className="PVRItemHeader">Machine Information</span>
					<div className="PVRItemHeaderSeparator"></div>
					<Row>
						<Col><small className="boldMyRW">Dealer:</small> {currentPVR.machine.dealer}</Col>
						<Col><small className="boldMyRW">Purchase Date:</small> {currentPVR.machine.purchase_date}</Col>
						<Col><small className="boldMyRW">Make:</small> {currentPVR.machine.make}</Col>
					</Row>
					<Row>
						<Col><small className="boldMyRW">Model:</small> {currentPVR.machine.model}</Col>
						<Col><small className="boldMyRW">Type:</small> {currentPVR.machine.type}</Col>
						<Col><small className="boldMyRW">Serial:</small> {currentPVR.machine.serial}</Col>
					</Row>
					<span className="PVRItemHeader">Parts-Pick Parts</span>
					<div className="PVRItemHeaderSeparator"></div>
					<Row>
						<Col><small className="boldMyRW">Parts Used:</small> {currentPVR.parts_pick_used.length}</Col>
						<Col><small className="boldMyRW">Part #s:</small> {parsePartsArrayToString(currentPVR.parts_pick_used)}</Col>
						<Col></Col>
					</Row>
					<span className="PVRItemHeader">Special Order Parts</span>
					<div className="PVRItemHeaderSeparator"></div>
					<Row>
						<Col><small className="boldMyRW">Parts Used:</small> {currentPVR.special_order_parts.length}</Col>
						<Col><small className="boldMyRW">Part #s:</small> {parsePartsArrayToString(currentPVR.special_order_parts)}</Col>
						<Col></Col>
					</Row>
					<span className="PVRItemHeader">Parts Directly Managed</span>
					<div className="PVRItemHeaderSeparator"></div>
					<Row>
						<Col><small className="boldMyRW">Parts Used:</small> {currentPVR.parts_directly_managed.length}</Col>
						<Col><small className="boldMyRW">Part #s:</small> {parsePartsArrayToString(currentPVR.parts_directly_managed)}</Col>
						<Col><Button className="mt-1" disabled={this.state.pvrSuccess} onClick={() => this.reflag(currentPVR.id)}>{this.state.pvrSuccess ? 'Success' : 'Flag PVR for re-download'}</Button></Col>
						<Col><Button className="mt-1" disabled={this.state.ticketSuccess} onClick={() => this.reflag(currentPVR.schedule_item_id, true)}>{this.state.ticketSuccess ? 'Success' : 'Flag Ticket Image for re-download'}</Button></Col>
					</Row>
				</Card.Body>

			</Card>
		);
	}
}

export default PVR;
