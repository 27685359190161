import React, { Component } from 'react';
import PaginationComponent from '../../components/Pagination';
import { inject, observer } from 'mobx-react';
import { Table, Card } from 'react-bootstrap';
import CallLogLoading from '../../components/loaders/CallLogLoading';
import { parseCallType, parseCallStatus } from '../../helpers/robocalling';
import verifyObjectLength from '../../helpers/verifyObject';
import { formatPhoneReadable } from '../../helpers/formatting';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class RoboCallingLog extends Component {
	state = {
		loading: true,
	}
	componentDidMount() {
		this.initializeRoboLog();
	}
	initializeRoboLog = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/getcalllogpage', parameters: { per_page: 25, page: 0, sort_by: 'WhenCalled', sort_direction: 'DESC' }, token });
		this.setState({ roboLog: data.data, numPages: data.pages, loading: false });
		this.setState({ pagination: this.renderPagination(this.state.numPages) });
	}
	getRoboLog = async (pageNum) => {
		this.setState({ loading: true });
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/getcalllogpage', parameters: { per_page: 25, page: pageNum, sort_by: 'WhenCalled', sort_direction: 'DESC' }, token });
		this.setState({ roboLog: data.data, loading: false });
	}
	renderPagination(numPages) {
		return (<PaginationComponent key="paginationComponentKey" numPages={numPages} currentPage="1" getRoboLog={(pageNum) => this.getRoboLog(pageNum)} />);
	}
	renderLog() {
		const { roboLog } = this.state;
		return ((roboLog || []).map((roboLogItem) => {
			let callStatus = parseCallStatus(roboLogItem.twilio_response);
			let callType = parseCallType(roboLogItem.call_type);
			let callStatusBadge = '';
			switch (callStatus) {
			case 'Queued': callStatusBadge = 'badge-info';
				break;
			case 'Completed': callStatusBadge = 'badge-success';
				break;
			default: callStatusBadge = 'badge-secondary';
			}
			let callTypeBadge = '';
			switch (callType) {
			case 'Incoming': callTypeBadge = 'badge-success';
				break;
			case 'Tech-Initiated': callTypeBadge = 'badge-purple';
				break;
			case 'Robo-Initiated': callTypeBadge = 'badge-primary';
				break;
			default: callTypeBadge = 'badge-secondary';
			}
			return (
				<tr key={roboLogItem.call_id}>
					<td key={roboLogItem.invoice_number + 'TechCode'}>{roboLogItem.invoice_number}</td>
					<td key={roboLogItem.number_called + 'TechName'}>{formatPhoneReadable(roboLogItem.number_called)}</td>
					<td key={roboLogItem.when_called + 'Platform'}>{roboLogItem.when_called}</td>
					<td key={roboLogItem.twilio_response + 'Version'}><div className={`badge ${callStatusBadge}`}>{callStatus}</div></td>
					<td key={roboLogItem.call_type + 'LastConnection'}><div className={`badge ${callTypeBadge}`}>{callType}</div></td>
				</tr>
			);
		}));
	}
	render() {
		return (
			<Card>
				<Card.Title className="p-4 border-bottom">Call Log</Card.Title>
				<Card.Body>
					{(!verifyObjectLength(this.state.roboLog) && !this.state.loading) && 'No calls logged.'}
					{this.state.loading && <CallLogLoading />}
					{!this.state.loading &&
						<Table size="sm" className="mt-3 myRwSmallTable">
							<thead>
								<tr>
									<th>Invoice</th>
									<th>From/To</th>
									<th>Time</th>
									<th>Call Status</th>
									<th>Call Type</th>
								</tr>
							</thead>
							<tbody>
								{this.renderLog()}
							</tbody>
						</Table>
					}
					{this.state.pagination}
				</Card.Body>

			</Card>
		);
	}
}

export default RoboCallingLog;

