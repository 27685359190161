import React, { Component } from 'react';
import InlineLoading from '../../components/loaders/InlineLoading';
import SearchCalls from '../../components/robocalling/CallSearch';
import { inject, observer } from 'mobx-react';
import CallRecord from '../../components/robocalling/CallRecord';
import { Alert, Card } from 'react-bootstrap';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class RoboCallingLookup extends Component {
	state = {
		loading: false,
	}
	searchForCall = async (props) => {
		this.setState({ loading: true });
		let { searchOn, textBoxValue } = props;
		let { token } = this.props.authStore.values;
		if (searchOn === 'invoice') {
			let data = await apiRequest({ endpoint: 'v3/myrossware/searchRobocalls', parameters: { invoice_number: textBoxValue }, token });
			this.setState({ calls: data.data });
		} else {
			let data = await apiRequest({ endpoint: 'v3/myrossware/searchRobocalls', parameters: { phone: textBoxValue }, token });
			this.setState({ calls: data.data });
		}
		this.setState({ loading: false });
	}
	renderCalls = () => {
		const { calls } = this.state;
		if (calls && calls !== null && calls.length !== 0) {
			return ((calls || []).map((call) => {
				const propObject = { callID: call.CallID, releventPhone: call.NumberCalled, invoice: call.InvNmbr, startTime: call.WhenCalled, duration: call.duration, status: call.TwiResponse };
				return (
					<CallRecord key={call} props={propObject} />
				);
			}));
		} else if (calls && calls !== null && calls.length === 0) {
			return <Alert variant="warning">No results found for that search, please try another.</Alert>;
		}
	}
	render() {
		if (!this.state.loading) {
			return (
				<Card>
					<Card.Title className="p-4 border-bottom">Call Lookup</Card.Title>
					<Card.Body>
						<SearchCalls searchForCall={this.searchForCall} />
						{this.renderCalls()}
					</Card.Body>
				</Card>
			);
		} else {
			return (<Card><Card.Body><InlineLoading /></Card.Body></Card>);
		}
	}
}

export default RoboCallingLookup;
