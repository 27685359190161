export function parseCallType(callType) {
	if (!callType) {
		return 'Unknown';
	}
	const possibleCallTypes = { R: 'Robo-Initiated', T: 'Tech-Initiated', I: 'Incoming', P: 'Post-Completion', S: 'Robo-Initiated' };
	if (possibleCallTypes[callType]) {
		return possibleCallTypes[callType];
	} else if (callType !== '') {
		return callType;
	} else {
		return 'Unknown';
	}
}
export function parseCallStatus(callStatus) {
	if (callStatus) {
		return callStatus.substring(0,1).toUpperCase() + callStatus.substring(1);
	} else {
		return 'Not reported';
	}
}
export function translateSecondsToFullDuration(seconds) {
	var minutes = 0;
	var hours = 0;
	while (seconds > 59) {
		minutes++; seconds = seconds - 60; 
	}
	while (minutes > 59) {
		hours++; minutes = minutes - 60; 
	}
	function fillZeroes(val) {
		if (val === 0) {
			return '00';
		} else if (val > 9) {
			return val;
		} else {
			return '0' + val;
		} 
	}
	return fillZeroes(hours) + ':' + fillZeroes(minutes) + ':' + fillZeroes(seconds);
}
