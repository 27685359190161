import React, { Component, Fragment } from 'react';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import Logo from '../assets/rossware-logo-simple.svg';
import { Link } from 'react-router-dom';

@inject('authStore')
@observer class Login extends Component {
	state = {
		urlParams: {},
	}
	constructor() {
		super();
	}

	onKeyPress = (event) => {
		if (event.which === 13) {
			this.props.authStore.login();
		}
	}

	handleChange = (event) => {
		const authStoreValues = this.props.authStore.nonObservableValues;
		authStoreValues[event.target.name] = event.target.value;
	}

	componentDidMount() {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const urlParams = Object.fromEntries(urlSearchParams.entries());
		this.setState({ urlParams });
	}

	render() {
		const preventLogin = false;
		const { loadingLogin, loginFailure } = this.props.authStore.values;
		const { urlParams } = this.state;
		const emailAddressFromSession = sessionStorage.getItem('email_address');
		if (emailAddressFromSession) {
			this.props.authStore.values.email_address = emailAddressFromSession;
		}


		return (
			<div className="login-wrapper">
				<div className="p-3 border-bottom d-flex justify-content-between">
					<img src={ Logo } width="130px" className="d-inline" />
					<Button href="https://rossware.com">Return to website</Button>
				</div>

				<div className="h-100 d-flex justify-content-center flex-column login-container py-5">
					<h2 className="text-center h1">Welcome back.</h2>
					<Form.Group>
						<Form.Label><small>Email Address</small></Form.Label>
						<Form.Control name="email_address" type="text" defaultValue={emailAddressFromSession || ''} onKeyPress={this.onKeyPress} onChange={this.handleChange} placeholder="john@businessemail.com" />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Password</small></Form.Label>
						<Form.Control name="password" type="password" placeholder="" onKeyPress={this.onKeyPress} onChange={this.handleChange} />
					</Form.Group>

					{urlParams.email_verified &&
						<Alert className="my-2" variant="success">
							Your email was verified! Please login.
						</Alert>
					}

					{loginFailure &&
						<Alert className="my-2" variant="danger">
							Login failed, your credentials were rejected.
						</Alert>
					}

					{!loadingLogin &&
						<Fragment>
							<Button variant="primary" type="submit" disabled={preventLogin} className="btn btn-lg btn-block" onClick={() => this.props.authStore.login()}>
								Log in
							</Button>
							<div className="text-center py-3 login-alert">Haven&apos;t created your dashboard user yet? <Link to="/signup">Click here</Link> to sign up.</div>
							<div className="text-center"><a href="/reset">Forgot your password?</a></div>
						</Fragment>
					}

					{loadingLogin &&
						<div className="loginLoadingSpinner">
							<Spinner animation="border" variant="primary" />
						</div>
					}
				</div>
			</div>
		);
	}
}

export default Login;

