import React, { Component } from 'react';
import SurveyImage from '../../assets/survey.png';
class SurveyMain extends Component {
	render() {
		return (
			<div className="card">
				<div className="card-body">
					<h3>How It Works</h3>
					<div>
						<p>Rossware Surveys are designed to be quick and simple. We only ask 4 questions, which allows us to rate your operation on the following factors:</p>
						<ul>
							<li>Calltaker Performance</li>
							<li>Technician Performance</li>
							<li>Value of Services Provided</li>
							<li>Would the customer recommend you again</li>
						</ul>
						<p>We use the Net-Promoter-Score (NPS) method for determining your score. This is not an average of the surveys, instead, it accounts for those who are thrilled with your services, and those who are not.</p>
						<ul>
							<li>Any score that is rated at <b>Less Than 6</b> is considered a <b>Detractor.</b></li>
							<li>Any score that is rated at <b>9 Or Better</b> is considered a <b>Promoter.</b></li>
							<li>A score of <b>7 or 8</b> is considered <b>Neutral</b> and has no affect on your NPS.</li>
						</ul>
						<p>Once you have these numbers, the actual formula is quite simple:</p>
						<ul>
							<li>Take the total number of <b>Promoters</b> and divide by <b>Total Number Of Completed Surveys</b> (let&apos;s call this variable P)</li>
							<li>Take the total number of <b>Detractors</b> and divide by <b>Total Number Of Completed Surveys</b> (let&apos;s call this variable D)</li>
						</ul>
						<p>From here, we can do this: (P-D)100 (rounded down.)</p>

						<p>As a real example, let&apos;s suppose you have the following values P(1529), D(201), T(1730)</p>

						<p>Our formula would be: <code>((1529/1730) - (201 / 1730))100</code>, for a NPS of: 76</p>

						<p>Here&apos;s what our survey looks like:</p>
						<div className="d-flex justify-content-center">
							<img className="border rounded" src={SurveyImage}></img>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SurveyMain;