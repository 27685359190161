import React, { useState } from 'react';
import { validateEverything } from 'helpers/validation';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import apiRequest from 'utilities/api';
import Logo from '../assets/rossware-logo-simple.svg';
import { useEffect } from 'react';
import InlineLoading from 'components/loaders/InlineLoading';

export default function ForwardedReset() {
	const [inputData, setInputData] = useState({
		password: {
			proper_name: 'New password',
			value: null,
			required: true,
			valid: false,
			type: 'text',
		},
		confirm_password: {
			proper_name: 'Confirm password',
			value: null,
			required: true,
			valid: false,
			type: 'text',
		},
	});

	const [resetRecord, setResetRecord] = useState(null);
	const [invalidMessage, setInvalidMessage] = useState(null);
	const [failureMessage, setFailureMessage] = useState(null);
	const [valid, setValid] = useState(false);
	const [success, setSuccess] = useState(false);
	const [resetActionLoading, setResetActionLoading] = useState(false);

	const [loading, setLoading] = useState(true);
	const [fetchResetFailure, setFetchResetFailure] = useState(null);

	useEffect(() => {
		getResetRecord();
	}, []);

	async function getResetRecord() {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		const matchingResetRecord = await apiRequest({ endpoint: 'v3/backend/getDashboardUserPasswordReset', parameters: { uid: params?.uid } });

		if (matchingResetRecord.status === 200) {
			setResetRecord(matchingResetRecord?.resetRecord);
		} else {
			setFetchResetFailure(matchingResetRecord.message);
		}
		setLoading(false);
	}

	function inputChange(event) {
		inputData[event.target.name].value = event.target.value;
		setInputData(inputData);
		const validationResult = validateEverything(inputData);
		setValid(validationResult.allValid);
		setInvalidMessage(validationResult.message);
	}
	async function submit() {
		console.log(resetRecord);
		setResetActionLoading(true);
		const { email_address, unique_string } = resetRecord;
		const password = inputData.password?.value;
		const passResetResult = await apiRequest({ endpoint: 'v3/backend/setNewDashboardUserPassword', method: 'POST', body: { email_address, password, uid: unique_string } });
		if (passResetResult?.data?.affectedRows === 1) {
			setSuccess(true);
		} else {
			setFailureMessage('Failed to reset password, please try again or contact support.');
		}
		setLoading(false);
	}
	if (fetchResetFailure) {
		return (
			<div className="login-wrapper">
				<div className="p-3 border-bottom d-flex justify-content-between">
					<img src={ Logo } width="130px" className="d-inline" />
					<Button href="https://rossware.com">Return to website</Button>
				</div>
				{ fetchResetFailure }
			</div>

		);
	}
	if (loading) {
		return (
			<div className="login-wrapper">
				<div className="p-3 border-bottom d-flex justify-content-between">
					<img src={ Logo } width="130px" className="d-inline" />
					<Button href="https://rossware.com">Return to website</Button>
				</div>
				<InlineLoading />
			</div>

		);
	}
	if (success) {
		return (
			<div className="login-wrapper">
				<div className="p-3 border-bottom d-flex justify-content-between">
					<img src={ Logo } width="130px" className="d-inline" />
					<Button href="https://rossware.com">Return to website</Button>
				</div>
				<p>Your password was successfully reset, <a href="https://dashboard.rossware.com">click to login</a></p>
			</div>
		);
	}
	return (
		<div className="login-wrapper">
			<div className="p-3 border-bottom d-flex justify-content-between">
				<img src={ Logo } width="130px" className="d-inline" />
				<Button href="https://rossware.com">Return to website</Button>
			</div>

			<div className="justify-content-center flex-column py-5 signup-container">
				<h1>Reset your password</h1>
				<div className="signup-form">
					<Form.Group>
						<Form.Label><small>New password</small></Form.Label>
						<Form.Control name="password" type="password" onChange={ (e) => { inputChange(e); } } />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Confirm password</small></Form.Label>
						<Form.Control name="confirm_password" type="password" onChange={ (e) => { inputChange(e); } } />
					</Form.Group>
				</div>
				<div>{ invalidMessage }</div>
				{ !valid && <div>Ensure password meets complexity requirements: Between 8 and 16 characters, at least one lower-case letter, at least one upper-case letter, at least one number, at least one special character.</div>}
				{ failureMessage && <Alert variant="danger">{ failureMessage }</Alert> }
				{!resetActionLoading &&
					<Button variant="primary" type="submit" disabled={ !valid } className="btn btn-lg btn-block" onClick={() => submit()}>
						Reset password
					</Button>
				}
				{resetActionLoading &&
					<div className="loginLoadingSpinner">
						<Spinner animation="border" variant="primary" />
					</div>
				}
			</div>
		</div>
	);
}
