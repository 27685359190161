import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatPhoneReadable } from '../../helpers/formatting';
import { parseCallStatus, translateSecondsToFullDuration } from '../../helpers/robocalling';

export class CallRecord extends Component {
	render() {
		const { callID, releventPhone, invoice, startTime, duration, status } = this.props.props;
		return (
			<div className="myRWCallRecord">
				<div className="myRWCallRecordHeader">
					<span><b>Call ID:</b> <span className="myRWCallRecordCallID">{callID}</span></span>
					<div className="border-bottom" />
				</div>
				<Row>
					<Col><small><b>Phone #:</b> {formatPhoneReadable(releventPhone)}</small></Col>
					<Col><small><b>Referenced Invoice:</b> {invoice || 'Unknown'}</small></Col>
					<Col><small><b>Time Started:</b> {startTime || 'Unknown'}</small></Col>
				</Row>
				<Row>
					<Col><small><b>Duration:</b> {translateSecondsToFullDuration(duration)}</small></Col>
					<Col><small><b>Status:</b> {parseCallStatus(status)}</small></Col>
					<Col></Col>
				</Row>
			</div>
		);
	}
}

export default CallRecord;

