import React, { Component } from 'react';
import { Card, Table, Pagination, InputGroup, Button, FormControl, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import InlineLoading from 'components/loaders/InlineLoading';
import EmptyImage from '../../assets/open-box.svg';

@inject('surveyStore')
@observer class Lookup extends Component {
	constructor(props) {
		super(props);
		var page = 0;
		let tech = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
		if (tech === 'lookup') {
			tech = '';
		}
		this.props.surveyStore.getTechs();
		this.props.surveyStore.getPage(page, tech);
		this.state = {
			surveys: this.props.surveyStore.values.surveyPage,
			page: 0,
			searchOn: '',
			tech: tech
		};
	}


	handlePageChange(next) {
		if (next) {
			this.setState({
				page: this.state.page + 1
			}, () => {
				this.props.surveyStore.getPage(this.state.page, this.state.tech);
			});
		} else {
			if (this.state.page !== 0) {
				this.setState({
					page: this.state.page - 1
				}, () => {
					this.props.surveyStore.getPage(this.state.page, this.state.tech);
				});
			}
		}
	}

	handleTechFilter = (event) => {
		var tech = event.target.value;
		if (tech === 'all') {
			tech = '';
		}
		this.setState({
			tech: tech
		},() => {
			this.props.surveyStore.getPage('0', this.state.tech);
		});
	}

	handleSearchOnChange = (event) => {
		this.setState({
			searchOn: event.target.value
		});
	}

	handleSearch = () => {
		this.props.surveyStore.search(this.state.searchOn);
	}

	renderStatBars(q1, q2, q3, q4) {
		return (
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 300 }}
				overlay={<Tooltip>{`Calltaker: ${q1}, Technician: ${q2}, Value: ${q3}, Recommend: ${q4}`}</Tooltip>}
			>
				<div className="d-flex align-items-end stat-hover" style={{ height: '30px' }}>
					<small className={`d-inline-block px-2 text-white ${this.scoreRating(q1)}`}></small>
					<small className={`d-inline-block px-2 text-white ${this.scoreRating(q2)}`}></small>
					<small className={`d-inline-block px-2 text-white ${this.scoreRating(q3)}`}></small>
					<small className={`d-inline-block px-2 text-white ${this.scoreRating(q4)}`}></small>
				</div>
			</OverlayTrigger>

		);
	}

	scoreRating(score) {
		const amazing = 'bg-success h-100 stat-border';
		const good = 'bg-primary h-75 stat-border';
		const okay = 'bg-warning h-50 stat-border';
		const bad = 'bg-danger h-25 stat-border';

		if (score >= 9) {
			return amazing;
		} else if (score < 9 && score > 6) {
			return good;
		} else if (score > 3 && score <= 6) {
			return okay;
		} else {
			return bad;
		}
	}

	render() {
		if (this.state.loading && this.props.surveyStore.values.loading) {
			return (<InlineLoading />);
		}
		return (
			<Card>
				<Card.Title className="p-4 border-bottom">Itemization/Lookup</Card.Title>
				<Card.Body>
					<Row>
						<Col lg>
							<InputGroup className="mb-4">
								<FormControl placeholder="Enter and invoice number to search" onChange={this.handleSearchOnChange}></FormControl>
								<InputGroup.Append>
									<Button onClick={this.handleSearch}>Search</Button>
								</InputGroup.Append>
							</InputGroup>
						</Col>
						<Col lg>
							<InputGroup>
								<select className="custom-select" onChange={this.handleTechFilter} defaultValue={this.state.tech}>
									<option disabled value="">Filter by technician</option>
									<option value="all">All techs</option>
									{!_.isEmpty(this.props.surveyStore.values.techList) && this.props.surveyStore.values.techList.map(tech => {
										return (
											<option key={tech.TechCode} value={tech.TechCode}>{tech.TechCode} - {tech.TechName}</option>
										);
									})};
								</select>
							</InputGroup>
						</Col>
					</Row>


					<Pagination>
						<Pagination.Prev disabled={this.state.page === 0} onClick={() => this.handlePageChange(false)} />
						<Pagination.Next disabled={_.isEmpty(this.props.surveyStore.values.surveyPage) || this.props.surveyStore.values.surveyPage.length < 50} onClick={() => this.handlePageChange(true)} />
					</Pagination>
					<Table className="border rounded">
						<thead>
							<tr>
								<th>Scores <small className="d-block">Hover for score totals</small></th>
								<th>Tech</th>
								<th>Call Taker</th>
								<th>Invoice Number</th>
								<th>Comments</th>
								<th>Date Completed</th>
							</tr>
						</thead>
						<tbody>
							{_.orderBy(this.props.surveyStore.values.surveyPage, 'CmpltDate', 'desc').map(survey => {
								try {
									var customerComments = decodeURIComponent(_.get(survey, 'CstmrCmnts'));
								} catch (error) {
									customerComments = '';
									console.log('Survey information malformed: ' + error);
								}
								return (
									<tr key={survey.ItemID}>
										<td>{this.renderStatBars(_.get(survey, 'q1'), _.get(survey, 'q2'), _.get(survey, 'q3'), _.get(survey, 'q4'))}</td>
										<td>{_.get(survey, 'TechCode')}</td>
										<td>{_.get(survey, 'CallTaker')}</td>
										<td>{_.get(survey, 'InvNmbr')}</td>
										<td>{customerComments}</td>
										<td>{moment(_.get(survey, 'CmpltDate')).format('MM-DD-YY')}</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
					{_.isEmpty(this.props.surveyStore.values.surveyPage) && <div className="mb-4 text-center d-flex justify-content-center align-items-center flex-column">
						<img src={EmptyImage} width="64px" className="mb-3" />
						<b>This table is as empty as it gets!</b>
					</div>}
					<Pagination>
						<Pagination.Prev disabled={this.state.page === 0} onClick={() => this.handlePageChange(false)} />
						<Pagination.Next disabled={_.isEmpty(this.props.surveyStore.values.surveyPage) || this.props.surveyStore.values.surveyPage.length < 50} onClick={() => this.handlePageChange(true)} />
					</Pagination>
				</Card.Body>
			</Card>
		);
	}
}

export default Lookup;