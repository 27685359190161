import React, { Component } from 'react';
import { Table, Badge, Card } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

@inject('authStore')
@observer class SDCOOverview extends Component {
	renderBadge = (value) => {
		if (value === 1) {
			return (
				<Badge variant="success">Yes</Badge>
			);
		} else {
			return (
				<Badge variant="warning">No</Badge>
			);
		}
	}
	render() {
		const values = this.props.authStore.values;
		console.log(values);
		return (
			<Card>
				<Card.Title className="p-4 border-bottom">Overview</Card.Title>
				<Card.Body>
					<Table striped size="sm" className="mt-3 myRwSmallTable">
						<thead>
							<tr>
								<th>Setting</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Business Name</td>
								<td>{values.name}</td>
							</tr>
							<tr>
								<td>* Contact Email</td>
								<td>{values.email}</td>
							</tr>
							<tr>
								<td>* Contact Phone</td>
								<td>{values.show_on_caller_id}</td>
							</tr>
							<tr>
								<td>* Require Model/Serial #?</td>
								<td>{this.renderBadge(values.require_model_serial)}</td>
							</tr>
							<tr>
								<td>* Open Directly to Zip Page?</td>
								<td>{this.renderBadge(values.direct_to_zip_page)}</td>
							</tr>
							<tr>
								<td>* Offer Time Frames?</td>
								<td>{this.renderBadge(values.web_configuration.offer_time_frames)}</td>
							</tr>
							<tr>
								<td>* Show Job History?</td>
								<td>{this.renderBadge(values.web_configuration.show_history)}</td>
							</tr>
							<tr>
								<td>* Show Tech Status?</td>
								<td>{this.renderBadge(values.tech_status)}</td>
							</tr>
							<tr>
								<td>Current Version Being Used</td>
								<td>{values.version_used ? values.version_used : 'Unknown'}</td>
							</tr>
						</tbody>
					</Table>
					<small>* Items denoted with an asterisk can be changed from within the SD-CyberLink Utility.</small>
				</Card.Body>
			</Card>
		);
	}
}

export default SDCOOverview;