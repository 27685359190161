import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import apiRequest, { rawApiRequest } from 'utilities/api';
import Loading from 'components/loaders/Loading';
import _ from 'lodash';

const states = [
	{ abbreviation: 'AL', name: 'Alabama' },
	{ abbreviation: 'AK', name: 'Alaska' },
	{ abbreviation: 'AZ', name: 'Arizona' },
	{ abbreviation: 'AR', name: 'Arkansas' },
	{ abbreviation: 'CA', name: 'California' },
	{ abbreviation: 'CO', name: 'Colorado' },
	{ abbreviation: 'CT', name: 'Connecticut' },
	{ abbreviation: 'DE', name: 'Delaware' },
	{ abbreviation: 'DC', name: 'District Of Columbia' },
	{ abbreviation: 'FL', name: 'Florida' },
	{ abbreviation: 'GA', name: 'Georgia' },
	{ abbreviation: 'HI', name: 'Hawaii' },
	{ abbreviation: 'ID', name: 'Idaho' },
	{ abbreviation: 'IL', name: 'Illinois' },
	{ abbreviation: 'IN', name: 'Indiana' },
	{ abbreviation: 'IA', name: 'Iowa' },
	{ abbreviation: 'KS', name: 'Kansas' },
	{ abbreviation: 'KY', name: 'Kentucky' },
	{ abbreviation: 'LA', name: 'Louisiana' },
	{ abbreviation: 'ME', name: 'Maine' },
	{ abbreviation: 'MD', name: 'Maryland' },
	{ abbreviation: 'MA', name: 'Massachusetts' },
	{ abbreviation: 'MI', name: 'Michigan' },
	{ abbreviation: 'MN', name: 'Minnesota' },
	{ abbreviation: 'MS', name: 'Mississippi' },
	{ abbreviation: 'MO', name: 'Missouri' },
	{ abbreviation: 'MT', name: 'Montana' },
	{ abbreviation: 'NE', name: 'Nebraska' },
	{ abbreviation: 'NV', name: 'Nevada' },
	{ abbreviation: 'NH', name: 'New Hampshire' },
	{ abbreviation: 'NJ', name: 'New Jersey' },
	{ abbreviation: 'NM', name: 'New Mexico' },
	{ abbreviation: 'NY', name: 'New York' },
	{ abbreviation: 'NC', name: 'North Carolina' },
	{ abbreviation: 'ND', name: 'North Dakota' },
	{ abbreviation: 'OH', name: 'Ohio' },
	{ abbreviation: 'OK', name: 'Oklahoma' },
	{ abbreviation: 'OR', name: 'Oregon' },
	{ abbreviation: 'PA', name: 'Pennsylvania' },
	{ abbreviation: 'RI', name: 'Rhode Island' },
	{ abbreviation: 'SC', name: 'South Carolina' },
	{ abbreviation: 'SD', name: 'South Dakota' },
	{ abbreviation: 'TN', name: 'Tennessee' },
	{ abbreviation: 'TX', name: 'Texas' },
	{ abbreviation: 'UT', name: 'Utah' },
	{ abbreviation: 'VT', name: 'Vermont' },
	{ abbreviation: 'VA', name: 'Virginia' },
	{ abbreviation: 'WA', name: 'Washington' },
	{ abbreviation: 'WV', name: 'West Virginia' },
	{ abbreviation: 'WI', name: 'Wisconsin' },
	{ abbreviation: 'WY', name: 'Wyoming' },
];

const provinces = [
	{ abbreviation: 'AB', name: 'Alberta' },
	{ abbreviation: 'BC', name: 'British Columbia' },
	{ abbreviation: 'MB', name: 'Manitoba' },
	{ abbreviation: 'NB', name: 'New Brunswick' },
	{ abbreviation: 'NL', name: 'Newfoundland and Labrador' },
	{ abbreviation: 'NS', name: 'Nova Scotia' },
	{ abbreviation: 'ON', name: 'Ontario' },
	{ abbreviation: 'PE', name: 'Prince Edward Island' },
	{ abbreviation: 'QC', name: 'Quebec' },
	{ abbreviation: 'SK', name: 'Saskatchewan' },
	{ abbreviation: 'NT', name: 'Northwest Territories' },
	{ abbreviation: 'NU', name: 'Nunavut' },
	{ abbreviation: 'YT', name: 'Yukon' },
];

function prepareFormValuesForSubmission(formValues) {
	const preparedFormValues = {};

	_.forEach(formValues, (value, key) => {
		if (key === 'business_contact_phone' || key === 'business_postal_code') {
			preparedFormValues[key] = value.value.replace(/\D/g,'');
		} else {
			preparedFormValues[key] = value.value;
		}
	});

	return preparedFormValues;
}

const TollFreeVerificationStatusBadge = (props) => {
	const { contentsAndStyle } = props;
	return (
		<div style={{ margin: '5px 0' }}>
			<div style={{ fontWeight: 'bold', display: 'inline', padding: '5px', marginRight: '5px', borderRadius: '5px', backgroundColor: contentsAndStyle?.backgroundColor, color: contentsAndStyle?.foregroundColor }}>
				{ contentsAndStyle?.text }
			</div>
			{ contentsAndStyle?.explanation }
		</div>
	);
};

@inject('authStore')
@observer class RoboCallingOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			registration: null,
			error: null,
			formValues: {
				business_name: { value: '', valid: false },
				business_street_address: { value: '', valid: false },
				business_street_address2: { value: '', valid: true },
				business_city: { value: '', valid: false },
				business_state_province_region: { value: '', valid: false },
				business_postal_code: { value: '', valid: false },
				business_website: { value: '', valid: false },
				business_contact_first_name: { value: '', valid: false },
				business_contact_last_name: { value: '', valid: false },
				business_contact_email: { value: '', valid: false },
				business_contact_phone: { value: '', valid: false },
			}
		};
	}


	renderValidityLabel(name) {
		const valid = this.state.formValues[name]?.valid;
		return valid ? <span style={{ fontWeight: 'bold', color: 'green' }}>&#10003;</span> : <span style={{ fontWeight: 'bold', color: 'red' }}>&#9747;</span>;
	}

	async componentDidMount() {
		const { token } = this.props.authStore.values;
		const existingRegistration = await apiRequest({ endpoint: 'v3/myrossware/getA2P10DLCCampaignInDb', token });


		this.setState({ loading: false, registration: existingRegistration?.status === 200 ? existingRegistration : null });
	}

	onInputChange(name, value, validation) {
		const { formValues: formValuesToModify } = this.state;

		if (!formValuesToModify[name]) { formValuesToModify[name] = {}; }

		formValuesToModify[name].value = value;

		if (validation) {
			formValuesToModify[name].valid = validation(value);
		} else {
			formValuesToModify[name].valid = true;
		}

		this.setState({ formValues: formValuesToModify });
	}

	renderRobocallingSignup() {
		function validateText(value, length) {
			if (value.length > length) { return true; }
		}
		function validateEmail(value) {
			return String(value)
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);
		}
		const isCanadian = this.props.authStore.nonObservableValues.business?.web_configuration.is_canadian;
		const { error } = this.state;

		return (
			<Form>
				<h2>Signup</h2>
				<div style={{ marginTop: '10px', marginBottom: '10px' }}>
					Submitting this form will sign you up for robocalling and submit a toll-free verification request. <br /><br />
					When you enter your information and sign up for robocalling, we will automatically purchase you a toll-free phone number. Then we will submit for a toll-free verification process which, using the business info you provided, will prove your business as legitimate so you may send unfiltered communications.<br /><br />
					Note: The toll-free verification process can take up to a few weeks before it is approved. Your SMS messages may be partly or completely filtered during this time.
				</div>
				<Form.Group className="mb-2">
					<Form.Label>Legal Business Name </Form.Label> {this.renderValidityLabel('business_name')}
					<Form.Control maxLength="100" name="business_name" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => validateText(value, 5)); }}/>
					<Form.Text className="text-muted">
						Be sure to enter your legal business name as it appears on your tax documents, including any business type indication like LLC or INC.
					</Form.Text>
				</Form.Group>

				<Form.Group className="mb-2">
					<Form.Label>Street Address</Form.Label> {this.renderValidityLabel('business_street_address')}
					<Form.Control maxLength="100" name="business_street_address" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => validateText(value, 5)); }}/>
				</Form.Group>
				<Form.Group className="mb-2">
					<Form.Label>Street Address line 2</Form.Label> <span style={{ fontWeight: 'bold', color: 'green' }}>&#10003;</span>
					<Form.Control maxLength="100" name="business_street_address2" onChange={(e) => { this.onInputChange(e.target.name, e.target.value); }}/>
				</Form.Group>
				<Form.Group className="mb-2">
					<Form.Label>City</Form.Label> {this.renderValidityLabel('business_city')}
					<Form.Control maxLength="100" name="business_city" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => validateText(value, 1)); }}/>
				</Form.Group>

				<Form.Group className="mb-2">
					<Form.Label>{ isCanadian ? 'Province' : 'State' }</Form.Label> {this.renderValidityLabel('business_state_province_region')}
					<Form.Control name="business_state_province_region" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => value !== 0); }} as="select" defaultValue="0">
						<option value="0" disabled>Select</option>
						{ !isCanadian ? states.map((state, key) => {
							return <option key={ key } value={ state.abbreviation }>{ state.name }</option>;
						}) : provinces.map((province, key) => {
							return <option key={ key } value={ province.abbreviation }>{ province.name }</option>;
						}) }
					</Form.Control>
				</Form.Group>
				<Form.Group className="mb-2">
					<Form.Label>{ isCanadian ? 'Postal' : 'Zip' } Code</Form.Label> {this.renderValidityLabel('business_postal_code')}
					<Form.Control maxLength={ isCanadian ? 6 : 5 } name="business_postal_code" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => {
						if (isCanadian && value.match(/[a-z][0-9][a-z][0-9][a-z][0-9]/gi)) {
							return true;
						} else if (!isCanadian && value.match(/[0-9][0-9][0-9][0-9][0-9]/g)) {
							return true;
						}
					}); }}/>
				</Form.Group>

				<Form.Group className="mb-2">
					<Form.Label>Business Website</Form.Label> {this.renderValidityLabel('business_website')}
					<Form.Control maxLength="100" name="business_website" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => validateText(value, 3)); }}/>
				</Form.Group>

				<Form.Group className="mb-2">
					<Form.Label>Contact First Name</Form.Label> {this.renderValidityLabel('business_contact_first_name')}
					<Form.Control maxLength="100" name="business_contact_first_name" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => validateText(value, 1)); }}/>
				</Form.Group>

				<Form.Group className="mb-2">
					<Form.Label>Contact Last Name</Form.Label> {this.renderValidityLabel('business_contact_last_name')}
					<Form.Control maxLength="100" name="business_contact_last_name" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => validateText(value, 1)); }}/>
				</Form.Group>
				<Form.Group className="mb-2">
					<Form.Label>Contact Email Address</Form.Label> {this.renderValidityLabel('business_contact_email')}
					<Form.Control maxLength="100" name="business_contact_email" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => validateEmail(value)); }}/>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Contact Phone Number</Form.Label> {this.renderValidityLabel('business_contact_phone')}
					<Form.Control as={ InputMask } mask={'***-***-****'} name="business_contact_phone" onChange={(e) => { this.onInputChange(e.target.name, e.target.value, (value) => value.replace(/\D/g,'').length === 10); }}/>
				</Form.Group>
				<Button variant="primary" disabled={ !_.every(this.state.formValues, (value) => value.valid) } type="submit" onClick={(event) => {
					event.preventDefault();
					this.submitSignup();
				}}>
					Sign up
				</Button>
				{ error && <div style={{ color: 'red' }}>{ error || error.message}</div> }
			</Form>
		);

	}

	renderRobocallingStatus() {

		const twilioTollFreeStatusTranslations = {
			'TWILIO_REJECTED': {
				backgroundColor: 'red',
				foregroundColor: 'white',
				text: 'Rejected',
				explanation: 'Our backend provider, Twilio, has rejected your toll-free verification request. This is typically due to incorrect or unverifiable business information provided during registration, and will cause complete undeliverability of outbound SMS communications. Please contact us for more information.',
			},
			'TWILIO_APPROVED': {
				backgroundColor: 'green',
				foregroundColor: 'white',
				text: 'Approved',
				explanation: 'Our backend provider, Twilio, has approved your toll-free verification request! You should now be able to send unfiltered communications.',
			},
			'IN_REVIEW': {
				backgroundColor: 'gold',
				foregroundColor: 'white',
				text: 'In review',
				explanation: 'Our backend provider, Twilio, has received your toll-free verification request and is currently reviewing it. Once they verify whether all your provided information is correct, they will update the status to "Rejected" or "Approved". Beginning 01/31/2024, toll-free phone numbers in this status will be incapable of delivering SMS messages. Check back here regularly for the status.',
			},
		};

		const { registration } = this.state;

		return (
			<div>

				<b>
					Toll-free phone number
				</b>
				<div>{ registration?.A2PDLCRegistration?.toll_free_number }</div>
				<b>
					Toll-free verification status
				</b>
				<TollFreeVerificationStatusBadge contentsAndStyle={ twilioTollFreeStatusTranslations?.[registration?.A2PDLCRegistration?.toll_free_verification_status] || {
					backgroundColor: 'grey',
					foregroundColor: 'white',
					text: registration?.A2PDLCRegistration?.toll_free_verification_status,
					explanation: 'We do not have an explanation for this exact status, as received from Twilio. If more information is needed, contact us so we can investigate.',
				} } />
				<Alert variant="warning">This has an effect on the deliverability on your outbound SMS messages and needs to be in an &quot;Approved&quot; status for full deliverability. <a target="_blank" rel="noreferrer" href="https://support.twilio.com/hc/en-us/articles/5377174717595-Toll-Free-Message-Verification-for-US-Canada">Read more about it here.</a></Alert>

			</div>
		);

	}

	async submitSignup() {
		this.setState({ loading: true });
		const { token } = this.props.authStore.values;
		const signupResponse = await rawApiRequest({ endpoint: 'v3/myrossware/signupForRobocalling', parameters: prepareFormValuesForSubmission(this.state.formValues), token });

		if (signupResponse?.status === 200) {
			console.log('trying to reauth');
			await this.props.authStore.reAuthenticate(this.props.authStore.values.token, true);
			this.setState({ loading: false });
		} else {
			this.setState({ loading: false, error: signupResponse?.message || signupResponse?.data?.message || signupResponse.toString() });
		}
	}

	render() {
		return (
			<Card>
				<Card.Title className="p-4 border-bottom">Overview</Card.Title>
				<Card.Body>
					{ this.state.loading && <Loading /> }
					{ this.state.registration && this.renderRobocallingStatus() }
					{ !this.state.registration && this.renderRobocallingSignup() }
				</Card.Body>
			</Card>
		);


	}
}

export default RoboCallingOverview;

