import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import InlineLoading from 'components/loaders/InlineLoading';
import _ from 'lodash';

@inject('authStore', 'surveyStore')
@observer class CallTakerStats extends Component {
	constructor(props) {
		super(props);
		if (!this.props.surveyStore.surveys) {
			this.props.surveyStore.getSurveys().then(() => {
				this.props.surveyStore.getTechs().then(() => {
					this.setValues();
				});
				this.props.surveyStore.getCallTakers();
			});
		} else {
			this.setValues();
		}
		//We need this to only hit API if it hasn't already, above code is hitting it on every remount.
	}

	setValues() {
		// const surveyStore = this.props.surveyStore.values;
		// const total = surveyStore.total;
		// const completed = surveyStore.completed;
		// const averages = surveyStore.averages;
		// //Date stuff
		// const currentDate = moment().format('M');
		this.props.surveyStore.values.loading = false;
	}

	render() {
		const surveyStore = this.props.surveyStore.values;
		if (this.props.surveyStore.values.loading) {
			return (
				<div>
					<h1 className="page-title">Call Taker Statistics</h1>
					<InlineLoading />
					<p className="d-block text-center">Rossware robots are working away to gather your stats, this can take awhile...</p>
				</div>
			);
		}
		let callTakers = _.sortBy(surveyStore.callTakers, ['techNps']);
		return (
			<div>
				<h1 className="page-title">Call Taker Statistics</h1>
				<div className="grid-2-col">
					<div>
						<h3 className="">Average of answers provided</h3>
						<p>Net promoter score per call taker.</p>
					</div>
					<div>
						{callTakers.map(name => {
							var techNps = this.props.surveyStore.getTechNps(name, true);
							if (techNps >= 75) {
								var color = 'text-success';
							} else if (techNps < 75 && techNps >= 50) {
								color = 'text-primary';
							} else if (techNps < 50 && techNps >= 30) {
								color = 'text-warning';
							} else if (techNps < 30) {
								color = 'text-danger';
							}
							return (
								<div className="grid-tech-stat grid-calltakers text-decoration-none" key={name}>
									<div>{name}</div>
									<div className={`font-weight-bold ${color}`}>{techNps}</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>

		);
	}
}

export default CallTakerStats;
