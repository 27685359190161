export default async function getSiteData(clientId) {
	try {
		const results = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v3/publicTurnkeyData?client_id=${clientId}`);
		const objectData = await results.json();
		if (results.status > 199 && results.status < 400) {
			return objectData;
		}
	} catch (error) {
		console.log(`getSiteData failed with error: ${error}`);
	}
}