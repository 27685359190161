import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class CTA extends Component {
	render() {
		return (
			<div className="bg-light rounded py-6 mb-4">
				<div className="d-flex flex-column align-items-center justify-content-center">
					<img src={this.props.image} width="80px"></img>
					<h3 className="text-center mb-3 mt-3">{this.props.title}</h3>
					<p className="text-center font-weight-bold text-muted mb-4 px-6">{this.props.description}</p>
					{this.props.internalLink && <Button variant="outline-secondary" as={Link} to={this.props.internalLink}>Click here</Button>}
					{this.props.externalLink && <Button variant="outline-secondary" href={this.props.externalLink}>Click here</Button>}
				</div>
			</div>
		);
	}
}

export default CTA;