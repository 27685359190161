import React, { Component } from 'react';
import ReviewLink from '../../components/reputation/ReviewLink';
import { inject, observer } from 'mobx-react';
import InlineLoading from '../../components/loaders/InlineLoading';
import { Button, Spinner, Alert, ButtonGroup, Form } from 'react-bootstrap';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class Settings extends Component {
	state = {
		loading: true,
		showAlert: false,
		inviteToReviewThreshold: null,
		emailToNotifyThreshold: null,
		emailAddressForSurveyNotifications: null,
		reviewLinks: {
			angies: { link: '', show: false },
			bbb: { link: '', show: false },
			facebook: { link: '', show: false },
			google: { link: '', show: false },
			manta: { link: '', show: false },
			yp: { link: '', show: false },
			yelp: { link: '', show: false },
			homestars: { link: '', show: false },
		}
	}

	componentDidMount() {
		this.getSurveySettings();
	}

	onInviteToReviewThreshold = (value) => {
		this.setState({ inviteToReviewThreshold: value });
	}
	onEmailToNotifyThreshold = (value) => {
		this.setState({ emailToNotifyThreshold: value });
	}

	toggleShowLink = (link) => {
		const { reviewLinks } = this.state;
		if (!reviewLinks[link]) {
			reviewLinks[link] = { link: '', show: true };
		} else {
			reviewLinks[link].show = !reviewLinks[link].show;
		}
		this.setState({ reviewLinks: reviewLinks });
	}

	onTextChange = (event, link) => {
		const value = event.target.value;
		const { reviewLinks } = this.state;
		reviewLinks[link].link = value;
		this.setState({ reviewLinks: reviewLinks });
	}

	onEmailToNotifyChange = (event) => {
		this.setState({ emailAddressForSurveyNotifications: event.target.value });
	}

	getSurveySettings = async () => {
		const business = this.props.authStore.nonObservableValues.business;
		const data = await apiRequest({ endpoint: 'v3/publicCybertheme', parameters: { business_id: business?.business_id } });
		if (data.data) {
			const { data: cyberSettings } = data;
			const reviewLinksObject = cyberSettings?.review_links ? JSON.parse(cyberSettings?.review_links) : { 'angies': { 'link': '','show': false },'bbb': { 'link': '','show': false },'facebook': { 'link': '','show': false },'google': { 'link': '','show': false },'manta': { 'link': '','show': false },'yp': { 'link': '','show': false },'yelp': { 'link': '','show': false }, 'homestars': { 'link': '','show': false } };
			this.setState({
				loading: false,
				showAlert: false,
				inviteToReviewThreshold: cyberSettings?.survey_present_third_party_threshold,
				emailToNotifyThreshold: cyberSettings?.survey_notify_low_threshold,
				emailAddressForSurveyNotifications: cyberSettings?.survey_notification_email,
				reviewLinks: reviewLinksObject
			});
		}
	}

	onSaveButton = async () => {
		this.setState({ loadingSave: true });
		const { token } = this.props.authStore.values;
		const { reviewLinks, inviteToReviewThreshold, emailToNotifyThreshold, emailAddressForSurveyNotifications } = this.state;
		await apiRequest({ endpoint: 'v3/myrossware/setSurveySettings', parameters: { review_links: JSON.stringify(reviewLinks), review_threshold: inviteToReviewThreshold, email_threshold: emailToNotifyThreshold, email_for_survey_notifications: emailAddressForSurveyNotifications }, token });
		this.setState({ loadingSave: false });
	}

	renderSaveButton = () => {
		return (
			<>
				{!this.state.loadingSave && <Button className="btn btn-dark float-right" onClick={this.onSaveButton}>Save changes</Button>}
				{this.state.loadingSave && <Button className="btn btn-dark float-right"><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;Saving</Button>}
			</>
		);
	}

	render() {
		const { reviewLinks } = this.state;
		if (this.state.loading) { return <div><InlineLoading /></div>; } else {
			return (
				<div>
					<div className="page-header">
						<h1>Settings</h1>
					</div>

					<div className="card">
						<div className="card-body">
							<h4>Survey thresholds</h4>
							<b>Invite-to-review Threshold</b>
							<p className="">This threshold sets the average survey score requirement for your customers be to be presented with an invitation to leave a review for your company. If set to 9 for example, any customers submitting an average survey score of 9 or above will be invited to review your busines on third-party sites.<span className="font-weight-bold">We highly recommend setting it at 9 or 10.</span></p>
							<ButtonGroup>
								<Button variant="outline-secondary" onClick={() => this.onInviteToReviewThreshold(7)} disabled={this.state.inviteToReviewThreshold === 7}>7 {this.state.inviteToReviewThreshold === 7 && <span>current</span>}</Button>
								<Button variant="outline-secondary" onClick={() => this.onInviteToReviewThreshold(8)} disabled={this.state.inviteToReviewThreshold === 8}>8 {this.state.inviteToReviewThreshold === 8 && <span>current</span>}</Button>
								<Button variant="outline-secondary" onClick={() => this.onInviteToReviewThreshold(9)} disabled={this.state.inviteToReviewThreshold === 9}>9 {this.state.inviteToReviewThreshold === 9 && <span>current</span>}</Button>
								<Button variant="outline-secondary" onClick={() => this.onInviteToReviewThreshold(10)} disabled={this.state.inviteToReviewThreshold === 10}>10 {this.state.inviteToReviewThreshold === 10 && <span>current</span>}</Button>
							</ButtonGroup>
							<br /><br />
							<b>Notify-me-via-email Threshold</b>
							<p className="">This threshold sets the average survey score you wish to trigger a notification via email. If set to 3, for example, you will receive an email any time a customer submits a survey with an average score at OR below 3.</p>
							<ButtonGroup>
								<Button variant="outline-secondary" onClick={() => this.onEmailToNotifyThreshold(3)} disabled={this.state.emailToNotifyThreshold === 3}>3 {this.state.emailToNotifyThreshold === 3 && <span>current</span>}</Button>
								<Button variant="outline-secondary" onClick={() => this.onEmailToNotifyThreshold(4)} disabled={this.state.emailToNotifyThreshold === 4}>4 {this.state.emailToNotifyThreshold === 4 && <span>current</span>}</Button>
								<Button variant="outline-secondary" onClick={() => this.onEmailToNotifyThreshold(5)} disabled={this.state.emailToNotifyThreshold === 5}>5 {this.state.emailToNotifyThreshold === 5 && <span>current</span>}</Button>
								<Button variant="outline-secondary" onClick={() => this.onEmailToNotifyThreshold(6)} disabled={this.state.emailToNotifyThreshold === 6}>6 {this.state.emailToNotifyThreshold === 6 && <span>current</span>}</Button>
								<Button variant="outline-secondary" onClick={() => this.onEmailToNotifyThreshold(7)} disabled={this.state.emailToNotifyThreshold === 7}>7 {this.state.emailToNotifyThreshold === 7 && <span>current</span>}</Button>
							</ButtonGroup>
							<br /><br />
							<b>Email address for survey notifications</b>
							<Form.Control type="email" placeholder="Enter email" defaultValue={this.state.emailAddressForSurveyNotifications} onChange={ (e) => this.onEmailToNotifyChange(e) }/>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<h4>Third party review sites</h4>
							<div>
								<p>Check the corresponding box to indicate you&apos;d like to offer a particular review site to your customers, then enter the relevant link. These should be direct links to your company profile or review page for each review site.</p>
								<ReviewLink value={reviewLinks['angies'] ? reviewLinks['angies'].link : ''} checked={reviewLinks['angies'] ? reviewLinks['angies'].show : ''} onTextChangeFunction={(event, link) => this.onTextChange(event, link)} onToggleShowFunction={this.toggleShowLink} title="Angie's List" linkName="angies" />
								<ReviewLink value={reviewLinks['bbb'] ? reviewLinks['bbb'].link : ''} checked={reviewLinks['bbb'] ? reviewLinks['bbb'].show : ''} onTextChangeFunction={(event, link) => this.onTextChange(event, link)} onToggleShowFunction={this.toggleShowLink} title="BBB" linkName="bbb" />
								<ReviewLink value={reviewLinks['facebook'] ? reviewLinks['facebook'].link : ''} checked={reviewLinks['facebook'] ? reviewLinks['facebook'].show : ''} onTextChangeFunction={(event, link) => this.onTextChange(event, link)} onToggleShowFunction={this.toggleShowLink} title="Facebook" linkName="facebook" />
								<ReviewLink value={reviewLinks['google'] ? reviewLinks['google'].link : ''} checked={reviewLinks['google'] ? reviewLinks['google'].show : ''} onTextChangeFunction={(event, link) => this.onTextChange(event, link)} onToggleShowFunction={this.toggleShowLink} title="Google" linkName="google" />
								<ReviewLink value={reviewLinks['manta'] ? reviewLinks['manta'].link : ''} checked={reviewLinks['manta'] ? reviewLinks['manta'].show : ''} onTextChangeFunction={(event, link) => this.onTextChange(event, link)} onToggleShowFunction={this.toggleShowLink} title="Manta" linkName="manta" />
								<ReviewLink value={reviewLinks['yp'] ? reviewLinks['yp'].link : ''} checked={reviewLinks['yp'] ? reviewLinks['yp'].show : ''} onTextChangeFunction={(event, link) => this.onTextChange(event, link)} onToggleShowFunction={this.toggleShowLink} title="YellowPages" linkName="yp" />
								<ReviewLink value={reviewLinks['yelp'] ? reviewLinks['yelp'].link : ''} checked={reviewLinks['yelp'] ? reviewLinks['yelp'].show : ''} onTextChangeFunction={(event, link) => this.onTextChange(event, link)} onToggleShowFunction={this.toggleShowLink} title="Yelp" linkName="yelp" />
								<ReviewLink value={reviewLinks['homestars'] ? reviewLinks['homestars'].link : ''} checked={reviewLinks['homestars'] ? reviewLinks['homestars'].show : ''} onTextChangeFunction={(event, link) => this.onTextChange(event, link)} onToggleShowFunction={this.toggleShowLink} title="HomeStars" linkName="homestars" />

								{this.state.showAlert && <Alert variant="danger">Please enter a valid URL for each source or uncheck the box if you don&apos;t want to use it.</Alert>}
							</div>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>
				</div>

			);
		}
	}
}

export default Settings;

