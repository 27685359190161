import React, { useState } from 'react';
import { validateEverything } from 'helpers/validation';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import apiRequest from 'utilities/api';
import Logo from '../assets/rossware-logo-simple.svg';


export default function ResetPassword() {
	const [inputData, setInputData] = useState({
		email_address: {
			proper_name: 'Email address',
			value: null,
			required: true,
			valid: false,
			type: 'email',
		},
	});
	const [invalidMessage, setInvalidMessage] = useState(null);
	const [valid, setValid] = useState(false);
	const [failureMessage, setFailureMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	function inputChange(event) {
		inputData[event.target.name].value = event.target.value;
		setInputData(inputData);
		const validationResult = validateEverything(inputData);
		setValid(validationResult.allValid);
		setInvalidMessage(validationResult.message);
	}
	async function submit() {
		setLoading(true);
		const email_address = inputData.email_address?.value;
		const passResetResult = await apiRequest({ endpoint: 'v3/backend/sendDashboardUserPasswordReset', parameters: { email_address } });
		if (passResetResult.success) {
			setSuccess(true);
		} else {
			setFailureMessage(passResetResult?.message);
		}
		setLoading(false);
	}
	if (success) {
		return (
			<div className="login-wrapper">
				<div className="p-3 border-bottom d-flex justify-content-between">
					<img src={ Logo } width="130px" className="d-inline" />
					<Button href="https://rossware.com">Return to website</Button>
				</div>
				<p>If your email address was found in our system, we just sent you an email containing a link to reset your password. It may take a few minutes before it shows up in your inbox. If you do not get it, check spam folder or <a href="https://rossware.com/connect">contact us for assistance</a>.</p>
				<p>You may now close this page.</p>
			</div>
		);
	}
	return (
		<div className="login-wrapper">
			<div className="p-3 border-bottom d-flex justify-content-between">
				<img src={ Logo } width="130px" className="d-inline" />
				<Button href="https://rossware.com">Return to website</Button>
			</div>

			<div className="justify-content-center flex-column py-5 signup-container">
				<h1>Forgot your password?</h1>
				<h6>Enter the email address you used when creating your account, we&apos;ll send you an email with a link to reset your password.</h6>
				<div className="signup-form">
					<Form.Group>
						<Form.Label><small>Email Address</small></Form.Label>
						<Form.Control name="email_address" type="text" placeholder="john@businessemail.com" onChange={ (e) => { inputChange(e); } } />
					</Form.Group>
				</div>
				<div>{ invalidMessage }</div>
				{ failureMessage && <Alert variant="danger">{ failureMessage }</Alert> }
				{!loading &&
					<Button variant="primary" type="submit" disabled={ !valid } className="btn btn-lg btn-block" onClick={() => submit()}>
						Send reset email
					</Button>
				}
				{loading &&
					<div className="loginLoadingSpinner">
						<Spinner animation="border" variant="primary" />
					</div>
				}
			</div>
		</div>
	);
}
