import React, { Component, Fragment } from 'react';
import { Form, Button } from 'react-bootstrap';
import ServerStatusBadge from './ServerStatusBadge';

export class ServerControls extends Component {
	renderServerList() {
		const { RSSServers } = this.props;
		return ((RSSServers || []).map((server, index) => {
			return (
				<option key={index} index={index}>{server.name}</option>
			);
		}));
	}
	renderServerStatus() {
		const { selectedIndex } = this.props;
		const { status } = this.props.RSSServers[selectedIndex];
		return (
			<ServerStatusBadge status={status} />
		);
	}
	renderServerControls() {
		const { RSSServers, selectedIndex } = this.props;

		const currentStatus = RSSServers[selectedIndex].status;
		switch (currentStatus) {
		case 'running':
			return (
				<div>
					<Button size="sm" className="mr-1 mt-1" variant="success" type="submit" onClick={() => this.props.doServerControlAction('start')} disabled>Start</Button>
					<Button size="sm" className="mr-1 mt-1" variant="danger" type="submit" onClick={() => this.props.doServerControlAction('stop')}>Stop</Button>
					<Button size="sm" className="mr-1 mt-1" variant="primary" type="submit" onClick={() => this.props.doServerControlAction('restart')}>Restart</Button>
				</div>
			);
		case 'stopped':
			return (
				<Fragment>
					<Button size="sm" className="mr-1 mt-1" variant="success" type="submit" onClick={() => this.props.doServerControlAction('start')}>Start</Button>
					<Button size="sm" className="mr-1 mt-1" variant="danger" type="submit" onClick={() => this.props.doServerControlAction('stop')} disabled>Stop</Button>
					<Button size="sm" className="mr-1 mt-1" variant="primary" type="submit" onClick={() => this.props.doServerControlAction('restart')} disabled>Restart</Button>
				</Fragment>
			);
		default:
			return (
				<Fragment>
					<Button size="sm" className="mr-1 mt-1" variant="success" type="submit" onClick={() => this.props.doServerControlAction('start')} disabled>Start</Button>
					<Button size="sm" className="mr-1 mt-1" variant="danger" type="submit" onClick={() => this.props.doServerControlAction('stop')} disabled>Stop</Button>
					<Button size="sm" className="mr-1 mt-1" variant="primary" type="submit" onClick={() => this.props.doServerControlAction('restart')} disabled>Restart</Button>
				</Fragment>
			);
		}
	}
	render() {
		return (
			<Fragment>
				<div className="grid-2-col">
					<Form.Control as="select" size="sm" onChange={this.props.onChange}>
						{this.renderServerList()}
					</Form.Control>
					{this.renderServerStatus()}
				</div>
				<div className="grid-2-col">
					{this.renderServerControls()}
				</div>
			</Fragment>
		);
	}
}

export default ServerControls;
