import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

class PatchNotes extends Component {
	render() {
		const updates = [
			{
				title: 'Added RSS Key information',
				date: '09/08/21',
				desc: 'Under the server solutions tab, you can now copy your tokenized link for RSS Key'
			},
			{
				title: 'Added the ability to delete quickpics',
				date: '02/09/21',
				desc: 'Added the ability to delete quickpics, made some minor changes to make things nicer on the eyes.'
			},
			{
				title: 'Added "Payments" section',
				date: '02/03/21',
				desc: 'Added "Payments" section for tracking and configuring online payments. These online payment requests can be triggered from ServiceDesk.'
			},
			{
				title: 'Added log for SMS messages sent through the robo call system',
				date: '01/15/21',
				desc: 'Under the RoboCalling section (if you are subscribed), you may now view a log of the SMS messages sent and received.'
			},
			{
				title: 'Added New Survey Setting',
				date: '12/18/20',
				desc: 'Under the survey section there is now a setting to set the threshold of what score average is required to prompt customer with the third party review invitation.'
			},
			{
				title: 'Added Call Taker Statistics',
				date: '12/15/20',
				desc: 'New menu option to choose Call Taker statistics. Currently it is lacking the ability to sort and view a more detailed breakdown by individual. Those features will be in a future update.'
			},
			{
				title: 'Added ability to flag ticket for re-download',
				date: '11/11/20',
				desc: 'In the SD-Mobile PVR section, you can now flag tickets for re-download by the office.'
			},
		];
		return (
			<div>
				{updates.map(update => {
					return (
						<Card key={update.date} className="mb-4 border-bottom">
							<Card.Body>
								<h3 className="mb-1">{update.title}</h3>
								<h3 className="mb-1">{update.date}</h3>
								<div>{update.desc}</div>
							</Card.Body>
						</Card>
					);
				})}
			</div>
		);
	}
}

export default PatchNotes;