import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import ServerControls from '../../components/servermanagement/ServerControls';
import InlineLoading from '../../components/loaders/InlineLoading';
import Dialog from 'react-bootstrap-dialog';
import NotSubscribedMessage from '../../components/NotSubscribedMessage';
import { Alert, Button, Card } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaRegCopy, FaExternalLinkAlt } from 'react-icons/fa';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class ServerControl extends Component {
	state = {
		loading: true,
		RSSServers: {},
		selectedIndex: 0,
		token: null,
	}
	silentUpdate = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/getRSSInstances', token });
		this.setState({ RSSServers: data });
	}
	componentWillUnmount() {
		clearInterval(this.timer);
	}
	componentDidMount = async () => {
		const { token } = this.props.authStore.values;
		this.timer = setInterval(this.silentUpdate, 5000);
		Dialog.setOptions({
			title: 'Confirm Action',
			defaultOkLabel: 'Yes',
			defaultCancelLabel: 'Nevermind',
			primaryClassName: 'btn-primary',
			defaultButtonClassName: 'btn-link'
		});
		const tokenFetch = await apiRequest({ endpoint: 'v3/getbusinesstoken', token });
		const businessToken = tokenFetch.token;
		this.setState({ token: businessToken });
		this.initialRSSInfoFetch();
		this.silentUpdate('start');
	}
	onChange = (event) => {
		this.setState({ selectedIndex: event.target.selectedIndex });
	}
	initialRSSInfoFetch = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/getRSSInstances', token });
		this.setState({ loading: false, RSSServers: data });
	}
	promptServerControlAction = (action) => {
		if (action === 'start') { this.doServerControlAction(action); return false; }
		this.dialog.show({
			title: 'Confirm Action',
			body: 'Are you sure you want to ' + action + ' this server? Doing so will disconnect all users. Please be sure before proceeding.',
			actions: [
				Dialog.CancelAction(),
				Dialog.OKAction(() => {
					this.doServerControlAction(action);
				})
			],
			bsSize: 'small',
			onHide: (dialog) => {
				dialog.hide();
			}
		});
	}
	doServerControlAction = async (action) => {
		const { token } = this.props.authStore.values;
		const { instance_id, region } = this.state.RSSServers[this.state.selectedIndex];
		await apiRequest({ endpoint: 'v3/myrossware/operateRSSInstance', parameters: { action: action, instance_id: instance_id, region: region }, token });
		setTimeout(() => {
			this.initialRSSInfoFetch();
		}, (1000));
	}
	renderControls = () => {
		if (this.state.RSSServers.length > 0) {
			return (
				<Fragment>
					<Dialog ref={(el) => { this.dialog = el; }} />
					<ServerControls RSSServers={this.state.RSSServers} onChange={this.onChange} selectedIndex={this.state.selectedIndex} doServerControlAction={this.promptServerControlAction} />
					<div className="clearfix"></div>
					<Alert variant="warning" className="mb-1 mt-2">Please allow time for operations to fully complete before attempting other actions. Stopping a server before it is fully started or vice versa can result in a locked state which will require contacting Rossware for assistance.</Alert>
					<Alert variant="warning" className="mb-1">After starting, though your server may indicate &quot;Running&quot;, it will be completing status checks for several minutes. It will take some time after startup before you are able to log in.</Alert>
				</Fragment>

			);
		} else {
			return <NotSubscribedMessage head="Not signed up for Server Hosting" p="Rossware Server Solutions can get you on the cloud fast! We host powerful Windows servers so you and your office can connect remotely to one shared data source. Automated backups occur daily, weekly, and monthly so no matter what happens your data is safe. Click below for more information and to get started!" link="https://rossware.com/hosting" />;
		}
	}
	onTokenCopy = () => {
		this.setState({ recentlyCopied: true });
		setTimeout(() => {
			this.setState({ recentlyCopied: false });
		}, 1000);
	}
	render() {
		const { loading, token, recentlyCopied } = this.state;
		if (loading) {
			return (<Card><Card.Body><InlineLoading /></Card.Body></Card>);
		} else {
			return (
				<div>
					<Card className="mb-3">
						<Card.Header>Controls
						</Card.Header>
						<Card.Body>
							{ this.renderControls() }
						</Card.Body>
					</Card>
					<Card className="mb-3 rssKeyTokenCard">
						<Card.Header>RSS Key
						</Card.Header>

						<Card.Body className="rssKeyTokenCardBody">
							<div><h6>Tokenized Link</h6><p className="mb-0 mt-n2">https://key.rossware.com/?token={ token }</p></div>
							<CopyToClipboard text={`https://key.rossware.com/?token=${ token }`}><Button onClick={ () => this.onTokenCopy() } disabled={ recentlyCopied } variant="primary"><FaRegCopy /> { recentlyCopied ? 'Copied!' : 'Copy' }</Button></CopyToClipboard>
							<Button variant="success" onClick={ () => { window.open(`https://key.rossware.com/?token=${ token }`, '_blank'); } }><FaExternalLinkAlt /> Open</Button>
						</Card.Body>

					</Card>
				</div>
			);

		}

	}
}

export default ServerControl;

