import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import apiRequest from 'utilities/api';
import { useStores } from 'utilities/useStores';
import { FaExternalLinkAlt } from 'react-icons/fa';
import TipReports from 'components/rosswarepay/TipReports';
import PlatformFeeReports from 'components/rosswarepay/PlatformFeeReports';

export default observer(() => {
	const { authStore } = useStores();
	const [loading, setLoading] = useState(true);
	const [productionAuthKey, setProductionAuthKey] = useState();
	const [testAuthKey, setTestAuthKey] = useState();

	const [testMerchantId, productionMerchantId] = [authStore?.nonObservableValues?.business?.test_rosswarepay_merchant_id, authStore?.nonObservableValues?.business?.production_rosswarepay_merchant_id];

	const requestAuthKey = async () => {
		setLoading(true);
		if (testMerchantId) {
			const authKey = await apiRequest({ endpoint: 'v3/rosswarepay/test/ssoAuth', token: authStore?.values?.token });
			setTestAuthKey(authKey?.authenticationKey);
		} else if (productionMerchantId) {
			const authKey = await apiRequest({ endpoint: 'v3/rosswarepay/production/ssoAuth', token: authStore?.values?.token });
			setProductionAuthKey(authKey?.authenticationKey);
		}
		setLoading(false);
	};

	const renderMerchantTrackButton = () => {
		if (testAuthKey) {
			return (
				<form action="https://merchanttrack-ext.fullsteampay.net/Account/SingleSignOn" method="post" target="_blank">
					<input type="hidden" id="AuthenticationKey" name="AuthenticationKey" value={ testAuthKey } />
					<button type="submit" className="merchanttrack-button">Launch MerchantTrack &nbsp;&nbsp;<FaExternalLinkAlt /></button>
				</form>
			);
		} else if (productionAuthKey) {
			return (
				<form action="https://merchanttrack.fullsteampay.net/Account/SingleSignOn" method="post" target="_blank">
					<input type="hidden" id="AuthenticationKey" name="AuthenticationKey" value={ productionAuthKey } />
					<button type="submit" className="merchanttrack-button">Launch MerchantTrack &nbsp;&nbsp;<FaExternalLinkAlt /></button>
				</form>
			);
		}
	};

	useEffect(() => {
		requestAuthKey();
	}, []);

	if (loading) return <h2>Loading</h2>;
	if (!(testAuthKey || productionAuthKey)) return <h2>SIGN UP FOR ROSSWAREPAY</h2>;

	return (
		<div>
			<div className="page-header">
				<h1>Reporting</h1>
			</div>

			<div className="card">
				<div className="card-body">
					{ renderMerchantTrackButton() }
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<TipReports />
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<PlatformFeeReports />
				</div>
			</div>
		</div>
	);


});
