export function formatPhoneReadable(phone) {
	if (!phone) {
		return 'Unknown';
	}
	let strippedPhone = phone.replace(/\D/g,'');
	if (!(strippedPhone.length === 10 || strippedPhone.length === 11)) {
		return phone;
	}
	if (strippedPhone.length === 10) {
		return '(' + strippedPhone.substring(0,3) + ') ' + strippedPhone.substring(3,6) + '-' + strippedPhone.substring(6,10);
	} else {
		return '(' + strippedPhone.substring(1,4) + ') ' + strippedPhone.substring(4,7) + '-' + strippedPhone.substring(7,11);
	}
}

export function formatToCurrency(val) {
	return '$' + val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatToNumber(val) {
	return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
