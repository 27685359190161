import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button, Card, Form, InputGroup, Table } from 'react-bootstrap';
import InlineLoading from 'components/loaders/InlineLoading';
import _ from 'lodash';
import apiRequest from 'utilities/api';

@inject('authStore', 'techStore')
@observer class TechImages extends Component {
	constructor() {
		super();
		this.state = {
			file: '',
			techs: {},
			loading: true,
			tech: ''
		};

	}

	componentDidMount() {
		this.getTechData();
	}

	async getTechData() {
		const techList = await this.props.techStore.getTechList();
		const techImages = await this.props.techStore.getTechImages();

		for (let dex = 0; dex < techList.length; dex++) {
			const matchingIndex = (techImages.findIndex(techImage => {
				return techImage.TechCode === techList[dex].TechCode;
			}));
			if (matchingIndex !== -1) {
				techList[dex].TechImage = techImages[matchingIndex].TechImage;
			}
		}
		this.setState({
			techs: techList,
			loading: false,
			file: ''
		});
	}

	convertImage(data) {
		let buffer = Buffer.from(data); // eslint-disable-line
		return 'data:image/jpg;base64,' + buffer.toString('base64');
	}

	handleTechSelect = (event) => {
		this.setState({
			tech: event.target.value
		});
	}

	handleFilePick = (event) => {
		this.setState({
			file: event.target.files
		});
	}

	handleUpload = () => {
		this.setState({
			loading: true
		}, async () => {
			let authStore = this.props.authStore;
			const { token } = authStore.values;
			var fileListObject = this.state.file[0];
			let formData = new FormData();
			formData.append('File', fileListObject);
			formData.append('tech_code', this.state.tech);

			const response = await apiRequest({ endpoint: 'v3/myrossware/uploadTechImage', body: formData, method: 'POST', token });

			if (response) {
				this.getTechData();
			}
		});
	}

	render() {
		if (this.state.loading) {
			return (
				<Card>
					<Card.Body>
						<InlineLoading />
					</Card.Body>
				</Card>
			);
		}

		return (
			<Card>
				<Card.Body>
					<h3>Upload a new image</h3>
					<div className="d-flex justify-content-between mb-5">
						<InputGroup className="mr-2">
							<select className="custom-select" onChange={this.handleTechSelect} defaultValue="">
								<option disabled value="">Select a tech to upload for</option>
								{!_.isEmpty(this.state.techs) && this.state.techs.map(tech => {
									return (
										<option key={tech.TechCode} value={tech.TechCode}>{tech.TechCode} - {tech.TechName}</option>
									);
								})};
							</select>
						</InputGroup>
						<Form.File
							label={!this.state.file ? 'Click to select a file' : this.state.file[0].name}
							lang="en"
							custom
							onChange={(event) => this.handleFilePick(event)}
							className="mr-2"
							accept=".jpg,.jpeg"
						/>
						<Button onClick={() => this.handleUpload()}>Upload</Button>
					</div>

					<h3>Current tech images</h3>
					<Table>
						<thead>
							<tr>
								<th>TechCode</th>
								<th>TechName</th>
								<th>Image</th>
							</tr>
						</thead>
						<tbody>
							{this.state.techs.map(tech => {
								if (tech.TechCode) {
									return (
										<tr key={tech.TechCode}>
											<td>{tech.TechCode}</td>
											<td>{tech.TechName}</td>
											<td>
												{ tech.TechImage && <img height="100px" className="rounded" src={this.convertImage(tech.TechImage.data)} /> }
												{ !tech.TechImage && <span>No image</span> }
											</td>
										</tr>
									);
								}
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		);
	}
}

export default TechImages;