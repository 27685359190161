import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { inject, observer } from 'mobx-react';
import InlineLoading from 'components/loaders/InlineLoading';
import moment from 'moment';

@inject('authStore', 'surveyStore')
@observer class Stats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			completions: {},
			npsScore: {},
			answer: {}
		};
		if (!this.props.surveyStore.surveys) {
			this.props.surveyStore.getSurveys().then(() => {
				this.setValues();
			});
		} else {
			this.setValues();
		}
		//We need this to only hit API if it hasn't already, above code is hitting it on every remount.
	}

	setValues() {
		const surveyStore = this.props.surveyStore.values;
		const total = surveyStore.total;
		const completed = surveyStore.completed;
		const averages = surveyStore.averages;
		//Date stuff
		const currentDate = moment().format('M');
		this.setState({
			completions: {
				options: {
					chart: {
						width: '100%',
					},
					labels: ['Completed', 'Not Completed']

				},
				series: [completed, total],
			},
			npsScore: {
				options: {
					chart: {
						width: '100%',
						markers: {
							size: 3,
							colors: undefined,
							strokeColors: '#fff',
							strokeWidth: 2,
							strokeOpacity: 0.9,
							strokeDashArray: 0,
							fillOpacity: 1,
							discrete: [],
							shape: 'circle',
							radius: 2,
							offsetX: 0,
							offsetY: 0,
							onClick: undefined,
							onDblClick: undefined,
							showNullDataPoints: true,
							hover: {
								size: undefined,
								sizeOffset: 3
							}
						}
					},
					xaxis: {
						categories: [
							moment(currentDate - 4, 'M').format('MMMM'),
							moment(currentDate - 3, 'M').format('MMMM'),
							moment(currentDate - 2, 'M').format('MMMM'),
							moment(currentDate - 1, 'M').format('MMMM'),
							moment(currentDate, 'M').format('MMMM')
						]
					},
					yaxis: {
						min: 0,
						max: 100
					}
				},
				series: [
					{
						name: 'NPS',
						data: [surveyStore.nps6, surveyStore.nps5, surveyStore.nps4, surveyStore.nps3, surveyStore.nps2]
					}
				]
			},
			answers: {
				options: {
					chart: {
						width: '100%'
					},
					xaxis: {
						categories: ['Calltaker', 'Technician', 'Value', 'Recommend']
					},
					yaxis: {
						min: 0,
						max: 10
					}
				},
				series: [
					{
						name: 'Calltaker',
						data: [averages.callTaker]
					},
					{
						name: 'Technician',
						data: [averages.technician]
					},
					{
						name: 'Value',
						data: [averages.value]
					},
					{
						name: 'Recommend',
						data: [averages.recommend]
					},
				]
			}
		}, () => {
			this.props.surveyStore.values.loading = false;
		});
	}

	render() {
		if (this.props.surveyStore.values.loading) {
			return (
				<div>
					<div>
						<InlineLoading />
						<p className="d-block text-center">Rossware robots are working away to gather your stats, this can take awhile...</p>
					</div>
				</div>
			);
		}
		return (
			<div>
				<h1 className="page-title">Statistics</h1>
				<div className="">
					<div className="grid-2-col border-bottom pb-4 mb-4">
						<div>
							<h3 className="">Survey completions</h3>
							<p className="d-block">The all-time total of how many surveys have been completed by your customers.</p>
						</div>

						<div className="rounded bg-light border p-3">
							{this.state.completions && <Chart
								options={this.state.completions.options}
								series={this.state.completions.series}
								labels={this.state.completions.labels}
								type="pie"
							/>}
						</div>
					</div>
					<div className="grid-2-col border-bottom pb-4 mb-4">
						<div>
							<h3 className="">Net Promoter Score</h3>
							<div className="p-3 bg-light rounded border mb-3">All-time score: <b className="text-primary">{this.props.surveyStore.values.nps1}</b></div>
							<p className="d-block">Net Promoter Score®, or NPS®, measures customer experience and predicts business growth. This proven metric transformed the business world and now provides the core measurement for customer experience management programs the world round. You can read more about it <a href="https://www.netpromoter.com/know/" target="_blank" rel="noreferrer">here</a>.</p>
						</div>

						<div className="rounded bg-light border p-3">
							<h5 className="text-center">NPS Score over time</h5>

							{this.state.completions && <Chart
								options={this.state.npsScore.options}
								series={this.state.npsScore.series}
								labels={this.state.npsScore.labels}
								type="line"
							/>}
						</div>
					</div>
					<div className="grid-2-col mb-5">
						<div>
							<h3>Average of answers provided</h3>
							<p>This is the all-time averages of the answers provided by your customers.</p>
						</div>

						<div className="rounded bg-light border p-3">
							<h5 className="text-center"></h5>

							<Chart
								options={this.state.answers.options}
								series={this.state.answers.series}
								type="bar"
							/>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

export default Stats;