import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

export class CallLogLoading extends Component {
	render() {
		return (
			<div className="myRwCallLogLoadingDiv w-100 d-flex justify-content-center align-items-center">
				<Spinner animation="border" role="status" variant="primary">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</div>
		);
	}
}

export default CallLogLoading;
