import React, { Component } from 'react';
import { InputGroup, Button, FormControl } from 'react-bootstrap';

export class SearchCalls extends Component {
	state = {
		searchOn: 'invoice',
		placeholder: 'Searching on invoice number',
		textBoxValue: ''
	}
	toggleSearchType = (from) => {
		from === 1 ? this.setState({ searchOn: 'invoice', placeholder: 'Searching on invoice number' }) : this.setState({ searchOn: 'phone', placeholder: 'Searching on phone number' });
	}
	onKeyPress = (event) => {
		if (event.which === 13) {
			this.props.searchForCall(this.state);
		}
		this.state.searchOn === 'invoice' ? this.setState({ textBoxValue: event.target.value }) : this.setState({ textBoxValue: event.target.value });
	}
	render = () => {
		return (
			<InputGroup className="mb-3 mt-3">
				<InputGroup.Prepend>
					{this.state.searchOn === 'invoice' && <Button variant="secondary" active size="sm" onClick={() => this.toggleSearchType(1)}>Invoice Number</Button>}
					{this.state.searchOn !== 'invoice' && <Button variant="secondary" size="sm" onClick={() => this.toggleSearchType(1)}>Invoice Number</Button>}
					{this.state.searchOn === 'phone' && <Button variant="secondary" active size="sm" onClick={() => this.toggleSearchType(2)}>Phone Number</Button>}
					{this.state.searchOn !== 'phone' && <Button variant="secondary" size="sm" onClick={() => this.toggleSearchType(2)}>Phone Number</Button>}
				</InputGroup.Prepend>
				<FormControl
					placeholder={this.state.placeholder}
					aria-label="Invoice Number"
					aria-describedby="basic-addon2"
					size="sm"
					onKeyUp={this.onKeyPress}
					onChange={this.onKeyPress}
					value={this.state.textBoxValue}
				/>
				<InputGroup.Append>
					<Button variant="primary" size="sm" onClick={() => this.props.searchForCall(this.state)}>Search</Button>
				</InputGroup.Append>
			</InputGroup>
		);
	}
}

export default SearchCalls;

