import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { formatToCurrency, formatToNumber } from '../../helpers/formatting';
import InlineLoading from '../../components/loaders/InlineLoading';
import { Card } from 'react-bootstrap';
import Picture from '../../assets/image.svg';
import Search from '../../assets/search.svg';
import Machine from '../../assets/washing-machine.svg';
import CTA from 'components/cta';
import QuickPicsSearch from '../../components/quickpics/QuickPicsSearch';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class QuickPicsOverview extends Component {
	state = {
		quickPicsInfo: {},
		loading: true,
		searching: false,
		picResult: {}
	}
	componentDidMount = async () => {
		let { token } = this.props.authStore.values;
		let data = await apiRequest({ endpoint: 'v3/myRossware/quickPicsData', token });
		this.setState({ quickPicsInfo: data.data[0], loading: false });
	}

	render() {
		let { total_pic_count, unique_machines, pics_this_month } = this.state.quickPicsInfo;
		let month_image_cost = parseInt(pics_this_month) * 0.04;
		if (this.state.loading) {
			return <div><InlineLoading /></div>;
		} else if (!total_pic_count) {
			return <div><InlineLoading /></div>;
		} else {
			return (
				<div>
					<h1 className="page-title">Quickpics overview</h1>

					<div className="grid-2-col pb-4 mb-4">
						<div>
							<Card className="h-100">
								<Card.Body className="text-center h-100 py-6">
									<img src={Picture} width="50px" />
									<h2>{total_pic_count ? formatToNumber(total_pic_count) : '--'}</h2>
									<p className="mb-4">Pictures taken total</p>
									<img src={Machine} width="50px" />
									<h2>{unique_machines ? formatToNumber(unique_machines) : '--'}</h2>
									<p>Total machines</p>
								</Card.Body>
							</Card>
						</div>
						<div>
							<Card className="h-100">
								<Card.Body className="text-center h-100 py-6">
									<img src={Search} width="50px" />
									<h2>{month_image_cost ? formatToCurrency(month_image_cost) : '--'}</h2>
									<p>Your total cost of images for the current month</p>
									<small>The 4 cent per pic fee covers use of all involved mechanisms (e.g., the SD-QuickPic app itself, communication channels and data movement, etc.). You also get at least two years of picture storage sans any separate fee.</small>
								</Card.Body>
							</Card>
						</div>
					</div>
					<QuickPicsSearch />

					<CTA
						image={Picture}
						title="Not signed up for Quickpics?"
						description="Quickpics is a feature accessible to any technician's signed up for SD-Mobile. Contact our team to get started!"
						externalLink="https://rossware.com/contact"
					/>
				</div>

			);
		}
	}
}

export default QuickPicsOverview;
