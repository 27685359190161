import { observable, action } from 'mobx';
import apiRequest from 'utilities/api';

class authStore {
	@observable values = {
		currentPageLoading: false,
		loadingLogin: null,
		loginFailure: null,
		loading: null,

		token: null,
	}

	nonObservableValues = {
		user: null,
		business: null,
		roboRegistration: null,
	}

	@action reAuthenticate = async (token, globalLoad) => { // run this in componentDidMount any time we want fully up-to-date data to work with
		this.values.loading = !!globalLoad;
		const authResult = await apiRequest({ endpoint: 'v3/dashboard/authenticate', method: 'POST', token });

		const tollFreeRoboRegistrationResult = await apiRequest({ endpoint: 'v3/myrossware/getA2P10DLCCampaignInDb', token });

		if (authResult.message === 'unauthorized' || !authResult?.user || !authResult?.business) {
			this.values.loginFailure = true;
		} else {
			const { user, business } = authResult;
			this.nonObservableValues.user = user;
			this.nonObservableValues.business = business;
			this.nonObservableValues.roboRegistration = tollFreeRoboRegistrationResult?.A2PDLCRegistration || null;

			this.values.token = user?.token;
			window.sessionStorage.setItem('token', user?.token);
		}
		this.values.loading = false;
		return true;
	}

	@action login = async () => {
		this.values.loadingLogin = true;
		this.values.loginFailure = false;

		const { email_address, password } = this.nonObservableValues;

		const authResult = await apiRequest({ endpoint: 'v3/dashboard/authenticate', method: 'POST', body: { email_address, password } });
		const tollFreeRoboRegistrationResult = await apiRequest({ endpoint: 'v3/myrossware/getA2P10DLCCampaignInDb', token: authResult?.user?.token });

		this.values.loadingLogin = false;
		if (authResult.message === 'unauthorized' || !authResult?.user || !authResult?.business) {
			this.values.loginFailure = true;
		} else {
			const { user, business } = authResult;
			this.nonObservableValues.user = user;
			this.nonObservableValues.business = business;
			this.nonObservableValues.roboRegistration = tollFreeRoboRegistrationResult?.A2PDLCRegistration || null;

			this.values.token = user?.token;
			window.sessionStorage.setItem('token', user?.token);
		}
	}

	@action handleLogout = async (token) => {
		await apiRequest({ endpoint: 'v3/authentication/deAuthenticateUser', token });

		window.sessionStorage.clear();
		this.values.token = null;
		this.nonObservableValues.user = null;
		this.nonObservableValues.business = null;
	}
}

export default new authStore();
