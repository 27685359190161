import CTA from 'components/cta';
import React, { Component } from 'react';
import Warning from '../assets/warning-sign.svg';

class NotFound extends Component {
	render() {
		return (
			<CTA
				title="Whoops!"
				image={Warning}
				description="Looks like you've reached a page that doesn't exist. Click the button below to return home."
				internalLink="/"
			/>
		);
	}
}

export default NotFound;