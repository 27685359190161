import React, { Component } from 'react';
import PendingCalls from '../../components/robocalling/PendingCalls';
import { Card } from 'react-bootstrap';

export class RoboCallingPendingCalls extends Component {
	render() {
		return (
			<Card>
				<Card.Title className="p-4 border-bottom">Pending Calls</Card.Title>
				<Card.Body>
					<PendingCalls />
				</Card.Body>
			</Card>
		);
	}
}

export default RoboCallingPendingCalls;

