import React, { Component } from 'react';
import { Button, ButtonGroup, Form, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import InlineLoading from '../../components/loaders/InlineLoading';
import CopyToClipboard from 'react-copy-to-clipboard';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class Settings extends Component {
	state = {
		loading: true,
	}
	componentDidMount() {
		const businessID = this.props.authStore.nonObservableValues.user.business_id;
		this.getCyberTheme();
		this.setState({
			schedURL: 'https://booking.rossware.com/schedule/' + businessID,
			jobStatusURL: 'https://booking.rossware.com/check-status/' + businessID,
			techStatusURL: 'https://booking.rossware.com/track-technician/' + businessID,
			schedEmbed: '<iframe style="width:100%;height:500px;border:none;" src="https://booking.rossware.com/schedule/' + businessID + '"></iframe>',
			jobStatusEmbed: '<iframe style="width:100%;height:500px;border:none;" src="https://booking.rossware.com/check-status/' + businessID + '"></iframe>',
			techStatusEmbed: '<iframe style="width:100%;height:500px;border:none;" src="https://booking.rossware.com/track-technician/' + businessID + '"></iframe>',
		});
	}
	getCyberTheme = async () => {
		const user = this.props.authStore.nonObservableValues.user;

		const businessCyber = await apiRequest({ endpoint: 'v3/publicCyberTheme', parameters: { business_id: user?.business_id } });
		const cyberSettings = businessCyber.data;

		const businessResponse = await apiRequest({ endpoint: 'v3/publicBusiness', parameters: { business_id: user?.business_id } });
		const business = businessResponse?.data;

		this.setState({ cyberSettings, business, loading: false });
	}

	onSaveButton = async () => {
		this.setState({ loadingSave: true });
		const { token } = this.props.authStore.values;
		const { cyberSettings } = this.state;

		if (cyberSettings.logo_image) {
			try {
				let formData = new FormData();
				formData.append('File', cyberSettings.logo_image);
				formData.append('image_name', cyberSettings.logo_image_name);

				await apiRequest({ endpoint: 'v3/myrossware/uploadSDCOLogoImage', method: 'POST', body: formData, token });
			} catch (err) {
				console.log('failed to upload image', err);
			}
		}
		delete cyberSettings.logo_image;
		console.log(cyberSettings);

		let formData = new FormData();
		formData.append('cyberSettings', cyberSettings);
		await apiRequest({ endpoint: 'v3/myrossware/setCyberSettings', parameters: { cyberSettings: JSON.stringify(cyberSettings) }, token, method: 'POST' });
		this.setState({ loadingSave: false });
	}

	toggleShowEmbedCode = (show) => {
		this.setState({ showEmbedCodes: show });
	}
	onChange = () => {
		const { cyberSettings } = this.state;
		if (event.target.type === 'checkbox') {
			cyberSettings[event.target.name] = event.target.checked;
		} else {
			cyberSettings[event.target.name] = event.target.value;
		}
		this.setState({ cyberSettings: cyberSettings });
	}
	onLogoImgSelect = (e) => {
		const { cyberSettings } = this.state;
		cyberSettings.logo_image = e?.target?.files[0];
		cyberSettings.logo_image_name = e?.target?.files[0]?.name;
		this.setState({ cyberSettings: cyberSettings });
	}
	renderSaveButton = () => {
		return (
			<>
				{!this.state.loadingSave && <Button variant="dark float-right" onClick={this.onSaveButton}>Save</Button>}
				{this.state.loadingSave && <Button variant="dark float-right" onClick={this.onSaveButton}><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;Saving</Button>}
			</>
		);
	}

	render() {
		const { schedURL, jobStatusURL, techStatusURL, schedEmbed, jobStatusEmbed, techStatusEmbed, showEmbedCodes, cyberSettings, business } = this.state;
		const authStore = this.props.authStore.values;

		if (this.state.loading) { return <InlineLoading />; } else {
			console.log(cyberSettings);
			return (
				<div>
					<div className="page-header">
						<h1>Settings</h1>
						<div>
							<a className="btn btn-light mr-2" href="#online-configuration">Online configuration</a>
							<a className="btn btn-light mr-2" href="#implementation">Implementation</a>
							<a className="btn btn-light" href="#cyberlink-settings">SD-CyberLink settings</a>
						</div>
					</div>
					<div className="hidden-anchor" id="online-configuration"></div>
					<h2>Online configuration</h2>
					<div className="card mb-3">
						<div className="card-body">
							<h5>Color selection</h5>
							<p>Click color pallete to change color value</p>
							<Form.Control
								type="color"
								name="foreground_color"
								defaultValue={ cyberSettings?.foreground_color || '#000' }
								title="Choose your color"
								onChange={ this.onChange }
								className="color-picker"
							/>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<h5>Logo image</h5>
							<p>of type .jpg, .jpeg, or .png, an image with transparency is recommended</p>
							<Form.File
								lang="en"
								onChange={(event) => this.onLogoImgSelect(event)}
								accept=".jpg,.jpeg,.png"
							/>
							{ (cyberSettings?.logo_image_name && !cyberSettings?.logo_image) && <img style={ { maxHeight: 50, marginTop: 10 } } src={`https://public-rw-client-assets.s3.us-west-2.amazonaws.com/${business.id}/logo_image/${cyberSettings.logo_image_name}`} /> }
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<h5>Layout</h5>
							<br />
							<span>Show initial page</span>
							<Form.Check
								style={{ float: 'right' }}
								onChange={ this.onChange }
								defaultChecked={ cyberSettings?.show_initial_page || false }
								type="switch"
								name="show_initial_page"
								label=''
								id="show_initial_page"
							/>
							<hr style={{ marginTop: '2px', marginBottom: '2px' }}/>
							<span>Show branding (logo image, header &amp; footer)</span>
							<Form.Check
								style={{ float: 'right' }}
								onChange={ this.onChange }
								defaultChecked={ cyberSettings?.show_header_footer || false }
								type="switch"
								name="show_header_footer"
								label=''
								id="show_header_footer"
							/>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<b>URL after schedule</b><br />
							<span>Leave blank if unwanted</span>
							<FormControl
								name="url_after_schedule"
								onChange={ this.onChange }
								defaultValue={ cyberSettings?.url_after_schedule || '' }
								placeholder="https://mycompanywebsite.com/ThanksForScheduling"
							/>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<b>Back to website URL</b><br />
							<span>With branding enabled, customers may click a button to be directed to this URL</span>
							<FormControl
								name="back_to_website_url"
								onChange={ this.onChange }
								defaultValue={ cyberSettings?.back_to_website_url || '' }
								placeholder="https://mycompanywebsite.com/"
							/>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<Form.Check
								style={{ float: 'right' }}
								onChange={ this.onChange }
								defaultChecked={ cyberSettings?.show_custom_terms || false }
								type="switch"
								name="show_custom_terms"
								label=''
								id="show_custom_terms"
							/>
							<b>Custom terms and conditions</b><br />
							<span>Require your customer agree to these terms before being allowed to schedule an appointment</span>
							<div className={ cyberSettings?.show_custom_terms ? '' : 'd-none' }>
								<br />
								<span>Cash-On-Delivery Terms</span>
								<FormControl
									as="textarea"
									name="custom_cod_terms"
									onChange={ this.onChange }
									defaultValue={ cyberSettings?.custom_cod_terms || '' }
									maxLength="5000"
								/>
								<span>Warranty Terms</span>
								<FormControl
									as="textarea"
									name="custom_warranty_terms"
									onChange={ this.onChange }
									defaultValue={ cyberSettings?.custom_warranty_terms || '' }
									maxLength="5000"
								/>
							</div>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<b>Scheduling window</b><br />
							<span>Limit how far in the future your clients may schedule online</span>

							<FormControl name="limit_scheduling_window" defaultValue={ cyberSettings?.limit_scheduling_window?.toString() || '0' } as="select" onChange={(e) => { this.onChange(e); }}>
								<option value="0">No limit</option>
								<option value="1">1 week</option>
								<option value="2">2 weeks</option>
								<option value="3">3 weeks</option>
								<option value="4">4 weeks</option>
								<option value="5">5 weeks</option>
								<option value="6">6 weeks</option>
							</FormControl>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<Form.Check
								style={{ float: 'right' }}
								onChange={ this.onChange }
								defaultChecked={ cyberSettings?.disallow_reschedule || false }
								type="switch"
								name="disallow_reschedule"
								label=''
								id="disallow_reschedule"
							/>
							<b>Disallow reschedule</b><br />
							<span>Disallow your customer from rescheduling when you send a confirmation request</span>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>
					<div className="hidden-anchor" id="implementation"></div>
					<h3 className="mt-5">Implementation&nbsp;&nbsp;<small>Use the URLs or embed codes below to either create links or embed the cyberoffice tools on your website</small></h3>

					<div className="card">
						<div className="card-body">
							<div className="mb-3">
								<ButtonGroup aria-label="Basic example">
									<Button variant="secondary" size="sm" active={!showEmbedCodes} onClick={() => this.toggleShowEmbedCode(false)}>Direct URLs</Button>
									<Button variant="secondary" size="sm" active={showEmbedCodes} onClick={() => this.toggleShowEmbedCode(true)}>Embed Codes</Button>
								</ButtonGroup>
							</div>
							<b>Scheduler</b>

							<InputGroup size="sm" className="mb-3">
								<FormControl readOnly value={showEmbedCodes ? schedEmbed : schedURL} />
								<InputGroup.Append>
									<CopyToClipboard
										text={showEmbedCodes ? schedEmbed : schedURL}>
										<Button variant="outline-secondary">Copy</Button>
									</CopyToClipboard>
								</InputGroup.Append>
							</InputGroup>
							<b>Tech Tracking</b>
							<InputGroup size="sm" className="mb-3">
								<FormControl readOnly size="sm" value={showEmbedCodes ? techStatusEmbed : techStatusURL} />
								<InputGroup.Append>
									<CopyToClipboard
										text={showEmbedCodes ? techStatusEmbed : techStatusURL}>
										<Button variant="outline-secondary">Copy</Button>
									</CopyToClipboard>
								</InputGroup.Append>
							</InputGroup>
							<b>Job Status</b>
							<InputGroup size="sm" className="mb-3">
								<FormControl readOnly size="sm" value={showEmbedCodes ? jobStatusEmbed : jobStatusURL} />
								<InputGroup.Append>
									<CopyToClipboard
										text={showEmbedCodes ? jobStatusEmbed : jobStatusURL}>
										<Button variant="outline-secondary">Copy</Button>
									</CopyToClipboard>
								</InputGroup.Append>
							</InputGroup>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>
					<div className="hidden-anchor" id="cyberlink-settings"></div>
					<h3 className="mt-5">SD-CyberLink Settings&nbsp;&nbsp;<small>Configured from the desktop application</small></h3>
					<div className="card">
						<div className="card-body">
							<span>Contact email</span>
							<span style={{ float: 'right' }}>{ business?.t_email }</span>
							<hr style={{ marginTop: '2px', marginBottom: '2px' }}/>
							<span>Contact phone</span>
							<span style={{ float: 'right' }}>{ business?.TelNmbr }</span>
							<hr style={{ marginTop: '2px', marginBottom: '2px' }}/>
							<span>Require model/serial on ALL jobs</span>
							<Form.Check
								style={{ float: 'right' }}
								type="switch"
								name=""
								label=''
								disabled
								checked={ business.RqrModSer === 1 ? true : false }
							/>
							<hr style={{ marginTop: '2px', marginBottom: '2px' }}/>
							<span>Direct to zip page / Disallow warranty work</span>
							<Form.Check
								style={{ float: 'right' }}
								type="switch"
								name=""
								label=''
								disabled
								checked={ business.DirectToZipPg === 1 ? true : false }
							/>
							<hr style={{ marginTop: '2px', marginBottom: '2px' }}/>
							<span>Offer time frames</span>
							<Form.Check
								style={{ float: 'right' }}
								type="switch"
								name=""
								label=''
								disabled
								checked={ business.OfferTimeFrms === 1 ? true : false }
							/>
							<hr style={{ marginTop: '2px', marginBottom: '2px' }}/>
							<span>Exclude check as payment option</span>
							<Form.Check
								style={{ float: 'right' }}
								type="switch"
								name=""
								label=''
								disabled
								checked={ [2,3,6,7].includes(business.ExcludePayMethods) ? true : false }	/>
							<hr style={{ marginTop: '2px', marginBottom: '2px' }}/>
							<span>Offer technician tracking</span>
							<Form.Check
								style={{ float: 'right' }}
								type="switch"
								name=""
								label=''
								disabled
								checked={ business.TechStatus === 1 ? true : false }
							/>
							<hr style={{ marginTop: '2px', marginBottom: '2px' }}/>
							<span>
								Show full history
							</span>
							<Form.Check
								style={{ float: 'right' }}
								label=''
								type="switch"
								name=""
								disabled
								checked={ business.ShowHistory === 1 ? true : false }
							/>
							<hr style={{ marginTop: '2px', marginBottom: '2px' }}/>
							<span>Running version</span>
							<span style={{ float: 'right' }}>{ authStore?.version_used }</span>
						</div>
						<div className="card-footer">
							{this.renderSaveButton()}
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Settings;

