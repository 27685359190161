import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TechnicianList from '../../components/sd-mobile/TechnicianList';

@inject('authStore')
@inject('techStore')
@observer class SdMobileTechnicians extends Component {
	render() {
		return (
			<div>
				<h1 className="page-title">Current Technicians</h1>
				<div>
					<TechnicianList />
				</div>
			</div>
		);
	}
}

export default SdMobileTechnicians;