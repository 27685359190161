import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import apiRequest from 'utilities/api';
import moment from 'moment';
import _ from 'lodash';
import Loading from 'components/loaders/Loading';

@inject('authStore')
@observer class TipReports extends Component {
	state = {
		loading: true,
		start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
		end_date: moment().format('YYYY-MM-DD'),
		tips: {},
	}
	componentDidMount() {
		this.getTips(this.state.start_date, this.state.end_date);
	}

	getTips = async (start, end) => {
		this.setState({ loading: true });
		let { business } = this.props.authStore.nonObservableValues;
		let { token } = this.props.authStore.values;
		let data = await apiRequest({ endpoint: 'v3/rosswarepay/production/reportTips', parameters: { business_id: business.business_id, business_password: business.business_password, start_date: start, end_date: end }, token });
		let groupedData = _.groupBy(data.data, 'recipient');

		_.forEach(groupedData, (tech, key) => {
			let total = 0;
			_.forEach(tech, (tip) => {
				total += tip.amount;
			});
			groupedData[key].total = total;
		});
		this.setState({ tips: groupedData, loading: false });
	}

	renderTechRows() {
		let { tips } = this.state;
		return Object.keys(tips).map((tech, key) => {
			const currentTech = tips[tech];
			if (tech === 'null') {
				tech = 'Unassigned';
			}
			return (
				<tr key={key}>
					<td>{ tech }</td>
					<td className="text-right">${ currentTech.total.toString() }</td>
				</tr>
			);
		});
	}

	handleDateChange = (event) => {
		this.setState({ [event.target.name]: event.target.value }, () => {
			this.getTips(this.state.start_date, this.state.end_date);
		});
	}


	render() {
		let { loading, start_date, end_date } = this.state;
		return (
			<div>
				<div className="card">
					<div className="card-body">
						<div className="mb-3">
							<h4 className="">Tip/Gratuity report</h4>
						</div>
						<div className="grid-2-col mb-3">
							<div className="grid-4-col">
								<div className="d-flex align-items-center">Dates:</div>
								<input type="date" className="form-control" name="start_date" onChange={(e) => this.handleDateChange(e)} defaultValue={start_date} />
								<div className="d-flex justify-content-center align-items-center">to</div>
								<input type="date" className="form-control" name="end_date" onChange={(e) => this.handleDateChange(e)} defaultValue={end_date} />
							</div>
							<button className="btn btn-light d-print-none" onClick={() => window.print()}>Print</button>
						</div>
						{loading && <div className="py-4"><Loading /></div>}
						{!loading && <table className="table table-hover">
							<thead>
								<tr>
									<th>Tech code</th>
									<th className="text-right">Total tips</th>
								</tr>
							</thead>
							<tbody className="table-group-divider">
								{this.renderTechRows()}
							</tbody>
						</table>}
					</div>
				</div>
			</div>
		);
	}
}

export default TipReports;
