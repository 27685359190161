import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import InlineLoading from '../loaders/InlineLoading';
import { Button, Table } from 'react-bootstrap';
import { formatPhoneReadable } from '../../helpers/formatting';
import _ from 'lodash';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class PendingCalls extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		this.getPendingCalls();
	}
	getPendingCalls = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/pendingCalls', token });
		this.setState({ pendingCalls: (data), loading: false });
	}
	renderPendingCalls() {
		const { pendingCalls } = this.state;
		return ((pendingCalls || []).map((pendingCall) => {
			return (
				<tr key={pendingCall.item_id}>
					<td key={pendingCall.item_id + 'invoice'}>{pendingCall.invoice_number}</td>
					<td key={pendingCall.item_id + 'location'}>{pendingCall.location_name}</td>
					<td key={pendingCall.item_id + 'number'}>{formatPhoneReadable(pendingCall.number_to_call)}</td>
					<td key={pendingCall.item_id + 'type'}>{pendingCall.type === 'confirmation' ? 'Confirmation' : 'RequestToSchedule'}</td>
					<td><Button onClick={ () => this.cancelSingleRobocall({ item_id: pendingCall.item_id, type: pendingCall.type }) } variant="danger" size="sm">Cancel</Button></td>
				</tr>
			);
		}));
	}
	async cancelSingleRobocall({ item_id, type }) {
		this.setState({ loading: true });
		const { user } = this.props.authStore.values;
		await apiRequest({ endpoint: 'v3/myrossware/cancelRobocall', parameters: { item_id, type }, token: user?.token });
		const { pendingCalls } = this.state;
		const indexOfItem = _.findKey(pendingCalls, function(o) { return o.item_id = item_id; });
		pendingCalls.splice(indexOfItem, 1);
		this.setState({ loading: false, pendingCalls });
	}
	render() {
		if (this.state.loading) { return <InlineLoading />; } else {
			const { pendingCalls } = this.state;
			if (pendingCalls.length < 1) { return <div>No calls pending.</div>; }
			return (
				<Fragment>
					Note: Canceling calls will not currently result in an entry in a job&apos;s narrative history. If you choose to cancel a call, the history will still indicate a robocall has been made.<br />
					<Table striped size="sm" className="mt-3 myRwSmallTable">
						<thead>
							<tr>
								<th>Invoice</th>
								<th>Job</th>
								<th>Phone</th>
								<th>Type</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ this.renderPendingCalls() }
						</tbody>
					</Table>

				</Fragment>
			);
		}

	}
}

export default PendingCalls;
