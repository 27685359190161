import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import InlineLoading from '../../components/loaders/InlineLoading';
import { Card, Button, Form, Spinner } from 'react-bootstrap';
import { validatePaymentProcessorSettings } from 'helpers/validation';
import InputMask from 'react-input-mask';
import { getKeyByValue } from 'helpers/objects';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class Settings extends Component {
	state = {
		loading: true,
		valid: false,
		selectedProcessor: null,
		processorsTranslation: {
			0: 'tsys',
			1: 'altiras',
		}
	}

	componentDidMount = async () => {
		const { token } = this.props.authStore.values;
		const processorInfoResponse = await apiRequest({ endpoint: 'v3/myrossware/getMerchantCredentials', token });
		const { processorsTranslation } = this.state;
		const selectedProcessor = processorsTranslation[parseInt(processorInfoResponse.processor_code)];
		let processorInfo = {};
		processorInfo[selectedProcessor] = processorInfoResponse;
		this.setState({ loading: false, processorInfo, selectedProcessor });
	}

	onInputChange = (event) => {
		const { processorInfo, selectedProcessor } = this.state;
		const keys = event.target.name.split('.');
		processorInfo[keys[0]][keys[1]] = event.target.value;
		const valid = validatePaymentProcessorSettings(selectedProcessor, processorInfo);
		this.setState({ processorInfo, valid });
	}

	onSaveButton = async () => {
		this.setState({ loadingSave: true });
		const { token } = this.props.authStore.values;
		const { selectedProcessor, processorInfo, processorsTranslation } = this.state;
		const specificProcessorInfo = processorInfo[selectedProcessor];
		const processorNumber = getKeyByValue(processorsTranslation, selectedProcessor);
		await apiRequest({ endpoint: 'v3/myrossware/setMerchantCredentials', parameters: { merchant_name: specificProcessorInfo.name, merchant_site_id: specificProcessorInfo.site_id, merchant_key: specificProcessorInfo.key, which_processor: processorNumber }, token });
		this.setState({ loadingSave: false });
	}

	renderProcessorInputs () {
		const { processorInfo, selectedProcessor } = this.state;
		if (selectedProcessor === 'tsys') {
			return (
				<Form className="mt-3">
					<Form.Group className="mb-3">
						<Form.Label className="mb-1">Profile Name</Form.Label>
						<Form.Control maxLength="40" value={ processorInfo.tsys.name } name="tsys.name" onChange={ (event) => this.onInputChange(event) } placeholder="Service Business, LLC." />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label className="mb-1">Site ID</Form.Label>
						<Form.Control as={ InputMask } mask={'********'} value={ processorInfo.tsys.site_id } name="tsys.site_id" onChange={ (event) => this.onInputChange(event) } placeholder="XXXXXXXX" />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label className="mb-1">Key</Form.Label>
						<Form.Control as={ InputMask } mask={'*****-*****-*****-*****-*****'} value={ processorInfo.tsys.key } name="tsys.key" onChange={ (event) => this.onInputChange(event) } placeholder="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX" />
					</Form.Group>
				</Form>
			);
		}
	}

	onProcessorSelect = (event) => {
		this.setState({ selectedProcessor: event.target.name });
	}

	render() {
		const { loading, valid, selectedProcessor } = this.state;
		if (loading) { return <Card><Card.Body><InlineLoading /></Card.Body></Card>; } else {
			return (
				<Card className="mb-4">
					<Card.Title className="p-4 border-bottom">Payment settings</Card.Title>
					<Card.Body>
						<div className="mr-3 mb-2 font-weight-bold">Select your processor:</div>
						<small>These are the same processor details to be used in SD-Mobile by your technicians.</small><br />
						<Button onClick={ (event) => this.onProcessorSelect(event) } className="mr-2" active={ selectedProcessor === 'tsys' } name="tsys">TSYS/Cayan</Button>
						<Button onClick={ (event) => this.onProcessorSelect(event) } disabled active={ selectedProcessor === 'altiras' } name="altiras">Altiras (coming soon)</Button>
						{ this.renderProcessorInputs() }
						{(!this.state.loadingSave && selectedProcessor) && <Button disabled={ !valid } variant="primary" className="mt-2" onClick={this.onSaveButton}>Save Settings</Button>}
						{(this.state.loadingSave && selectedProcessor) && <Button disabled={ true } variant="primary" className="mt-2" onClick={this.onSaveButton}><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;Saving</Button>}
					</Card.Body>
				</Card>


			);
		}
	}
}

export default Settings;

