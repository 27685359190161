import React, { useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { rawApiRequest } from '../utilities/api';
import { useStores } from 'utilities/useStores';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { has } from 'lodash';
import { capitalizeFirstLetter } from 'helpers/strings';
import apiRequest from 'utilities/api';

const fields = {
	first_name: { required: true },
	last_name: { required: true },
	user_short_code: { required: false, find_issues: (value, data) => {
		if (value) {
			if (value.length !== 2) {
				return ['Two Letter Code must be 2 characters long'];
			}
		} else if (data.is_technician) {
			return ['Two Letter Code is required for technicians'];
		}
	} },
};

function validateUserData(data, onlyPresentFields) {
	let issues = [];

	for (let field in fields) {
		if (onlyPresentFields && !has(data, field)) continue;
		if (fields[field].required && !data[field]) issues.push({ field, description: `${field} is required` });

		const field_issues = fields[field]?.find_issues?.(data[field], data);
		if (field_issues) {
			issues = issues.concat(field_issues.map(item => {
				return { field, description: item };
			}));
		}
	}

	return issues;
}

function ValidationIssueDisplayer(props) {
	const { issues, field } = props;

	if (!issues || !issues.length) return null;
	const relevantIssues = issues.filter(item => item.field === field);
	if (!relevantIssues || !relevantIssues.length) return null;

	return (
		<div>
			{relevantIssues.map(issue => (
				<div key={issue.description} className="alert alert-danger" role="alert">
					{issue.description}
				</div>
			))}
		</div>
	);
}

function EditUser(props) {
	const { user, refresh } = props;

	const { authStore } = useStores();
	const ownUser = authStore.nonObservableValues?.user;
	const isOwner = ownUser?.is_owner;
	const isManager = ownUser?.is_manager;
	const isOwnerOrManager = isOwner || isManager;

	const [userData, setUserData] = useState(user);

	const [invalidMessages, setInvalidMessages] = useState(null);
	const [valid, setValid] = useState(false);
	const [success, setSuccess] = useState(false);
	const [failureMessage, setFailureMessage] = useState(null);
	const [loading, setLoading] = useState(false);

	function inputChange(event) {
		setUserData({ is_owner: false, is_manager: false, ...userData, [event.target.name]: event.target.value });
	}

	function checkChange(event) {
		setUserData({ is_owner: false, is_manager: false, ...userData, [event.target.name]: event.target.checked });
	}

	function validate(fully = false) {
		const issues = validateUserData(userData, !fully);
		setInvalidMessages(issues.length ? issues : null);
		setValid(issues.length === 0);
		return issues.length === 0;
	}

	useEffect(() => {
		validate(false);
	}, [userData]);

	async function resetPassword(email_address) {
		setLoading(true);
		const passResetResult = await apiRequest({ endpoint: 'v3/backend/sendDashboardUserPasswordReset', parameters: { email_address } });
		if (passResetResult.success) {
			setSuccess(true);
		} else {
			setFailureMessage(passResetResult?.message);
		}
		setLoading(false);
	}

	async function submit() {
		setLoading(true);
		let parameters = {};
		Object.keys(userData).forEach(item => {
			console.log(`setting key ${item} to value ${userData[item]}`);
			parameters[item] = userData[item];
		});
		const submitResult = await rawApiRequest({ endpoint: 'v3/dashboard/editDashboardUser', parameters, token: authStore.values?.token });
		console.log('got submitResult', submitResult);
		if (submitResult.status === 200) {
			setSuccess(true);
			refresh();
		} else {
			setFailureMessage(submitResult?.raw);
		}
		setLoading(false);
	}

	useEffect(() => {
		if (success) {
			setTimeout(() => {
				setSuccess(false);
			}, 1000);
		}
	}, [success]);

	return (
		<div className="">
			<div className="justify-content-center flex-column py-5 signup-container">
				<div className="signup-form">
					<Form.Group>
						<Form.Label><small>First Name</small></Form.Label>
						<Form.Control name="first_name" type="text" placeholder="John" maxLength="25" onChange={inputChange} value={userData?.first_name || ''} />
						<ValidationIssueDisplayer issues={invalidMessages} field="first_name" />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Last Name</small></Form.Label>
						<Form.Control name="last_name" type="text" placeholder="Smith" maxLength="25" onChange={inputChange} value={userData?.last_name || ''} />
						<ValidationIssueDisplayer issues={invalidMessages} field="last_name" />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Phone Number</small></Form.Label>
						<Form.Control name="phone_number" type="tel" placeholder="360-427-6000" onChange={inputChange} value={userData?.phone_number || ''} />
						<ValidationIssueDisplayer issues={invalidMessages} field="phone_number" />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Two Letter Code</small></Form.Label>
						<Form.Control name="user_short_code" type="text" placeholder="XX" onChange={inputChange} value={userData?.user_short_code || ''} />
						<ValidationIssueDisplayer issues={invalidMessages} field="user_short_code" />
					</Form.Group>
					<div className="d-flex flex-column align-items-center">
						<Form.Group>
							<Form.Check title={!isOwner ? 'You must be an owner to set owner status' : ''} disabled={!isOwner} type="checkbox" name="is_owner" label="Is Owner" onChange={checkChange} checked={userData?.is_owner || false} />
						</Form.Group>
					</div>
					<div className="d-flex flex-column align-items-center">
						<Form.Group>
							<Form.Check title={!isOwner ? 'You must be an owner to set financial right status' : 'Users with this permission are allowed to interact with the RosswarePay merchant track system'} disabled={!isOwner} type="checkbox" name="has_financial_rights" label="Has Financial Rights" onChange={checkChange} checked={userData?.has_financial_rights || false} />
						</Form.Group>
					</div>
					<div className="d-flex flex-column align-items-center">
						<Form.Group>
							<Form.Check title={!isOwnerOrManager ? 'You must be an owner or manager to set manager status' : ''} disabled={!isOwnerOrManager} type="checkbox" name="is_manager" label="Is Manager" onChange={checkChange} checked={userData?.is_manager || false} />
						</Form.Group>
					</div>
					<div className="d-flex flex-column align-items-center">
						<Form.Group>
							<Form.Check type="checkbox" name="is_technician" label="Is Technician" onChange={checkChange} checked={userData?.is_technician || false} />
						</Form.Group>
					</div>
				</div>
				{!loading && userData?.email_address && isOwnerOrManager && <button className="btn btn-primary mb-3" onClick={() => resetPassword(userData?.email_address)}>Reset password</button>}
				{success && <Alert variant="success">User successfully updated</Alert>}
				{failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
				{!loading &&
					<Button variant="primary" type="submit" disabled={!valid} className="btn btn-lg btn-block" onClick={() => {
						if (validate(true)) {
							submit();
						}
					}}>
						Submit Edits
					</Button>
				}
				{loading &&
					<div className="loginLoadingSpinner">
						<Spinner animation="border" variant="primary" />
					</div>
				}
			</div>
		</div>
	);

}

export default function EditUserModal(props) {
	return (
		<Modal size="lg" show onHide={props.onHide}	keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>Edit {capitalizeFirstLetter(props?.user?.first_name)}</Modal.Title>
			</Modal.Header>

			<EditUser {...props} />
		</Modal>
	);
}
