import React, { Component, Fragment } from 'react';

export class ReviewLink extends Component {
	render() {
		const { title, checked, value, linkName, onToggleShowFunction, onTextChangeFunction } = this.props;
		return (
			<Fragment>
				<div className="input-group mb-3">
					<div className="input-group-prepend">
						<div className="input-group-text myRWReviewLinkText d-flex justify-content-between">
							<span className="">{title}</span><input className="" checked={checked} onChange={() => onToggleShowFunction(linkName)} type="checkbox" aria-label="Checkbox for following text input" />
						</div>
					</div>
					<input id={linkName} value={value} type="text" onChange={(event) => onTextChangeFunction(event, linkName)} className="form-control" aria-label="Text input with checkbox" disabled={!checked} />
				</div>
			</Fragment>
		);
	}
}

export default ReviewLink;
