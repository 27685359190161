export default function decimalsAndCommas (dollarAmount) {
	const parts = dollarAmount.split('.');
	let dollarParts = [];
	for (let i = 0; i < parts[0].length / 3; i++) {
		dollarParts.push(parts[0].substring(parts[0].length - ((i + 1) * 3), parts[0].length - (i * 3)));
	}
	const dollarPartsReversed = dollarParts.reverse();
	let dollars = dollarPartsReversed.join(',');
	if (dollars.length === 0) {
		dollars = '0';
	}
	let cents = parts[1] ? parts[1] : '';
	if (cents.length > 2) {
		cents = cents.substring(0,2);
	}
	while (cents.length < 2) {
		cents = cents.concat('0');
	}
	return `${dollars}.${cents}`;
}