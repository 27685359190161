import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

export class BluebookOverview extends Component {
	render() {
		return (
			<Card>
				<Card.Title className="p-4 border-bottom">Overview</Card.Title>
				<Card.Body className="py-8">
					<p>In order to provide Blue BookBook pricing in SD-Mobile, you need to map your ServiceDesk UIS information to the types and makes recognized by BlueBook. This is accomplished by using the functions here.</p>
				</Card.Body>
			</Card>
		);
	}
}

export default BluebookOverview;

