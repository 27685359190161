import { validateEverything } from 'helpers/validation';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import apiRequest from 'utilities/api';
import Logo from '../assets/rossware-logo-simple.svg';
import InputMask from 'react-input-mask';

function Signup() {
	useEffect(() => {
		validateEverything();
		setInvalidMessage('All fields are required.');
	}, []);
	const [inputData, setInputData] = useState({
		business_id: {
			proper_name: 'Business ID',
			value: null,
			required: true,
			valid: false,
			type: 'business_id',
		},
		business_password: {
			proper_name: 'Business password',
			value: null,
			required: true,
			valid: false,
			type: 'text',
		},
		first_name: {
			proper_name: 'First name',
			value: null,
			required: true,
			valid: false,
			type: 'text',
		},
		last_name: {
			proper_name: 'Last name',
			value: null,
			required: true,
			valid: false,
			type: 'text',
		},
		email_address: {
			proper_name: 'Email address',
			value: null,
			required: true,
			valid: false,
			type: 'email',
		},
		phone_number: {
			proper_name: 'Phone number',
			value: null,
			required: true,
			valid: false,
			type: 'phone',
		},
		password: {
			proper_name: 'Password',
			value: null,
			required: true,
			valid: false,
			type: 'text',
		},
		confirm_password: {
			proper_name: 'Confirm password',
			value: null,
			required: true,
			valid: false,
			type: 'text',
		},

	});
	const [invalidMessage, setInvalidMessage] = useState(null);
	const [valid, setValid] = useState(false);
	const [success, setSuccess] = useState(false);
	const [failureMessage, setFailureMessage] = useState(null);
	const [loading, setLoading] = useState(false);

	function inputChange(event) {
		inputData[event.target.name].value = event.target.value;
		setInputData(inputData);
		const validationResult = validateEverything(inputData);
		setValid(validationResult.allValid);
		setInvalidMessage(validationResult.message);
	}
	async function submit() {
		setLoading(true);
		let parameters = {};
		Object.keys(inputData).forEach(item => {
			console.log(`setting key ${item} to value ${inputData[item].value}`);
			parameters[item] = inputData[item].value;
		});
		const signupResult = await apiRequest({ endpoint: 'v3/dashboard/createDashboardManager', parameters });
		if (signupResult.status === 200) {
			setSuccess(true);
		} else {
			setFailureMessage(signupResult?.message);
		}
		setLoading(false);
	}
	if (success) {
		return (
			<div className="login-wrapper">
				<div className="p-3 border-bottom d-flex justify-content-between">
					<img src={ Logo } width="130px" className="d-inline" />
					<Button href="https://rossware.com">Return to website</Button>
				</div>
				<div>Account created successfully! You will be receiving an email soon with a link to verify your account.</div>
			</div>
		);
	}
	return (
		<div className="login-wrapper">
			<div className="p-3 border-bottom d-flex justify-content-between">
				<img src={ Logo } width="130px" className="d-inline" />
				<Button href="https://rossware.com">Return to website</Button>
			</div>

			<div className="justify-content-center flex-column py-5 signup-container">
				<h1>Sign up for Rossware Dashboard</h1>
				<h6>Create your first Rossware Dashboard user. <a href="https://help.rossware.com/docs/other/misc/myrossware-dashboard-transition">Click here to read about the transition from MyRossware.</a></h6>
				<div className="signup-form">
					<Form.Group>
						<Form.Label><small>4-Digit Business ID</small></Form.Label>
						<Form.Control as={ InputMask } mask="9999" name="business_id" type="text" placeholder="1001" onChange={ (e) => { inputChange(e); } } defaultValue={inputData?.business_id?.value || ''} />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Business Password</small></Form.Label>
						<Form.Control name="business_password" type="password" placeholder="" onChange={ (e) => { inputChange(e); } } defaultValue={inputData?.business_password?.value || ''} />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>First Name</small></Form.Label>
						<Form.Control name="first_name" type="text" placeholder="John" maxLength="25" onChange={ (e) => { inputChange(e); } } defaultValue={inputData?.first_name?.value || ''} />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Last Name</small></Form.Label>
						<Form.Control name="last_name" type="text" placeholder="Smith" maxLength="25" onChange={ (e) => { inputChange(e); } } defaultValue={inputData?.last_name?.value || ''} />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Email Address</small></Form.Label>
						<Form.Control name="email_address" type="text" placeholder="john@businessemail.com" onChange={ (e) => { inputChange(e); } } defaultValue={inputData?.email_address?.value || ''} />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Phone Number</small></Form.Label>
						<Form.Control name="phone_number" type="text" placeholder="360-427-6000" onChange={ (e) => { inputChange(e); } } defaultValue={inputData?.phone_number?.value || ''} />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Password</small></Form.Label>
						<Form.Control name="password" type="password" placeholder="" onChange={ (e) => { inputChange(e); } } defaultValue={inputData?.password?.value || ''} />
					</Form.Group>
					<Form.Group>
						<Form.Label><small>Confirm Password</small></Form.Label>
						<Form.Control name="confirm_password" type="password" placeholder="" onChange={ (e) => { inputChange(e); } } defaultValue={inputData?.confirm_password?.value || ''} />
					</Form.Group>
				</div>
				<div>{ invalidMessage }</div>
				{ !valid && <div>Ensure password meets complexity requirements: Between 8 and 16 characters, at least one lower-case letter, at least one upper-case letter, at least one number, at least one special character.</div>}
				{ failureMessage && <Alert variant="danger">{ failureMessage }</Alert> }
				{!loading &&
					<Button variant="primary" type="submit" disabled={ !valid } className="btn btn-lg btn-block" onClick={() => submit()}>
						Sign up
					</Button>
				}
				{loading &&
					<div className="loginLoadingSpinner">
						<Spinner animation="border" variant="primary" />
					</div>
				}
			</div>
		</div>
	);

}

export default Signup;
