import CreateUserModal from 'modals/CreateUserModal';
import EditUserModal from 'modals/EditUserModal';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { rawApiRequest } from 'utilities/api';
import { useStores } from 'utilities/useStores';

function getUsersFromDatabase(token) {
	return rawApiRequest({ endpoint: 'v3/dashboard/getUsers', token });
}

function capitalizeFirstLetter(string) {
	if (!string) return '';
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function HighlightSearchTerm({ searchTerm, string }) {
	if (!searchTerm) return string;
	console.log('searchTerm', searchTerm);
	const lowerSearchTerm = searchTerm.toLowerCase();
	const lowerString = string.toLowerCase();
	const index = lowerString.indexOf(lowerSearchTerm);
	if (index === -1) return string;

	return (
		<>
			{string.substring(0, index)}
			<span className='text-primary'>{string.substring(index, index + searchTerm.length)}</span>
			{string.substring(index + searchTerm.length)}
		</>
	);
}

function UserList(props) {
	const { token, users, user, refresh, filter, showUser } = props;

	if (!users || !users?.[0]) return null;

	let filteredUsers = users;

	if (filter) {
		const lowerFilter = filter.toLowerCase();
		filteredUsers = users.filter((user) => {
			if (user.first_name.toLowerCase().includes(lowerFilter)) return true;
			if (user.last_name.toLowerCase().includes(lowerFilter)) return true;
			if (user.email_address.toLowerCase().includes(lowerFilter)) return true;
			if (user.phone_number.toLowerCase().includes(lowerFilter)) return true;
		});
	}

	const userViews = [];

	for (let item of filteredUsers) {
		const tags = [];

		if (item.id === user.id) tags.push({ text: 'You', class: 'badge-warning' });
		if (item.is_owner) tags.push({ text: 'Owner', class: 'badge-success' });
		if (item.is_manager) tags.push({ text: 'Manager', class: 'badge-primary' });
		if (item.is_technician) tags.push({ text: 'Technician', class: 'badge-purple' });
		if (item.has_financial_rights) tags.push({ text: 'Financial', class: 'badge-info' });
		if (!item.enable) tags.push({ text: 'Disabled', class: 'badge-secondary' });

		userViews.push(
			<div className="card" key={item.id + 'row'}>
				<div className="card-body grid-2-col">
					<div>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<h5 style={{ margin: 0, padding: 0 }}><HighlightSearchTerm searchTerm={filter} string={capitalizeFirstLetter(item.first_name)} /> <HighlightSearchTerm searchTerm={filter} string={capitalizeFirstLetter(item.last_name)} /></h5>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
								{tags.map(tag => (
									<div className={'badge ' + tag.class} style={{ marginLeft: 6 }} key={tag.text}>{tag.text}</div>
								))}
							</div>
						</div>
						<div>
							<span className='text-muted' style={{ margin: 0 }}><HighlightSearchTerm searchTerm={filter} string={capitalizeFirstLetter(item.email_address)} /></span>
						</div>
					</div>
					<div className="w-100 d-flex flex-row justify-content-end align-items-center">
						<button onClick={() => {
							showUser(item);
						}} style={{ marginRight: 8 }} type="button" className="btn btn btn-light">Edit</button>
						<button onClick={async () => {
							if (confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
								const deleteResponse = await rawApiRequest({ endpoint: 'v3/dashboard/deleteDashboardUser', token, parameters: { id: item.id } });
								if (deleteResponse.status > 399) {
									alert(deleteResponse.raw);
								}
								refresh();
							}
						}} type="button" className="btn btn-danger">Delete</button>
					</div>
				</div>

			</div>
		);
	}

	return (
		<div>
			{userViews}
		</div>
	);
}

export default function UserManagement() {
	const { authStore } = useStores();
	const user = authStore.nonObservableValues?.user;
	const token = authStore?.values?.token;
	const [users, setUsers] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [filterValue, setFilterValue] = useState('');
	const [shownEditUser, setShownEditUser] = useState();

	if (!user?.is_manager && !user?.is_owner) {
		return <div>{'Sorry you don\'t have permission to access this page'}</div>;
	}

	const reloadUsers = async () => {
		try {
			const response = await getUsersFromDatabase(authStore.values?.token);

			if (response.status === 200) {
				setUsers(response.data?.users);
			} else {
				setError(response.raw);
			}
		} catch (err) {
			setError(err);
		}

		setLoading(false);
	};

	useEffect(() => {
		reloadUsers();
	}, [user]);

	if (loading) {
		return <div>{'Loading...'}</div>;
	}

	if (error) {
		return <div>{error?.message || JSON.stringify(error) || 'Unknown Error, Contact Rossware'}</div>;
	}

	return (
		<div>
			{showAddUserModal && <CreateUserModal refresh={reloadUsers} onHide={() => setShowAddUserModal(false)} />}
			{!!shownEditUser && <EditUserModal refresh={reloadUsers} user={shownEditUser} onHide={() => setShownEditUser(null)} />}
			<div style={{ marginBottom: 15, display: 'flex', justifyContent: 'flex-end' }}>
				<form className="form-inline my-2 my-lg-0">
					<input onChange={event => setFilterValue(event.target.value)} value={filterValue} className="form-control mr-sm-2" type="search" placeholder="Filter" aria-label="Filter" />
				</form>
				<div style={{ flex: 1 }}/>
				<button onClick={() => setShowAddUserModal(true)} type="button" className="btn btn-primary"><FaPlusCircle /> Add New User</button>
			</div>
			<UserList filter={filterValue} refresh={reloadUsers} showUser={setShownEditUser} users={users} user={user} token={token} />
		</div>
	);
}
