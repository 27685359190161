import React, { Component } from 'react';
import Img from 'react-image';
import InlineLoading from '../loaders/InlineLoading';
import { Col, Row } from 'react-bootstrap';
import { getURLExtension } from 'helpers/strings';
import { inject, observer } from 'mobx-react';
import apiRequest from 'utilities/api';
import moment from 'moment';

@inject('authStore')
@observer class QuickPic extends Component {
	constructor() {
		super();
		this.state = {
			deleteWarning: false,
			deleted: false
		};
	}

	renderUnableToShowImage (image_url) {
		return (
			<div className="nonTraditionalQuickPicContainer">
				<span>Unable to display file, click to download:</span>
				<form method="get" action={ image_url }>
					<button type="submit">Download</button>
				</form>
			</div>
		);
	}

	renderActualFile () {
		const { image_url } = this.props.props;
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
		const extension = getURLExtension(image_url);
		if (imageExtensions.includes(extension)) {
			return (
				<Img src={image_url} loader={<InlineLoading />} unloader={ this.renderUnableToShowImage(image_url) } className="quickPicImage" />
			);
		} else {
			return this.renderUnableToShowImage(image_url);
		}
	}

	deletePicture = async (fileId) => {
		let { token } = this.props.authStore.values;
		let data = await apiRequest({ endpoint: 'v3/myRossware/deleteQuickPic', parameters: { file_id: fileId }, token });
		if (data) {
			this.setState({
				deleted: true
			});
		}
	}

	render() {
		const { file_id, tech_code, invoice_number, description, uis_id, upload_date, picture_snapped_date } = this.props.props;
		var local_picture_snapped_date = moment.utc(picture_snapped_date).local().format('YYYY-MM-DD HH:mm:ss');

		if (!this.state.deleted) {
			return (
				<div className="quickPicImageContainer p-3 border rounded mb-3">
					<Row>
						<Col>
							<span><b>Description:</b> {description}</span>
						</Col>
						<Col>
							<span><b>Upload Date:</b> {upload_date}</span>
						</Col>
						<Col>
							<span><b>Date Taken:</b> {local_picture_snapped_date}</span>
						</Col>
					</Row>
					<Row className="my-3">
						<Col>
							<span><b>Invoice #:</b> {invoice_number}</span>
						</Col>
						<Col>
							<span><b>UIS ID:</b> {uis_id}</span>
						</Col>
						<Col>
							<span><b>Technician:</b> {tech_code}</span>
						</Col>
					</Row>
					{!this.state.deleteWarning && <div className="btn btn-light" onClick={() => this.setState({ deleteWarning: true })}>Delete picture</div>}
					{this.state.deleteWarning && <div className="btn btn-danger" onClick={() => this.deletePicture(file_id)}>Click again to confirm. THIS CANNOT BE UNDONE.</div>}
					<div className="quickPicImageContainerInner">
						{ this.renderActualFile() }
					</div>
				</div>
			);
		}
		return (
			<div className="alert alert-primary mb-3">Deleted picture ID#({file_id}) successfully.</div>
		);
	}
}

export default QuickPic;
