import React, { Component } from 'react';
import { Form, Button, Spinner, Card } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import InlineLoading from '../../components/loaders/InlineLoading';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class BluebookTypes extends Component {
	state = {
		// should remain unchanged
		BBTypes: {},
		SDTypes: {},
		// subject to change based on user interaction
		typeMap: {},
		selectedBBType: 'default',
		selectedFromUnmapped: [],
		selectedFromMapped: [],
		loading: true,
		loadingSave: false,
	}
	componentDidMount() {
		this.getTypes();
	}
	getTypes = async () => { // fills state with data
		this.setState({ loading: true });
		const { token } = this.props.authStore.values;
		const BBTypes = await apiRequest({ endpoint: 'v3/myrossware/getBBTypes', token });
		const SDTypes = await apiRequest({ endpoint: 'v3/myrossware/getSDTypes', token });
		const BBTypeMap = await apiRequest({ endpoint: 'v3/myrossware/getBBTypeMap', token });
		let BBTypeMapObject = {};
		BBTypeMap.data.forEach(type => {
			if (_.has(BBTypeMapObject, type.bbItmStrng)) {
				BBTypeMapObject[type.bbItmStrng].push(type.RWItmStrng);
			} else {
				BBTypeMapObject[type.bbItmStrng] = [];
				BBTypeMapObject[type.bbItmStrng].push(type.RWItmStrng);
			}
		});
		this.setState({ BBTypes: BBTypes.data, SDTypes: SDTypes.data, typeMap: BBTypeMapObject, loading: false });
	}

	// render options below

	renderBBTypesOptions() { // renders types from SCS BB data
		const { BBTypes } = this.state;
		if (BBTypes) {
			return BBTypes.map((type) => {
				return <option key={type.ApplianceId}>{type.ApplianceName}</option>;
			});
		}
	}
	renderAllSDTypeOptions() { // renders list of SD types with relevant ones selected
		const { SDTypes } = this.state;
		return SDTypes.map((type) => {
			return (
				<option key={type.ItmStrng}>{type.ItmStrng}</option>
			);
		});
	}
	renderMappedSDTypeOptions() {
		const { selectedBBType, typeMap } = this.state;
		const mappedTypes = typeMap[selectedBBType] || [];
		return mappedTypes.map((type) => {
			return (
				<option key={type}>{type}</option>
			);
		});
	}

	// on change/click functions below

	onBBTypeSelectChange = (event) => {
		this.setState({ selectedBBType: event.target.value });
	}
	onMappedSelectChange = (event) => {
		var options = event.target.options;
		var value = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}
		this.setState({ selectedFromMapped: value });
	}
	onUnmappedSelectChange = (event) => {
		var options = event.target.options;
		var value = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}
		this.setState({ selectedFromUnmapped: value });
	}
	onMapButton = () => {
		const { selectedBBType, selectedFromUnmapped, typeMap } = this.state;
		selectedFromUnmapped.forEach(element => {
			if (typeMap[selectedBBType]) {
				if (!typeMap[selectedBBType].includes(element)) {
					typeMap[selectedBBType].push(element);
				}
			} else {
				typeMap[selectedBBType] = [];
				typeMap[selectedBBType].push(element);
			}
		});
		this.setState({ typeMap: typeMap, selectedFromUnmapped: [] });
	}
	onUnmapButton = () => {
		const { selectedBBType, selectedFromMapped, typeMap } = this.state;
		let indexesToBeUnmapped = [];
		selectedFromMapped.forEach(element => {
			let dex = typeMap[selectedBBType].indexOf(element);
			indexesToBeUnmapped.push(dex);
		});
		const newTypeMap = typeMap[selectedBBType].filter((type) => {
			const indexOfItemInTypeMap = typeMap[selectedBBType].indexOf(type);
			return !indexesToBeUnmapped.includes(indexOfItemInTypeMap);
		});
		typeMap[selectedBBType] = newTypeMap;
		this.setState({ typeMap: typeMap, selectedFromMapped: [] });
	}
	onSaveButton = async () => {
		this.setState({ loadingSave: true });
		const { token } = this.props.authStore.values;
		const typeMap = JSON.stringify(this.state.typeMap);
		await apiRequest({ endpoint: 'v3/myrossware/setBBTypeMap', parameters: { bb_type_map_object: typeMap }, token });
		this.setState({ loadingSave: false });
	}
	render() {
		if (this.state.loading) {
			return (
				<Card>
					<Card.Body>
						<InlineLoading />
					</Card.Body>
				</Card>
			);
		} else {
			return (
				<Card>
					<Card.Title className="p-4 border-bottom">Map Types</Card.Title>

					<Card.Body>
						<small>Select Bluebook Type</small>
						<Form.Control defaultValue={this.state.selectedBBType} as="select" onChange={this.onBBTypeSelectChange}>
							<option disabled value="default">Select a Bluebook Type</option>
							{this.renderBBTypesOptions()}
						</Form.Control>
						<br></br>
						<small>Mapped ServiceDesk Types</small>
						<Form.Control as="select" multiple className="SDMakeSelect" onChange={this.onMappedSelectChange}>
							{this.renderMappedSDTypeOptions()}
						</Form.Control>
						<br />
						<Button disabled={!this.state.selectedFromUnmapped.length > 0} variant="secondary" size="sm" onClick={this.onMapButton}>Map <MdKeyboardArrowUp /></Button>&nbsp;
						<Button disabled={!this.state.selectedFromMapped.length > 0} variant="secondary" size="sm" onClick={this.onUnmapButton}>Unmap <MdKeyboardArrowDown /></Button><br />
						<small>Your ServiceDesk Types</small>
						<Form.Control as="select" multiple className="SDMakeSelectedSelect" onChange={this.onUnmappedSelectChange}>
							{this.renderAllSDTypeOptions()}
						</Form.Control>
						<br></br>
						{!this.state.loadingSave && <Button variant="primary" className="mt-2" onClick={this.onSaveButton}>Save Mapping</Button>}
						{this.state.loadingSave && <Button variant="primary" className="mt-2" onClick={this.onSaveButton}><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;Saving</Button>}
					</Card.Body>
				</Card>
			);
		}
	}
}

export default BluebookTypes;

